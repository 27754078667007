import { ArcElement, ChartData, Chart as ChartJS, Legend, Tooltip } from 'chart.js';
import { ReactNode } from 'react';
import { Pie } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

interface Props {
    originalData: number[];
    data: ChartData<"pie", number[], string>;
    title: ReactNode;
    value: ReactNode;
    lastSync?: string;
}

const IntegrationChart = (props: Props) => {
    return (
        <div className='integration-card'>
            <Pie
                data={props.data}
                options={{
                    responsive: true,
                    hover: {
                        mode: 'point',
                    },
                    plugins: {
                        legend: {
                            display: false
                        },
                        tooltip: {
                            enabled: false,
                            external: (context) => {
                                let tooltipEl = document.getElementById('chartjs-tooltip');
                                
                                if (!tooltipEl) {
                                    tooltipEl = document.createElement('div');
                                    tooltipEl.id = 'chartjs-tooltip';
                                    tooltipEl.style.position = 'absolute';
                                    tooltipEl.style.background = 'rgba(0, 0, 0, 0.7)';
                                    tooltipEl.style.color = 'white';
                                    tooltipEl.style.padding = '5px';
                                    tooltipEl.style.borderRadius = '3px';
                                    tooltipEl.style.pointerEvents = 'none';
                                    document.body.appendChild(tooltipEl);
                                }
                                
                                const tooltipModel = context.tooltip;
                                if (tooltipModel.opacity === 0) {
                                    tooltipEl.style.opacity = '0';
                                    return;
                                }
                                
                                tooltipEl.innerHTML = tooltipModel.body.map(item => item.lines).join('<br/>');
                                const position = context.chart.canvas.getBoundingClientRect();
                                tooltipEl.style.opacity = '1';
                                tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
                                tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
                            },
                            callbacks: {
                                label: (context) => {
                                    const index = context.dataIndex;
                                    const originalValue = props.originalData[index];
                                    return `${originalValue} ${context.label}`;
                                }
                            }
                        },
                    },
                }}
            />
            <div className='integration-card-content'>
                <div className='integration-card-content-title'>{props.title}</div>
                <div className='integration-card-content-value'>{props.value}</div>
            </div>
        </div>
    );
}

export default IntegrationChart;