import { useEffect } from "react";
import { customersFetched, mandatesFetched, missionsFetched } from "../../store/features/customerManagement";
import { useAppDispatch, useAppSelector } from "../../store/store";

const useCustomerManagement = () => {
    const dispatch = useAppDispatch();
    const customerManagement = useAppSelector(state => state.customerManagement);
    
    // const refresh = useCallback(() => {
    //     dispatch(customersFetched({forceReload: false}));
    //     dispatch(mandatesFetched({forceReload: false}));
    //     dispatch(missionsFetched({forceReload: false}));
    //     dispatch(sitesFetched({forceReload: false}));
    // }, [dispatch])

    useEffect(() => {
        if (customerManagement.customers.data === undefined && !customerManagement.customers.loading)
            dispatch(customersFetched({forceReload: false}));
        if (customerManagement.mandates.data === undefined && !customerManagement.mandates.loading)
            dispatch(mandatesFetched({forceReload: false}));
        if (customerManagement.missions.data === undefined && !customerManagement.missions.loading)
            dispatch(missionsFetched({forceReload: false}));
        // if (customerManagement.sites.data === undefined)
        //     dispatch(sitesFetched({forceReload: false}));
    }, [customerManagement.customers.data, customerManagement.customers.loading, customerManagement.mandates.data, customerManagement.mandates.loading, customerManagement.missions.data, customerManagement.missions.loading, dispatch])

    return {
        customers: customerManagement.customers,
        mandates: customerManagement.mandates,
        missions: customerManagement.missions,
        sites: customerManagement.sites,
        // refresh: refresh()
    };
}

export default useCustomerManagement;