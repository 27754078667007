import cloneDeep from 'lodash/cloneDeep';
import remove from 'lodash/remove';
import moment, { Moment } from "moment";
import { BreaktimeCalculatedTypes, OvertimesCalculatedStatus, OvertimesCalculatedTypes } from "../../constants";
import { MonthlyHoursOfTheYear } from "../../types/planningTypes";
import { EventsByContractByMonth, EventsByMonth, InitialHoursByContractByYear, SimpleBreaktime, SimpleContract, SimpleEvent, SimpleOvertime, UserHoursSummaryByYear, UserHoursSummaryInMonth } from "../../types/reportTypes";
import { roundDecimals, showNotification } from "../../utils";

export class UserEventsData {
    protected _user: any;
    protected _events: SimpleEvent[];
    protected _overtimes: SimpleOvertime[];
    protected _breaktimes: SimpleBreaktime[];
    protected _contracts?: SimpleContract[];
    protected _monthlyHours?: MonthlyHoursOfTheYear;
    protected _initialHoursByContractByYear?: InitialHoursByContractByYear[];

    protected _startMonth?: Moment;
    protected _endMonth?: Moment;
    protected _eventsByMonth?: EventsByMonth;
    protected _eventsByContractByMonth?: EventsByContractByMonth[];

    constructor(events: SimpleEvent[], user: any, contracts?: SimpleContract[]) {
        this._events = events;
        this._user = user;
        this._contracts = contracts;


        this._overtimes = events.flatMap(e => e.overtimes);
        this._breaktimes = events.flatMap(e => e.breakTimes);
    }

    // #region Events
    get events(): SimpleEvent[] {
        return this._events;
    }

    set events(events: SimpleEvent[]) {
        this._events = events;
    }

    /**
   * Appends new event to the end of an array, and returns the new length of the array.
   *
   * @param event New event to add to the array.
   *
   * @returns The new length of the array
   */
    eventAdd(event: SimpleEvent) {
        return this._events.push(event);
    }

    /**
   * Removes events from an array and, if necessary, inserts new event in their place, returning the deleted events.
   *
   * @param start The zero-based location in the array from which to start removing events.
   * @param deleteCount The number of events to remove.
   * @param event Elements to insert into the array in place of the deleted events.
   *
   * @returns An array containing the events that were deleted.
   */
    eventSplice(start: number, deleteCount: number | undefined = 1, event?: SimpleEvent) {
        if (event === undefined) {
            return this._events.splice(start, deleteCount);
        } else {
            return this._events.splice(start, deleteCount, event);
        }
    }

    /**
   * Calls a defined callback function on each event of a array , and returns an array that contains the results.
   *
   * @param predicate A function that accepts up to three arguments. The map method calls the callbackfn function one time for each event in the array.
   * @returns
   */
    eventMap(callbackfn: (value: SimpleEvent, index?: number, obj?: SimpleEvent[]) => boolean) {
        return this._events.map(callbackfn);
    }

    /**
   * Performs the specified action for each event in an array.
   *
   * @param callbackfn  A function that accepts up to three arguments. forEach calls the callbackfn function one time for each event in the array.
   * @returns
   */
    eventForeach(callbackfn: (value: SimpleEvent, index?: number, obj?: SimpleEvent[]) => boolean) {
        this._events.forEach(callbackfn);
    }

    /**
   * Returns the events of an array that meet the condition specified in a callback function.
   *
   * @param predicate A function that accepts up to three arguments. The filter method calls the predicate function one time for each event in the array.
   * @returns
   */
    eventFilter(predicate: (value: SimpleEvent, index?: number, obj?: SimpleEvent[]) => boolean) {
        return this._events.filter(predicate);
    }

    /**
   * Returns the index of the first event in the array where predicate is true, and -1 otherwise.
   *
   * @param predicate Find calls predicate once for each element of the array, in ascending order, until it finds one where predicate returns true. If such an event is found, findIndex immediately returns that event index. Otherwise, findIndex returns -1.
   * @returns If an event is found, the index is returned. Otherwise, returns -1.
   */
    eventFindIndex(predicate: (value: SimpleEvent, index?: number, obj?: SimpleEvent[]) => boolean) {
        return this._events.findIndex(predicate);
    }

    /**
   * Returns the value of the first event in the array where predicate is true, and undefined otherwise.
   *
   * @param predicate Find calls predicate once for each event of the array, in ascending order, until it finds one where predicate returns true. If such an event is found, find immediately returns that event value. Otherwise, find returns undefined.
   * @returns If an event is found it is returned. Otherwise, returns undefined.
   */
    eventFind(predicate: (value: SimpleEvent, index?: number, obj?: SimpleEvent[]) => boolean) {
        return this._events.find(predicate);
    }

    /**
   * Returns the index of the first occurrence of a event in an array, or -1 if it is not present.
   *
   * @param event The event to locate in the array.
   * @param fromIndex The array index at which to begin the search. If fromIndex is omitted, the search starts at index 0.
   * @returns the index of the first occurrence of a event in an array, or -1 if it is not present.
   */
    eventIndexOf(event: SimpleEvent, fromIndex?: number) {
        return this._events.indexOf(event, fromIndex);
    }

    /**
   * Calculate the duration of the event rounded in hours
   *
   * @param event Event to calculate the duration
   * @param withOvertimes Calculate duration of overtimes
   * @param withBreaktimes Calculate duration of breaktimes
   * @param calculateNotCountAsWorktime Calculate duration of events with countAsWorktime == false
   * @param calculateNotInReports Calculate duration of events with inReports == false
   * @param precise calculate in seconds if true else hours
   * @returns The duration of the event rounded in hours
   */
    static eventDuration = (event: SimpleEvent, withOvertimes?: boolean, withBreaktimes?: boolean, calculateNotCountAsWorktime = false, calculateNotInReports = false, precise = false, decimals = 2) => {
        if (calculateNotCountAsWorktime === false) {
            if (event.typeOfDay?.countAsWorktime === false || event.typeOfDayOff?.countAsWorktime === false) {
                return 0.0;
            }
        }

        if (calculateNotInReports !== true) {
            if (event.typeOfDay?.inReports === false || event.typeOfDayOff?.inReports === false) {
                return 0.0;
            }
        }

        let eventDuration = 0.0;
        if (precise) eventDuration = roundDecimals(moment.duration(event.dateTo.diff(event.dateFrom, undefined, true)).asSeconds(), decimals);
        else eventDuration = roundDecimals(moment.duration(event.dateTo.diff(event.dateFrom, undefined, true)).asHours(), decimals);
        if (withBreaktimes) {
            eventDuration -= UserEventsData.breaktimesDuration([event], BreaktimeCalculatedTypes.NOTPAID, calculateNotCountAsWorktime, calculateNotInReports, precise);
        }

        if (withOvertimes) {
            eventDuration += UserEventsData.overtimesDuration([event], OvertimesCalculatedTypes.ALL, OvertimesCalculatedStatus.CONFIRMED, calculateNotCountAsWorktime, calculateNotInReports, precise);
        }
        return eventDuration;
    };

    /**
   * Calculate the duration of an array of events
   *
   * @param events An array of events to calculate duration
   * @param withOvertimes Calculate duration of overtimes
   * @param withBreaktimes Calculate duration of breaktimes
   * @param calculateNotCountAsWorktime Calculate duration of events with countAsWorktime == false
   * @param calculateNotInReports Calculate duration of events with inReports == false
   * @returns The duration of an array of events
   */
    static eventsDuration = (events: SimpleEvent[], withOvertimes?: boolean, withBreaktimes?: boolean, calculateNotCountAsWorktime = false, calculateNotInReports = false) => {
        let totalHours = undefined;
        const clonedEvents = cloneDeep(events);

        totalHours = clonedEvents.reduce((total: number, event: SimpleEvent) => {
            return total + UserEventsData.eventDuration(event, withOvertimes, withBreaktimes, calculateNotCountAsWorktime, calculateNotInReports);
        }, 0.0);

        return roundDecimals(totalHours);
    };

    /**
   * Calculate the duration of all events rounded in hours
   *
   * @param withOvertimes Calculate duration of overtimes
   * @param withBreaktimes Calculate duration of breaktimes
   * @param calculateNotCountAsWorktime Calculate duration of events with countAsWorktime == false
   * @param calculateNotInReports Calculate duration of events with inReports == false
   * @returns the duration of all events rounded in hours
   */
    eventsTotalDuration = (withOvertimes?: boolean, withBreaktimes?: boolean, calculateNotCountAsWorktime = false, calculateNotInReports = false) => {
        return UserEventsData.eventsDuration(this._events, withOvertimes, withBreaktimes, calculateNotCountAsWorktime, calculateNotInReports);
    };
    // #endregion

    // #region Overtimes
    get overtimes(): SimpleOvertime[] {
        return this._overtimes;
    }

    set overtimes(overtimes: SimpleOvertime[]) {
        this._overtimes = overtimes;
    }

    /**
   * Appends new overtime to the end of an array, and returns the new length of the array.
   *
   * @param overtime New overtime to add to the array.
   *
   * @returns The new length of the array
   */
    addOvertime(overtime: SimpleOvertime) {
        return this._overtimes.push(overtime);
    }

    /**
   * Removes overtimes from an array and, if necessary, inserts new overtime in their place, returning the deleted overtimes.
   *
   * @param start The zero-based location in the array from which to start removing overtimes.
   * @param deleteCount The number of overtimes to remove.
   * @param overtime Overtime to insert into the array in place of the deleted overtimes.
   *
   * @returns An array containing the overtimes that were deleted.
   */
    overtimeSplice(start: number, deleteCount: number | undefined = 1, overtime?: SimpleOvertime) {
        if (overtime === undefined) {
            this._overtimes.splice(start, deleteCount);
        } else {
            this._overtimes.splice(start, deleteCount, overtime);
        }
    }

    /**
   * Calls a defined callback function on each overtime of a array , and returns an array that contains the results.
   *
   * @param predicate A function that accepts up to three arguments. The map method calls the callbackfn function one time for each overtime in the array.
   * @returns
   */
    overtimeMap(callbackfn: (value: SimpleOvertime, index?: number, obj?: SimpleOvertime[]) => boolean) {
        return this._overtimes.map(callbackfn);
    }

    /**
   * Performs the specified action for each overtime in an array.
   *
   * @param callbackfn  A function that accepts up to three arguments. forEach calls the callbackfn function one time for each overtime in the array.
   * @returns
   */
    overtimeForeach(callbackfn: (value: SimpleOvertime, index?: number, obj?: SimpleOvertime[]) => boolean) {
        this._overtimes.forEach(callbackfn);
    }

    /**
   * Returns the overtimes of an array that meet the condition specified in a callback function.
   *
   * @param predicate A function that accepts up to three arguments. The filter method calls the predicate function one time for each overtime in the array.
   * @returns
   */
    overtimeFilter(predicate: (value: SimpleOvertime, index?: number, obj?: SimpleOvertime[]) => boolean) {
        return this._overtimes.filter(predicate);
    }

    /**
   * Returns the index of the first overtime in the array where predicate is true, and -1 otherwise.
   *
   * @param predicate Find calls predicate once for each element of the array, in ascending order, until it finds one where predicate returns true. If such an overtime is found, findIndex immediately returns that overtime index. Otherwise, findIndex returns -1.
   * @returns If an overtime is found, the index is returned. Otherwise, returns -1.
   */
    overtimeFindIndex(predicate: (value: SimpleOvertime, index?: number, obj?: SimpleOvertime[]) => boolean) {
        return this._overtimes.findIndex(predicate);
    }

    /**
   * Returns the value of the first overtime in the array where predicate is true, and undefined otherwise.
   *
   * @param predicate Find calls predicate once for each overtime of the array, in ascending order, until it finds one where predicate returns true. If such an overtime is found, find immediately returns that overtime value. Otherwise, find returns undefined.
   * @returns If an overtime is found it is returned. Otherwise, returns undefined.
   */
    overtimeFind(predicate: (value: SimpleOvertime, index?: number, obj?: SimpleOvertime[]) => boolean) {
        return this._overtimes.find(predicate);
    }

    /**
   * Returns the index of the first occurrence of a overtime in an array, or -1 if it is not present.
   *
   * @param event The overtime to locate in the array.
   * @param fromIndex The array index at which to begin the search. If fromIndex is omitted, the search starts at index 0.
   * @returns the index of the first occurrence of a overtime in an array, or -1 if it is not present.
   */
    overtimeIndexOf(overtime: SimpleOvertime, fromIndex?: number) {
        return this._overtimes.indexOf(overtime, fromIndex);
    }

    /**
   * Calculate the duration of the overtime rounded in hours
   *
   * @param overtime Overtime to calculate the duration
   * @param precise calculate in seconds if true else hours
   * @returns The duration of the overtime rounded in hours
   */
    static overtimeDuration(overtime: SimpleOvertime, precise = false, decimals = 2) {
        if (overtime.isNegative) {
            if (precise) return roundDecimals(moment.duration(overtime.dateFrom.diff(overtime.dateTo, 'seconds'), 'seconds').asSeconds(), decimals);
            else return roundDecimals(moment.duration(overtime.dateFrom.diff(overtime.dateTo, 'seconds'), 'seconds').asHours(), decimals);
        } else {
            if (precise) return roundDecimals(moment.duration(overtime.dateTo.diff(overtime.dateFrom, 'seconds'), 'seconds').asSeconds(), decimals);
            else return roundDecimals(moment.duration(overtime.dateTo.diff(overtime.dateFrom, 'seconds'), 'seconds').asHours(), decimals);
        }
    }

    /**
   * Calculate the duration of overtimes from an array of events rounded in hours
   *
   * @param events An array of events to calculate overtimes duration
   * @param type OvertimesCalculatedTypes.OVERTIMES or OvertimesCalculatedTypes.UNDERTIMES or OvertimesCalculatedTypes.ALL
   * @param status OvertimesCalculatedStatus.WAITING
   * @param calculateNotCountAsWorktime Calculate duration of events with countAsWorktime == false
   * @param calculateNotInReports Calculate duration of events with inReports == false
   * @param precise calculate in seconds if true else hours
   * @returns the duration of overtimes from an array of events rounded in hours
   */
    static overtimesDuration = (events: SimpleEvent[], type: OvertimesCalculatedTypes, status: OvertimesCalculatedStatus, calculateNotCountAsWorktime = false, calculateNotInReports = false, precise = false, decimals = 2) => {
        let totalHours = 0.0;
        totalHours = events.reduce((total: number, event: SimpleEvent) => {
            if (calculateNotCountAsWorktime === false) {
                if (event.typeOfDay?.countAsWorktime === false || event.typeOfDayOff?.countAsWorktime === false) {
                    return total;
                }
            }

            if (calculateNotInReports !== true) {
                if (event.typeOfDay?.inReports === false || event.typeOfDayOff?.inReports === false) {
                    return total;
                }
            }

            const subTotal = event.overtimes.reduce((overtimeTotal: number, overtime: SimpleOvertime) => {
                switch (type) {
                    case OvertimesCalculatedTypes.OVERTIMES:
                        if (overtime.isNegative === true) return overtimeTotal;
                        break;
                    case OvertimesCalculatedTypes.UNDERTIMES:
                        if (overtime.isNegative === false) return overtimeTotal;
                        break;
                    case OvertimesCalculatedTypes.ALL:
                        break;
                }

                switch (status) {
                    case OvertimesCalculatedStatus.CONFIRMED:
                        if (overtime.isConfirmed !== true) return overtimeTotal;
                        break;
                    case OvertimesCalculatedStatus.REFUSED:
                        if (overtime.isRefused !== true) return overtimeTotal;
                        break;
                    case OvertimesCalculatedStatus.WAITING:
                        if (overtime.isConfirmed !== false && overtime.isRefused !== false) return overtimeTotal;
                        break;
                    case OvertimesCalculatedStatus.ALL:
                        break;
                }

                return overtimeTotal + UserEventsData.overtimeDuration(overtime, precise, decimals);
            }, 0.0);

            return total + subTotal;
        }, 0.0);

        return roundDecimals(totalHours, decimals);
    };

    /**
   * Calculate the duration of all overtimes rounded in hours
   *
   * @param type OvertimesCalculatedTypes.OVERTIMES or OvertimesCalculatedTypes.UNDERTIMES or OvertimesCalculatedTypes.ALL
   * @param status 
   * @param calculateNotCountAsWorktime Calculate duration of events with countAsWorktime == false
   * @param calculateNotInReports Calculate duration of events with inReports == false
   * @returns the duration of all overtimes rounded in hours
   */
    overtimesTotalDuration = (type: OvertimesCalculatedTypes, status: OvertimesCalculatedStatus, calculateNotCountAsWorktime = false, calculateNotInReports = false, precise = false) => {
        return UserEventsData.overtimesDuration(this._events, type, status, calculateNotCountAsWorktime, calculateNotInReports, precise);
    };
    // #endregion

    // #region Breaktimes
    get breaktimes(): SimpleBreaktime[] {
        return this._breaktimes;
    }

    set breaktimes(breaktimes: SimpleBreaktime[]) {
        this._breaktimes = breaktimes;
    }

    /**
   * Appends new breaktime to the end of an array, and returns the new length of the array.
   *
   * @param breaktime New breaktime to add to the array.
   *
   * @returns The new length of the array
   */
    addBreaktime(breaktime: SimpleBreaktime) {
        return this._breaktimes.push(breaktime);
    }

    /**
     * Removes breaktimes from an array and, if necessary, inserts new breaktime in their place, returning the deleted breaktimes.
     *
     * @param start The zero-based location in the array from which to start removing breaktimes.
     * @param deleteCount The number of breaktimes to remove.
     * @param breaktime Overtime to insert into the array in place of the deleted breaktimes.
     *
     * @returns An array containing the breaktimes that were deleted.
     */
    breaktimeSplice(start: number, deleteCount: number | undefined = 1, breaktime?: SimpleBreaktime) {
        if (breaktime === undefined) {
            this._breaktimes.splice(start, deleteCount);
        } else {
            this._breaktimes.splice(start, deleteCount, breaktime);
        }
    }

    /**
   * Calls a defined callback function on each breaktime of a array , and returns an array that contains the results.
   *
   * @param predicate A function that accepts up to three arguments. The map method calls the callbackfn function one time for each breaktime in the array.
   * @returns
   */
    breaktimeMap(callbackfn: (value: SimpleBreaktime, index?: number, obj?: SimpleBreaktime[]) => boolean) {
        return this._breaktimes.map(callbackfn);
    }

    /**
   * Performs the specified action for each breaktime in an array.
   *
   * @param callbackfn  A function that accepts up to three arguments. forEach calls the callbackfn function one time for each breaktime in the array.
   * @returns
   */
    breaktimeForeach(callbackfn: (value: SimpleBreaktime, index?: number, obj?: SimpleBreaktime[]) => boolean) {
        this._breaktimes.forEach(callbackfn);
    }

    /**
   * Returns the breaktimes of an array that meet the condition specified in a callback function.
   *
   * @param predicate A function that accepts up to three arguments. The filter method calls the predicate function one time for each breaktime in the array.
   * @returns
   */
    breaktimeFilter(predicate: (value: SimpleBreaktime, index?: number, obj?: SimpleBreaktime[]) => boolean) {
        return this._breaktimes.filter(predicate);
    }

    /**
   * Returns the index of the first breaktime in the array where predicate is true, and -1 otherwise.
   *
   * @param predicate Find calls predicate once for each element of the array, in ascending order, until it finds one where predicate returns true. If such an breaktime is found, findIndex immediately returns that breaktime index. Otherwise, findIndex returns -1.
   * @returns If an breaktime is found, the index is returned. Otherwise, returns -1.
   */
    breaktimeFindIndex(predicate: (value: SimpleBreaktime, index?: number, obj?: SimpleBreaktime[]) => boolean) {
        return this._breaktimes.findIndex(predicate);
    }

    /**
   * Returns the value of the first breaktime in the array where predicate is true, and undefined otherwise.
   *
   * @param predicate Find calls predicate once for each breaktime of the array, in ascending order, until it finds one where predicate returns true. If such an breaktime is found, find immediately returns that breaktime value. Otherwise, find returns undefined.
   * @returns If an breaktime is found it is returned. Otherwise, returns undefined.
   */
    breaktimeFind(predicate: (value: SimpleBreaktime, index?: number, obj?: SimpleBreaktime[]) => boolean) {
        return this._breaktimes.find(predicate);
    }

    /**
   * Returns the index of the first occurrence of a breaktime in an array, or -1 if it is not present.
   *
   * @param event The breaktime to locate in the array.
   * @param fromIndex The array index at which to begin the search. If fromIndex is omitted, the search starts at index 0.
   * @returns the index of the first occurrence of a breaktime in an array, or -1 if it is not present.
   */
    breaktimeIndexOf(breaktime: SimpleBreaktime, fromIndex?: number) {
        return this._breaktimes.indexOf(breaktime, fromIndex);
    }

    /**
   * Calculate the duration of the breaktime rounded in hours
   *
   * @param breaktime breaktime to calculate the duration
   * @param precise calculate in seconds if true else hours
   * @returns The duration of the breaktime rounded in hours
   */
    static breaktimeDuration = (breaktime: SimpleBreaktime, precise = false, decimals = 2) => {
        if (precise) return roundDecimals(moment.duration(breaktime.dateTo.diff(breaktime.dateFrom, 'seconds'), 'seconds').asSeconds(), decimals);
        else return roundDecimals(moment.duration(breaktime.dateTo.diff(breaktime.dateFrom, 'seconds'), 'seconds').asHours(), decimals);
    };

    /**
   * Calculate the duration of breaktimes from an array of events rounded in hours
   *
   * @param events An array of events to calculate breaktimes duration
   * @param type BreaktimeCalculatedTypes.PAID or BreaktimeCalculatedTypes.NOTPAID or BreaktimeCalculatedTypes.ALL
   * @param calculateNotCountAsWorktime Calculate duration of events with countAsWorktime == false
   * @param calculateNotInReports Calculate duration of events with inReports == false
   * @param precise calculate in seconds if true else hours
   * @returns the duration of breaktimes from an array of events rounded in hours
   */
    static breaktimesDuration = (events: SimpleEvent[], type: BreaktimeCalculatedTypes, calculateNotCountAsWorktime = false, calculateNotInReports = false, precise = false, decimals = 2) => {
        let totalHours = 0.0;
        totalHours = events.reduce((total: number, event: SimpleEvent) => {
            if (calculateNotCountAsWorktime === false) {
                if (event.typeOfDay?.countAsWorktime === false || event.typeOfDayOff?.countAsWorktime === false) {
                    return total;
                }
            }

            if (calculateNotInReports !== true) {
                if (event.typeOfDay?.inReports === false || event.typeOfDayOff?.inReports === false) {
                    return total;
                }
            }

            const subTotal = event.breakTimes.reduce((breaktimeTotal: number, breaktime: SimpleBreaktime) => {
                switch (type) {
                    case BreaktimeCalculatedTypes.PAID:
                        if (breaktime.isPaid !== true) return breaktimeTotal;
                        break;
                    case BreaktimeCalculatedTypes.NOTPAID:
                        if (breaktime.isPaid === true) return breaktimeTotal;
                        break;
                    case BreaktimeCalculatedTypes.ALL:
                        break;
                }
                return breaktimeTotal + UserEventsData.breaktimeDuration(breaktime, precise, decimals);
            }, 0.0);

            return total + subTotal;
        }, 0.0);

        return roundDecimals(totalHours, decimals);
    };

    /**
   * Calculate the duration of all breaktimes rounded in hours
   *
   * @param type BreaktimeCalculatedTypes.PAID or BreaktimeCalculatedTypes.NOTPAID or BreaktimeCalculatedTypes.ALL
   * @param calculateNotCountAsWorktime Calculate duration of events with countAsWorktime == false
   * @param calculateNotInReports Calculate duration of events with inReports == false
   * @param precise calculate in seconds if true else hours
   * @returns the duration of all breaktimes rounded in hours
   */
    breaktimesTotalDuration = (type: BreaktimeCalculatedTypes, calculateNotCountAsWorktime = false, calculateNotInReports = false, precise = false) => {
        UserEventsData.breaktimesDuration(this._events, type, calculateNotCountAsWorktime, calculateNotInReports, precise);
    };
    // #endregion

    // #region Calcs
    static eventsByMonth(events: SimpleEvent[], startMonth: Moment, endMonth: Moment) {
        events = cloneDeep(events);
        startMonth = startMonth.clone();
        endMonth = endMonth.clone();
        const eventsByMonth: EventsByMonth = {};

        const monthInCalculation = startMonth.clone();

        while (monthInCalculation.isSameOrBefore(endMonth, "month")) {
            const monthEvents: SimpleEvent[] = remove(events, event => {
                return event.dateFrom.isSame(monthInCalculation, "month");
            });
            switch (monthInCalculation.format('M')) {
                case "1":
                    eventsByMonth.janHours = monthEvents;
                    break;
                case "2":
                    eventsByMonth.febHours = monthEvents;
                    break;
                case "3":
                    eventsByMonth.marHours = monthEvents;
                    break;
                case "4":
                    eventsByMonth.aprHours = monthEvents;
                    break;
                case "5":
                    eventsByMonth.mayHours = monthEvents;
                    break;
                case "6":
                    eventsByMonth.junHours = monthEvents;
                    break;
                case "7":
                    eventsByMonth.julHours = monthEvents;
                    break;
                case "8":
                    eventsByMonth.augHours = monthEvents;
                    break;
                case "9":
                    eventsByMonth.sepHours = monthEvents;
                    break;
                case "10":
                    eventsByMonth.octHours = monthEvents;
                    break;
                case "11":
                    eventsByMonth.novHours = monthEvents;
                    break;
                case "12":
                    eventsByMonth.decHours = monthEvents;
                    break;
            }
            monthInCalculation.add(1, 'month');
        }

        return eventsByMonth;
    }

    eventsByMonthInit(startMonth: Moment, endMonth: Moment) {
        this._startMonth = startMonth.clone();
        this._endMonth = endMonth.clone();
        this._eventsByMonth = UserEventsData.eventsByMonth(this._events, startMonth, endMonth);
        return this._eventsByMonth;
    }

    static eventsMonthlyCalculate(events: SimpleEvent[], prevBalance?: number, workRate?: number, monthlyHours?: number) {
        const hours: UserHoursSummaryInMonth = {};

        if (workRate !== undefined && monthlyHours !== undefined) {
            const workRateDecimal = roundDecimals(workRate / 100);
            hours.todoHours = roundDecimals((workRateDecimal * monthlyHours));
        }
        // else if (workRate !== undefined) {

        // } else if (monthlyHours !== undefined) {

        // }

        hours.overtimeUndertimeBalance = this.overtimesDuration(events, OvertimesCalculatedTypes.ALL, OvertimesCalculatedStatus.CONFIRMED);
        hours.breaktimeBalance = UserEventsData.breaktimesDuration(events, BreaktimeCalculatedTypes.PAID);
        hours.effectiveHours = UserEventsData.eventsDuration(events);
        hours.totalCalculatedBalance = roundDecimals(hours.effectiveHours - hours.breaktimeBalance + hours.overtimeUndertimeBalance);
        hours.prevBalance = prevBalance ? roundDecimals(prevBalance) : prevBalance;
        hours.nextBalance = roundDecimals((hours.prevBalance ? hours.prevBalance : 0.0) + hours.totalCalculatedBalance - (hours.todoHours ? hours.todoHours : 0.0));

        return hours;
    }

    eventsContractCaculate(contract: EventsByContractByMonth, monthlyHours?: MonthlyHoursOfTheYear) {
        const userHoursSummary: UserHoursSummaryByYear = {};
        let startMonth = this._startMonth?.clone();
        let endMonth = this._endMonth?.clone();

        if (startMonth === undefined || endMonth === undefined) {
            return undefined;
        } else if (contract.startDate !== undefined && contract.endDate !== undefined) {
            if (contract.startDate.isAfter(startMonth, "days")) {
                startMonth = contract.startDate.clone();
            }
            if (contract.endDate.isBefore(endMonth, "days")) {
                endMonth = contract.endDate.clone();
            }
        }
        const events = cloneDeep(contract.events);
        const monthInCalculation = startMonth.clone();

        let prevBalance: number | undefined = contract.initialHours;
        userHoursSummary.startBalance = prevBalance;
        while (monthInCalculation.isSameOrBefore(endMonth, "month")) {
            let plannedMonthlyHours: number | undefined = undefined;
            const monthEvents: SimpleEvent[] = remove(events, event => {
                return event.dateFrom.isSame(monthInCalculation, "month");
            });
            switch (monthInCalculation.format('M')) {
                case "1":
                    plannedMonthlyHours = monthlyHours?.janHours;
                    userHoursSummary.janHours = UserEventsData.eventsMonthlyCalculate(monthEvents, prevBalance, contract.workRate, plannedMonthlyHours);
                    prevBalance = userHoursSummary.janHours?.nextBalance;
                    break;
                case "2":
                    plannedMonthlyHours = monthlyHours?.febHours;
                    userHoursSummary.febHours = UserEventsData.eventsMonthlyCalculate(monthEvents, prevBalance, contract.workRate, plannedMonthlyHours);
                    prevBalance = userHoursSummary.febHours?.nextBalance;
                    break;
                case "3":
                    plannedMonthlyHours = monthlyHours?.marHours;
                    userHoursSummary.marHours = UserEventsData.eventsMonthlyCalculate(monthEvents, prevBalance, contract.workRate, plannedMonthlyHours);
                    prevBalance = userHoursSummary.marHours?.nextBalance;
                    break;
                case "4":
                    plannedMonthlyHours = monthlyHours?.aprHours;
                    userHoursSummary.aprHours = UserEventsData.eventsMonthlyCalculate(monthEvents, prevBalance, contract.workRate, plannedMonthlyHours);
                    prevBalance = userHoursSummary.aprHours?.nextBalance;
                    break;
                case "5":
                    plannedMonthlyHours = monthlyHours?.mayHours;
                    userHoursSummary.mayHours = UserEventsData.eventsMonthlyCalculate(monthEvents, prevBalance, contract.workRate, plannedMonthlyHours);
                    prevBalance = userHoursSummary.mayHours?.nextBalance;
                    break;
                case "6":
                    plannedMonthlyHours = monthlyHours?.junHours;
                    userHoursSummary.junHours = UserEventsData.eventsMonthlyCalculate(monthEvents, prevBalance, contract.workRate, plannedMonthlyHours);
                    prevBalance = userHoursSummary.junHours?.nextBalance;
                    break;
                case "7":
                    plannedMonthlyHours = monthlyHours?.julHours;
                    userHoursSummary.julHours = UserEventsData.eventsMonthlyCalculate(monthEvents, prevBalance, contract.workRate, plannedMonthlyHours);
                    prevBalance = userHoursSummary.julHours?.nextBalance;
                    break;
                case "8":
                    plannedMonthlyHours = monthlyHours?.augHours;
                    userHoursSummary.augHours = UserEventsData.eventsMonthlyCalculate(monthEvents, prevBalance, contract.workRate, plannedMonthlyHours);
                    prevBalance = userHoursSummary.augHours?.nextBalance;
                    break;
                case "9":
                    plannedMonthlyHours = monthlyHours?.sepHours;
                    userHoursSummary.sepHours = UserEventsData.eventsMonthlyCalculate(monthEvents, prevBalance, contract.workRate, plannedMonthlyHours);
                    prevBalance = userHoursSummary.sepHours?.nextBalance;
                    break;
                case "10":
                    plannedMonthlyHours = monthlyHours?.octHours;
                    userHoursSummary.octHours = UserEventsData.eventsMonthlyCalculate(monthEvents, prevBalance, contract.workRate, plannedMonthlyHours);
                    prevBalance = userHoursSummary.octHours?.nextBalance;
                    break;
                case "11":
                    plannedMonthlyHours = monthlyHours?.novHours;
                    userHoursSummary.novHours = UserEventsData.eventsMonthlyCalculate(monthEvents, prevBalance, contract.workRate, plannedMonthlyHours);
                    prevBalance = userHoursSummary.novHours?.nextBalance;
                    break;
                case "12":
                    plannedMonthlyHours = monthlyHours?.decHours;
                    userHoursSummary.decHours = UserEventsData.eventsMonthlyCalculate(monthEvents, prevBalance, contract.workRate, plannedMonthlyHours);
                    prevBalance = userHoursSummary.decHours?.nextBalance;
                    break;
            }
            monthInCalculation.add(1, 'month');
        }

        userHoursSummary.endBalance = prevBalance;

        return userHoursSummary;
    }

    getInitialOvertimeHours = (contractId: number) => {
        let initialOvertimeHours = 0;

        let foundData = this._initialHoursByContractByYear?.find(ih => ih.contractId === contractId);
        if (!foundData) {
            foundData = this._initialHoursByContractByYear?.find(ih => ih.contractId === -1);
        }
        if (foundData) {
            initialOvertimeHours = foundData.initialHours;
        }

        return initialOvertimeHours;
    };

    eventsByContractByMonth(events: SimpleEvent[], contracts: SimpleContract[], startMonth: Moment, endMonth: Moment, monthlyHours?: MonthlyHoursOfTheYear) {
        const eventsByContractByMonth: EventsByContractByMonth[] = [];
        events = cloneDeep(events);
        contracts?.forEach(((contract) => {
            const contractEvents = remove(events, (event) => {
                return moment(event.dateFrom).isBetween(moment(contract.startDate), moment(contract.endDate), "day", "[]");
            });
            const contractEvent: EventsByContractByMonth = {
                id: contract.id,
                name: contract.name,
                typeOfContract: contract.typeOfContract,
                department: contract.department,
                startDate: moment(contract.startDate),
                endDate: moment(contract.endDate),
                vacationIncreasedPercentByHour: contract.vacationIncreasedPercentByHour,
                dayWorkingHours: contract.weeklyWorkingHours / 5,
                workRate: contract.workRate,
                initialHours: this.getInitialOvertimeHours(contract.id),
                withoutContract: false,
                events: contractEvents,
                calculatedEvents: {}
            };
            const calculatedEvents = this.eventsContractCaculate(contractEvent, monthlyHours);
            if (calculatedEvents === undefined) {
                return;
            }
            contractEvent.calculatedEvents = calculatedEvents;
            eventsByContractByMonth.push(contractEvent);
        }));

        if (events.length > 0) {
            const withoutContractEvents = events;
            const contractEvent: EventsByContractByMonth = {
                id: -1,
                name: "Sans contrat",
                startDate: startMonth,
                endDate: endMonth,
                dayWorkingHours: -1,
                workRate: -1,
                initialHours: 0.0,
                withoutContract: true,
                events: withoutContractEvents,
                calculatedEvents: {},
            };
            const calculatedEvents = this.eventsContractCaculate(contractEvent,);
            if (calculatedEvents === undefined) {
                showNotification("Problem dev", "error");
                return;
            }
            contractEvent.calculatedEvents = calculatedEvents;
            eventsByContractByMonth.push(contractEvent);
        }

        return eventsByContractByMonth;
    }

    eventsByContractByMonthInit(startMonth: Moment, endMonth: Moment, monthlyHours?: MonthlyHoursOfTheYear, initialHoursByContractByYear?: InitialHoursByContractByYear[]) {
        this._startMonth = startMonth;
        this._endMonth = endMonth;
        this._monthlyHours = monthlyHours;
        this._initialHoursByContractByYear = initialHoursByContractByYear;
        if (this._contracts) {
            this._eventsByContractByMonth = this.eventsByContractByMonth(this._events, this._contracts, startMonth, endMonth, monthlyHours);
            return this._eventsByContractByMonth;
        } else {
            // TODO If not exist create customexception extends exception , not init contracts on constructor
            throw Error("Contracts undifined");
        }
    }
    // #endregion
}