import { Col, Divider, Image, Row } from 'antd';
import { IIntegrationDetailsDisplayCharts, IIntegrationSyncButtons } from '../../../utils/types/integrationTypes';
import IntegrationChart from '../../common/chart/integrationChart';
import IntegrationSync from '../../common/chart/integrationSync';

interface Props {
    logo: string;
    name: string;
    displays: IIntegrationDetailsDisplayCharts[];
    syncButtons: IIntegrationSyncButtons[];
}

//TODO: Fix for all cases, only for presentation
const IntegrationDetails = (props: Props) => {
    return (
        <>
            <Divider orientation='left' plain>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    <Image src={props.logo} alt='logo' className='dashboard-company-logo' fallback={`/icons/fallback.png`} preview={false} style={{ width: 75, minWidth: 75, maxHeight: 75, maxWidth: 75 }} />
                    {props.name}
                </div>
            </Divider>
            <Row gutter={[10, 10]}>
                <Col xs={{ span: 24 }} md={{ span: 24 }} sm={{ span: 24 }}>
                    <Row gutter={[10, 10]}>
                        {props.displays.map((display, index) => {
                            return (
                                <Col key={`dashboard-display-${props.name?.toLocaleLowerCase().trim()}-${index}`} xs={{ span: 24 }} md={{ span: 12 }} style={{ position: 'relative' }}>
                                    <IntegrationChart
                                        data={{
                                            labels: display.labels,
                                            datasets: [
                                                {
                                                    data: display.data.map(value => value > 1 ? Math.log10(value) : value),
                                                    backgroundColor: display.colors,
                                                    borderWidth: 0,
                                                },
                                            ],
                                        }}
                                        originalData={display.data}
                                        title={display.title}
                                        value={
                                            <>
                                                {
                                                    display.display === 'all' ?
                                                        <>
                                                            {
                                                                display.data.length > 0 ? display.data.reduce((acc, value) => acc + value, 0) : 0
                                                            }
                                                        </>
                                                        :
                                                        <>
                                                            {display.data.length > 0 ? display.data[1] : 0}/{display.data.length > 0 ? display.data.reduce((acc, value) => acc + value, 0) : 0}
                                                        </>
                                                }
                                            </>
                                        }
                                    />
                                </Col>
                            );
                        })}
                    </Row>
                    <Divider />
                    <Row gutter={[10, 10]}>
                        {props.syncButtons.map((syncButton, index) => {
                            return (
                                <Col key={`dashboard-display-${props.name?.toLocaleLowerCase().trim()}-${index}`} xs={{ span: 24 }} md={{ span: 12 }} xl={{ span: 8 }} xxl={{ span: 12 }} style={{ position: 'relative' }}>
                                    <IntegrationSync lastSync={syncButton.lastSync} title={syncButton.title} percentage={syncButton.percentage} onClick={syncButton.onClick} />
                                </Col>
                            );
                        })}
                    </Row>
                </Col>
            </Row>
        </>
    );
};

export default IntegrationDetails;
