import { Col, Row } from 'antd';
import React from 'react';
import { injectIntl } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { Privileges } from '../../privileges';
import { changeGroups } from '../../store/actions/teamManagement';
import { changeCompany } from '../../store/actions/user';
import '../../styles/dashboard.css';
import Network from '../../utils/network';
import { Company, Group, RouterProps } from '../../utils/types/generalTypes';
import { ApplicationState, StoreDispatch, TeamManagementDispatchProps, UserDispatchProps } from '../../utils/types/storeTypes';
import { checkPrivilege, showNotification } from '../../utils/utils';
import { IntlProps } from '../app/LanguageProvider';
import Container from '../common/navigations/container';
import AvailabilitiesConfirmation from './components/availabilitiesConfirmation';
import DownloadApp from './components/downloadApp';
import Expiry from './components/expiry';
import General from './components/general';
import Integrations from './components/integration';
import OccupancyRatesControl from './components/occupancyRatesControl';
import PlanningV2 from './components/planningV2';

type ReduxProps = ConnectedProps<typeof connector>;


type Props = RouterProps & TeamManagementDispatchProps & UserDispatchProps & ReduxProps & IntlProps;

class Dashboard extends React.Component<Props> {

    componentDidMount() {
        //get company details
        Network.getCompanyDetails().then(
            response => this.props.changeCompany!(response),
            () => showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading your company' }), "warning")
        );
    }

    render() {
        const { company } = this.props;
        return (
            <Container className="container-content-background-dashboard" breadcrumb={[{ title: "Dashboard", link: "" }]}>
                <div className="dashboard-parent">
                    {
                        this.props.windowWidth >= 2560 ?
                            <Row gutter={[20, 10]}>
                                <Col xs={{ span: 24 }} xxl={{ span: 8 }}>
                                    <div className="item2">{checkPrivilege(Privileges.Dashboard.Display, this.props.currentUser) && <General />}</div>
                                    <div className="item2">{checkPrivilege(Privileges.Dashboard.Display, this.props.currentUser) && !checkPrivilege(Privileges.CRM.Visit, this.props.currentUser) && <Expiry />}</div>

                                    {/* <div className="item2">{this.props.currentUser?.role === 2 && !checkPrivilege(Privileges.CRM.Visit, this.props.currentUser) && this.renderAvailability()}</div> */}
                                </Col>
                                <Col xs={{ span: 24 }} xxl={{ span: 8 }}>
                                    <div className="item2">{checkPrivilege(Privileges.Dashboard.Display, this.props.currentUser) && checkPrivilege(Privileges.Planning.Visit, this.props.currentUser) && <OccupancyRatesControl />}</div>
                                    <div className="item2">{checkPrivilege(Privileges.Dashboard.Display, this.props.currentUser) && checkPrivilege(Privileges.Planning.Visit, this.props.currentUser) && company && <AvailabilitiesConfirmation />}</div>
                                    {/* <div className="item2">{this.props.currentUser?.role === 1 && !checkPrivilege(Privileges.CRM.Visit, this.props.currentUser) && this.renderExclusions()}</div> */}
                                </Col>
                                <Col xs={{ span: 24 }} xxl={{ span: 8 }}>
                                    <div className="item2">{!checkPrivilege(Privileges.Dashboard.Display, this.props.currentUser) && !checkPrivilege(Privileges.CRM.Visit, this.props.currentUser) && <DownloadApp />}</div>
                                    <div className="item2">{!checkPrivilege(Privileges.CRM.Visit, this.props.currentUser) && <PlanningV2 />}</div>
                                    {/* <div className="item2">{this.props.currentUser?.role === 2 && checkPrivilege(Privileges.Planning.Visit, this.props.currentUser) && company && this.renderOvertimesNotConfirmed()}</div> */}
                                    {/* <div className="item2">{this.props.currentUser?.role === 2 && checkPrivilege(Privileges.Planning.Visit, this.props.currentUser) && company && this.renderPeriodsNotConfirmed()}</div> */}
                                    {/* <div className="item2">{this.props.currentUser?.role === 2 && checkPrivilege(Privileges.Planning.Visit, this.props.currentUser) && company && this.renderExclusionsNotConfirmed()}</div> */}
                                    {/* <div className="item2">{this.props.currentUser?.role === 2 && checkPrivilege(Privileges.Planning.Visit, this.props.currentUser) && checkCompanyDetailsPrivilege(CompanyDetailsPrivilege.TimeClock.Visit, this.props.company, this.props.currentUser) && <TimeclockControl isDashboard />}</div> */}
                                </Col>
                            </Row>
                            :
                            <Row gutter={[20, 10]}>
                                <Col xs={{ span: 24 }} xxl={{ span: 12 }}>
                                    <div className="item2">{checkPrivilege(Privileges.Dashboard.Display, this.props.currentUser) && <General />}</div>
                                    <div className="item2">{checkPrivilege(Privileges.Dashboard.Display, this.props.currentUser) && !checkPrivilege(Privileges.CRM.Visit, this.props.currentUser) && <Expiry />}</div>
                                    <div className="item2">{checkPrivilege(Privileges.Dashboard.Display, this.props.currentUser) && checkPrivilege(Privileges.Planning.Visit, this.props.currentUser) && company && <AvailabilitiesConfirmation />}</div>
                                    <div className="item2">{!checkPrivilege(Privileges.Dashboard.Display, this.props.currentUser) && !checkPrivilege(Privileges.CRM.Visit, this.props.currentUser) && <DownloadApp />}</div>

                                    {/* <div className="item2">{this.props.currentUser?.role === 2 && !checkPrivilege(Privileges.CRM.Visit, this.props.currentUser) && this.renderAvailability()}</div> */}
                                    {/* <div className="item2">{this.props.currentUser?.role === 2 && checkPrivilege(Privileges.Planning.Visit, this.props.currentUser) && checkCompanyDetailsPrivilege(CompanyDetailsPrivilege.TimeClock.Visit, this.props.company, this.props.currentUser) && <TimeclockControl isDashboard />}</div> */}
                                </Col>
                                <Col xs={{ span: 24 }} xxl={{ span: 12 }} >

                                    <div className="item2">{checkPrivilege(Privileges.Dashboard.Display, this.props.currentUser) && checkPrivilege(Privileges.Planning.Visit, this.props.currentUser) && <OccupancyRatesControl />}</div>
                                    <div className="item2">{!checkPrivilege(Privileges.CRM.Visit, this.props.currentUser) && <PlanningV2 />}</div>
                                    <div className="item2">{checkPrivilege(Privileges.Dashboard.Display, this.props.currentUser) && checkPrivilege(Privileges.Integrations.Admin, this.props.currentUser)  && <Integrations />}</div>


                                    {/* <div className="item2">{this.props.currentUser?.role === 2 && checkPrivilege(Privileges.Planning.Visit, this.props.currentUser) && company && this.renderOvertimesNotConfirmed()}</div> */}
                                    {/* <div className="item2">{this.props.currentUser?.role === 2 && checkPrivilege(Privileges.Planning.Visit, this.props.currentUser) && company && this.renderPeriodsNotConfirmed()}</div> */}
                                    {/* <div className="item2">{this.props.currentUser?.role === 2 && checkPrivilege(Privileges.Planning.Visit, this.props.currentUser) && company && this.renderExclusionsNotConfirmed()}</div> */}

                                    {/* <div className="item2">{this.props.currentUser?.role === 1 && !checkPrivilege(Privileges.CRM.Visit, this.props.currentUser) && this.renderExclusions()}</div> */}
                                </Col>
                            </Row>

                    }
                </div>
            </Container>
        );
    }
}

const mapDispatchToProps = (dispatch: StoreDispatch) => ({
    changeGroups: (g: Group[]) => dispatch(changeGroups(g)),
    changeCompany: (c: Company) => dispatch(changeCompany(c)),
});

const mapStateToProps = (state: ApplicationState) => ({
    isSmartphone: state.window.isSmartphone,
    windowWidth: state.window.width,
    groups: state.teamManagement.groups,
    company: state.user.company,
    currentUser: state.user.currentUser,
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(injectIntl(Dashboard));