/* eslint-disable @typescript-eslint/no-unused-vars */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { reject } from 'lodash';
import moment from 'moment';
import { ReloadTimes } from '../../utils/constants';
import Network from '../../utils/network';
import { ApplicationState, IntegrationsState, StatusType } from '../../utils/types/storeTypes';
import { isDeprecated, showNotification } from '../../utils/utils';

export enum IntegrationSyncActions {
    USERS = 'users',
    MANDATES = 'mandates',
    DATA = 'data',
    CUSTOMERS = 'customers'
}

export enum IntegrationSyncCompany {
    GLOBAL_OFFICE = 'globaloffice',
    HOTELA = 'hotela',
}

export const initialState: IntegrationsState = {
    enabledIntegrations: {
        status: StatusType.NONE 
    },
    hotelaDetails: {
        status: StatusType.NONE,
    },
    globalOfficeDetails: {
        status: StatusType.NONE,
    },
    officeMakerDetails: {
        status: StatusType.NONE,
    },
};

//* Integrations
export const enabledIntegrationsFetched = createAsyncThunk('integrations/enabled/fetched', async (arg: { forceReload?: boolean }, thunkAPI) => {
    const response = await Network.getIntegrations();
    return { updatedAt: moment().format(), data: response.data };
},
{
    condition: (arg: { forceReload: boolean }, thunkAPI) => {
        const { integrations } = thunkAPI.getState() as ApplicationState;
        return arg.forceReload || isDeprecated(integrations.enabledIntegrations.updatedAt ? moment(integrations.enabledIntegrations.updatedAt) : undefined, ReloadTimes.FAST_RELOAD);
    },
});


//* Details
export const hotelaDetailsFetched = createAsyncThunk('integrations/hotela/details/fetched', async (arg: { forceReload?: boolean }, thunkAPI) => {
    const response = await Network.getHotelaDetails();
    return { updatedAt: moment().format(), data: response.data };
},
{
    condition: (arg: { forceReload: boolean }, thunkAPI) => {
        const { integrations } = thunkAPI.getState() as ApplicationState;
        return integrations.enabledIntegrations.data?.hotelaEnabled && (arg.forceReload || isDeprecated(integrations.hotelaDetails.updatedAt ? moment(integrations.hotelaDetails.updatedAt) : undefined, ReloadTimes.FAST_RELOAD));
    },
});

export const globalOfficeDetailsFetched = createAsyncThunk('integrations/globalOffice/details/fetched', async (arg: { forceReload?: boolean }, thunkAPI) => {
    const response = await Network.getGoDetails();
    return { updatedAt: moment().format(), data: response.data };
},
{
    condition: (arg: { forceReload: boolean }, thunkAPI) => {
        const { integrations } = thunkAPI.getState() as ApplicationState;
        return integrations.enabledIntegrations.data?.globalOfficeEnabled && (arg.forceReload || isDeprecated(integrations.globalOfficeDetails.updatedAt ? moment(integrations.globalOfficeDetails.updatedAt) : undefined, ReloadTimes.FAST_RELOAD));
    },
});

//* Synchronisations
//? Global Office
export const globalOfficeUsersSynced = createAsyncThunk('integrations/globalOffice/users/synced', async (args: {forceReload?: boolean}, thunkAPI) => {
    const response = await Network.syncGlobalOffice(IntegrationSyncActions.USERS)
    if (response.error){
        if(response.code === 'integrations-already-syncing-601'){
            showNotification('Synchronisation en cours', 'info', 'Veuillez attendre quelques instants, ou recharger la page pour voir le chargement en cours.')
        }
        reject
    }
    return { updatedAt: moment().format(), data: response.data };
},
{
    condition: (arg: {forceReload: boolean}, thunkAPI) => {
        const { integrations } = thunkAPI.getState() as ApplicationState;
        return (integrations.globalOfficeDetails.data?.sync.users?.percentage === 100 || integrations.globalOfficeDetails.data?.sync.users.lastSync === undefined) && isDeprecated(integrations.globalOfficeDetails.data?.sync.users.lastSync ? moment(integrations.globalOfficeDetails.data?.sync.users.lastSync) : undefined, ReloadTimes.SPEED_RELOAD);
    }
});

export const globalOfficeCustomersSynced = createAsyncThunk('integrations/globalOffice/customers/synced', async (args: {forceReload?: boolean}, thunkAPI) => {
    const response = await Network.syncGlobalOffice(IntegrationSyncActions.CUSTOMERS)
    return { updatedAt: moment().format(), data: response.data };
},
{
    condition: (arg: {forceReload: boolean}, thunkAPI) => {
        const { integrations } = thunkAPI.getState() as ApplicationState;
        return (integrations.globalOfficeDetails.data?.sync.customers?.percentage === 100 || integrations.globalOfficeDetails.data?.sync.customers?.lastSync === undefined) && isDeprecated(integrations.globalOfficeDetails.data?.sync.customers?.lastSync ? moment(integrations.globalOfficeDetails.data?.sync.customers?.lastSync) : undefined, ReloadTimes.SPEED_RELOAD);
    }
});

export const globalOfficeDataSynced = createAsyncThunk('integrations/globalOffice/data/synced', async (args: {forceReload?: boolean}, thunkAPI) => {
    const response = await Network.syncGlobalOffice(IntegrationSyncActions.DATA)
    return { updatedAt: moment().format(), data: response.data };
},
{
    condition: (arg: {forceReload: boolean}, thunkAPI) => {
        const { integrations } = thunkAPI.getState() as ApplicationState;
        return (integrations.globalOfficeDetails.data?.sync.data?.percentage === 100 || integrations.globalOfficeDetails.data?.sync.data?.lastSync === undefined) && isDeprecated(integrations.globalOfficeDetails.data?.sync.data?.lastSync ? moment(integrations.globalOfficeDetails.data?.sync.data?.lastSync) : undefined, ReloadTimes.SPEED_RELOAD);
    }
});

export const globalOfficeMandatesSynced = createAsyncThunk('integrations/globalOffice/mandates/synced', async (args: {forceReload?: boolean}, thunkAPI) => {
    const response = await Network.syncGlobalOffice(IntegrationSyncActions.MANDATES)
    return { updatedAt: moment().format(), data: response.data };
},
{
    condition: (arg: {forceReload: boolean}, thunkAPI) => {
        const { integrations } = thunkAPI.getState() as ApplicationState;
        return (integrations.globalOfficeDetails.data?.sync.mandates?.percentage === 100 || integrations.globalOfficeDetails.data?.sync.mandates?.lastSync === undefined) && isDeprecated(integrations.globalOfficeDetails.data?.sync.mandates?.lastSync ? moment(integrations.globalOfficeDetails.data?.sync.mandates?.lastSync) : undefined, ReloadTimes.SPEED_RELOAD);
    }
});

//? Hotela
export const hotelaUsersSynced = createAsyncThunk('integrations/hotela/users/synced', async (args: {forceReload?: boolean}, thunkAPI) => {
    const response = await Network.syncHotela(IntegrationSyncActions.USERS)
    return { updatedAt: moment().format(), data: response.data };
},
{
    condition: (arg: {forceReload: boolean}, thunkAPI) => {
        const { integrations } = thunkAPI.getState() as ApplicationState;
        return (integrations.hotelaDetails.data?.sync.users?.percentage === 100 || integrations.globalOfficeDetails.data?.sync.users?.lastSync === undefined) && isDeprecated(integrations.globalOfficeDetails.data?.sync.users?.lastSync ? moment(integrations.globalOfficeDetails.data?.sync.users?.lastSync) : undefined, ReloadTimes.SPEED_RELOAD);
    }
});

const integrationsSlice = createSlice({
    name: 'integrations',
    initialState,
    reducers: {
        reset: () => {
            return {
                ...initialState,
            };
        },
    },
    extraReducers(builder) {
        //* Enabled integrations *//
        builder.addCase(enabledIntegrationsFetched.fulfilled, (state, action) => {
            state.enabledIntegrations = {
                ...state.enabledIntegrations,
                data: action.payload.data,
                updatedAt: action.payload.updatedAt,
                status: StatusType.FULFILLED,
            };
        });
        builder.addCase(enabledIntegrationsFetched.pending, (state) => {
            state.enabledIntegrations = {
                ...state.enabledIntegrations,
                status: StatusType.PENDING,
            };
        });
        builder.addCase(enabledIntegrationsFetched.rejected, (state) => {
            state.enabledIntegrations = {
                ...state.enabledIntegrations,
                status: StatusType.REJECTED,
            };
        });

        //* Hotela details *//
        builder.addCase(hotelaDetailsFetched.fulfilled, (state, action) => {
            state.hotelaDetails = {
                ...state.hotelaDetails,
                data: action.payload.data,
                updatedAt: action.payload.updatedAt,
                status: StatusType.FULFILLED,
            };
        });
        builder.addCase(hotelaDetailsFetched.pending, (state) => {
            state.hotelaDetails = {
                ...state.hotelaDetails,
                status: StatusType.PENDING,
            };
        });
        builder.addCase(hotelaDetailsFetched.rejected, (state) => {
            state.hotelaDetails = {
                ...state.hotelaDetails,
                status: StatusType.REJECTED,
            };
        });

        //* GlobalOffice details *//
        builder.addCase(globalOfficeDetailsFetched.fulfilled, (state, action) => {
            state.globalOfficeDetails = {
                ...state.globalOfficeDetails,
                data: action.payload.data,
                updatedAt: action.payload.updatedAt,
                status: StatusType.FULFILLED,
            };
        });
        builder.addCase(globalOfficeDetailsFetched.pending, (state) => {
            state.globalOfficeDetails = {
                ...state.globalOfficeDetails,
                status: StatusType.PENDING,
            };
        });
        builder.addCase(globalOfficeDetailsFetched.rejected, (state) => {
            state.globalOfficeDetails = {
                ...state.globalOfficeDetails,
                status: StatusType.REJECTED,
            };
        });


        //* Synchronise *//
        //? GlobalOffice - Users ?//
        builder.addCase(globalOfficeUsersSynced.fulfilled, (state, action) => {
            state.globalOfficeDetails = {
                ...state.globalOfficeDetails,
                data: action.payload.data,
                updatedAt: action.payload.updatedAt,
                status: StatusType.FULFILLED,
            };
        });
        builder.addCase(globalOfficeUsersSynced.pending, (state) => {
            state.globalOfficeDetails = {
                ...state.globalOfficeDetails,
                status: StatusType.PENDING,
            };
        });
        builder.addCase(globalOfficeUsersSynced.rejected, (state) => {
            state.globalOfficeDetails = {
                ...state.globalOfficeDetails,
                status: StatusType.REJECTED,
            };
        });

        //? GlobalOffice - Customers ?//
        builder.addCase(globalOfficeCustomersSynced.fulfilled, (state, action) => {
            state.globalOfficeDetails = {
                ...state.globalOfficeDetails,
                data: action.payload.data,
                updatedAt: action.payload.updatedAt,
                status: StatusType.FULFILLED,
            };
        });
        builder.addCase(globalOfficeCustomersSynced.pending, (state) => {
            state.globalOfficeDetails = {
                ...state.globalOfficeDetails,
                status: StatusType.PENDING,
            };
        });
        builder.addCase(globalOfficeCustomersSynced.rejected, (state) => {
            state.globalOfficeDetails = {
                ...state.globalOfficeDetails,
                status: StatusType.REJECTED,
            };
        });

        //? GlobalOffice - Data ?//
        builder.addCase(globalOfficeDataSynced.fulfilled, (state, action) => {
            state.globalOfficeDetails = {
                ...state.globalOfficeDetails,
                data: action.payload.data,
                updatedAt: action.payload.updatedAt,
                status: StatusType.FULFILLED,
            };
        });
        builder.addCase(globalOfficeDataSynced.pending, (state) => {
            state.globalOfficeDetails = {
                ...state.globalOfficeDetails,
                status: StatusType.PENDING,
            };
        });
        builder.addCase(globalOfficeDataSynced.rejected, (state) => {
            state.globalOfficeDetails = {
                ...state.globalOfficeDetails,
                status: StatusType.REJECTED,
            };
        });

        //? GlobalOffice - Mandates ?//
        builder.addCase(globalOfficeMandatesSynced.fulfilled, (state, action) => {
            state.globalOfficeDetails = {
                ...state.globalOfficeDetails,
                data: action.payload.data!,
                updatedAt: action.payload.updatedAt,
                status: StatusType.FULFILLED,
            };
        });
        builder.addCase(globalOfficeMandatesSynced.pending, (state) => {
            state.globalOfficeDetails = {
                ...state.globalOfficeDetails,
                status: StatusType.PENDING,
            };
        });
        builder.addCase(globalOfficeMandatesSynced.rejected, (state) => {
            state.globalOfficeDetails = {
                ...state.globalOfficeDetails,
                status: StatusType.REJECTED,
            };
        });

        //? Hotela - Users ?//
        builder.addCase(hotelaUsersSynced.fulfilled, (state, action) => {
            state.hotelaDetails = {
                ...state.hotelaDetails,
                data: action.payload.data,
                updatedAt: action.payload.updatedAt,
                status: StatusType.FULFILLED,
            };
        });
        builder.addCase(hotelaUsersSynced.pending, (state) => {
            state.hotelaDetails = {
                ...state.hotelaDetails,
                status: StatusType.PENDING,
            };
        });
        builder.addCase(hotelaUsersSynced.rejected, (state) => {
            state.hotelaDetails = {
                ...state.hotelaDetails,
                status: StatusType.REJECTED,
            };
        });
    },
});

export const { reset } = integrationsSlice.actions;

export default integrationsSlice.reducer;
