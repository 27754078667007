import cloneDeep from 'lodash/cloneDeep';
import moment from "moment";
import isEqual from 'react-fast-compare';
import { POI } from "../../utils/types/generalTypes";
import { LocationState, StoreAction } from "../../utils/types/storeTypes";

export const LOCATION_INIT_STATE: LocationState = {
    courses: undefined,
    sectors: undefined,
    pois: {},
    basePois: {},
    loadingPois: false
    // shared methods
    // onClickEvent: undefined,
};

export const CHANGE_COURSES = ('CHANGE_COURSES');
export const CHANGE_SECTORS = ('CHANGE_SECTORS');
export const CHANGE_PLANNING_POIS = ('CHANGE_PLANNING_POIS');
export const CHANGE_ALL_POIS = ('CHANGE_ALL_POIS');
export const CHANGE_ONLY_POIS = ('CHANGE_ONLY_POIS');
export const CHANGE_ONLY_BASEPOIS = ('CHANGE_ONLY_BASEPOIS');
export const TOGGLE_LOADING_POIS = ('TOGGLE_LOADING_POIS');
export const RESET = ('RESET_LOCATION');

const location = (state: LocationState = LOCATION_INIT_STATE, action: StoreAction) => {
    switch (action.type) {
        case CHANGE_COURSES:
            if (isEqual(state.courses, action.data)) {
                return state;
            } else {
                return {
                    ...state,
                    courses: cloneDeep(action.data),
                };
            }
        case CHANGE_SECTORS:
            if (isEqual(state.sectors, action.data)) {
                return state;
            } else {
                return {
                    ...state,
                    sectors: cloneDeep(action.data),
                };
            }
        case CHANGE_ALL_POIS: {
            const pois = action.data.filter((p: POI) => p.isBasePoi === false);
            const basePois = action.data.filter((p: POI) => p.isBasePoi === true);
            if (!action.forceReload && isEqual(state.pois, pois) && isEqual(state.basePois, basePois)) {
                return state;
            } else {
                return {
                    ...state,
                    pois: {
                        update: moment(),
                        data: cloneDeep(pois)
                    },
                    basePois: {
                        update: moment(),
                        data: cloneDeep(basePois)
                    },
                    planningPois: {
                        update: moment(),
                        data: cloneDeep(pois)
                    },
                    planningBasePois: {
                        update: moment(),
                        data: cloneDeep(basePois)
                    },
                    loadingPois: false
                };
            }
        }
        case CHANGE_PLANNING_POIS: {
            const pois = action.data.filter((p: POI) => p.isBasePoi === false);
            const basePois = action.data.filter((p: POI) => p.isBasePoi === true);
            if (!action.forceReload && isEqual(state.pois, pois) && isEqual(state.basePois, basePois)) {
                return state;
            } else {
                return {
                    ...state,
                    planningPois: {
                        update: moment(),
                        data: cloneDeep(pois)
                    },
                    planningBasePois: {
                        update: moment(),
                        data: cloneDeep(basePois)
                    },
                    loadingPois: false
                };
            }
        }
        case CHANGE_ONLY_POIS:
            if (!action.forceReload && isEqual(state.pois, action.data)) {
                return state;
            } else {
                return {
                    ...state,
                    pois: {
                        update: moment(),
                        data: cloneDeep(action.data)
                    },
                    loadingPois: false
                };
            }
        case CHANGE_ONLY_BASEPOIS:
            if (isEqual(state.basePois, action.data)) {
                return state;
            } else {
                return {
                    ...state,
                    basePois: {
                        update: moment(),
                        data: cloneDeep(action.data)
                    },
                    loadingPois: false
                };
            }
        case TOGGLE_LOADING_POIS:
            return {
                ...state,
                loadingPois: action.data
            }
        case RESET:
            return LOCATION_INIT_STATE;
        default:
            return state;
    }
};

export default location;