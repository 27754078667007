import { CloudSyncOutlined, DashOutlined, FullscreenExitOutlined, FullscreenOutlined, MoreOutlined } from "@ant-design/icons";
import { DatePicker, Divider, Dropdown, Empty, Segmented, Select, Space } from "antd";
import { ItemType } from "antd/lib/menu/hooks/useItems";
import { DefaultOptionType } from "antd/lib/select";
import moment from "moment";
import { ForwardRefExoticComponent, ReactNode, useCallback, useMemo, useRef, useState } from "react";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useAppSelector } from "../../../store/store";
import getFormat from "../../../utils/Lang";
import { IIntegrationsType } from "../../../utils/types/generalTypes";
import { ApplicationState } from "../../../utils/types/storeTypes";
import FAIcon from "../../common/FAIcon";
import CircleButton from "../../common/fields/circleButton";
import SpeedDial from "../../common/fields/speedDial";
import Anticon from "../../common/general/anticon";
import Card from "../../common/general/card";
import { UsersManagementRef } from "../tabs/export";

interface Props {
    element: ForwardRefExoticComponent<any>;
    title: string;
    icon: ReactNode;
    tab: string;
}

const IntegrationSelector = (props: Props) => {
    const Element = props.element;
    const icon = props.icon;
    const title = props.title;

    const integrations = useAppSelector(state => state.integrations.enabledIntegrations.data);

    const defaultIntegration = useMemo((): IIntegrationsType => {
        if (!integrations)
            return 'unknown';

        if (integrations.hotelaEnabled)
            return 'hotela';
        else if (integrations.officeMakerEnabled)
            return 'officemaker';
        else if (integrations.globalOfficeEnabled)
            return 'globaloffice';
        else
            return 'unknown';


    }, [integrations]);


    const [selectedIntegration, setSelectedIntegration] = useState<IIntegrationsType>(defaultIntegration);
    const [selectedDate, setSelectedDate] = useState(moment().startOf('month').subtract(1, 'day').startOf('month'));
    const [selectedEndDate, setSelectedEndDate] = useState(moment().startOf('month').subtract(1, 'day'));
    const [display, setDisplay] = useState<'weekly' | 'monthly'>('monthly');
    // const [showSettings, setShowSettings] = useState(false);
    const [loading, setLoading] = useState(false);

    const fullscreen = useSelector((state: ApplicationState) => state.window.fullscreen);
    const width = useSelector((state: ApplicationState) => state.window.width);

    const intl = useIntl();

    const ref = useRef<UsersManagementRef>(null);

    const refresh = useCallback(() => {
        console.log("KROK_refresh");
        ref.current?.refresh();
    }, [ref]);

    const toggleFullScreen = useCallback(() => {
        const doc = document;

        if (!doc.fullscreenElement) {
            document.body.requestFullscreen();
        }
        else {
            document.exitFullscreen();
        }
    }, []);

    const integratorItems: DefaultOptionType[] = [
        {
            label: 'Hotela',
            value: 'hotela',
            disabled: !integrations?.hotelaEnabled
        },
        {
            label: 'Global Office',
            value: 'globaloffice',
            disabled: !integrations?.globalOfficeEnabled
        },
        {
            label: 'Office Maker',
            value: 'officemaker',
            disabled: !integrations?.officeMakerEnabled
        },
    ];

    const canGoNext = useMemo(() => selectedDate.clone().endOf('month').add(1, 'day').isBefore(moment(), "month"), [selectedDate]);

    const onNext = useCallback(() => {
        const date = selectedDate.clone();
        if (canGoNext) {
            setSelectedDate(date.clone().endOf('month').add(1, 'day'));
            setSelectedEndDate(date.clone().endOf('month').add(1, 'day').endOf('month'));
        }
    }, [canGoNext, selectedDate]);

    const onPrevious = useCallback(() => {
        const date = selectedDate.clone();
        setSelectedDate(date.clone().startOf('month').subtract(1, 'day').startOf('month'));
        setSelectedEndDate(date.clone().startOf('month').subtract(1, 'day'));
    }, [selectedDate]);

    const integrationMobileMenu = useMemo((): ItemType[] => {
        return [
            {
                label: 'Hotela',
                key: 'hotela',
                disabled: !integrations?.hotelaEnabled,
                onClick: (e) => setSelectedIntegration(e.key as IIntegrationsType)
            },
            {
                label: 'Global Office',
                key: 'globaloffice',
                disabled: !integrations?.globalOfficeEnabled,
                onClick: (e) => setSelectedIntegration(e.key as IIntegrationsType)
            },
            {
                label: 'Office Maker',
                key: 'officemaker',
                disabled: !integrations?.officeMakerEnabled,
                onClick: (e) => setSelectedIntegration(e.key as IIntegrationsType)
            },

        ];
    }, [integrations?.globalOfficeEnabled, integrations?.hotelaEnabled, integrations?.officeMakerEnabled]);

    const IntegrationButton = () => {
        return (
            <>
                {
                    width > 1050 ?
                        <Select
                            style={{ width: 220 }}
                            value={selectedIntegration}
                            onChange={(value) => setSelectedIntegration(value)}
                            options={integratorItems}
                        />
                        :
                        <Dropdown trigger={['click']} menu={{ items: integrationMobileMenu }}>
                            <CircleButton
                                small
                                icon={<FAIcon name='grid-2-plus' prefix='far' />}
                                title={intl.formatMessage({ defaultMessage: 'Integration selection' })}
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                }}
                            />
                        </Dropdown>
                }
            </>
        );
    };

    const RefreshButton = () => {
        return (
            <CircleButton
                small
                title={intl.formatMessage({ defaultMessage: 'Force reload' })}
                icon={<CloudSyncOutlined />}
                onClick={() => refresh()}
            />
        );
    };

    const mobileButtons = () => {
        const buttons: ReactNode[] = [];

        if (width <= 470)
            buttons.push(<RefreshButton />);
        buttons.push(<IntegrationButton />);
        return buttons;
    };

    return (
        <>
            <Card
                icon={icon}
                style={{ height: '100%' }}
                title={
                    <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center', gap: 8 }}>
                        {
                            props.tab === 'exports' ?
                                <>
                                    {
                                        selectedIntegration === 'globaloffice' && display === 'weekly' ?
                                            <div key={`custom-date-picker-div-${selectedDate?.format('YYYYMMDD')}`} className='work_control-datepicker-input-container'>
                                                <DatePicker.RangePicker
                                                    className={`__weekly-planning-month-picker work_control-datepicker-input-container-picker`}
                                                    key={`year-picker-${selectedDate.format("YY-MM")}-${selectedDate?.month()}`}
                                                    suffixIcon={null}
                                                    placement={'bottomLeft'}
                                                    size='large'
                                                    bordered={false}
                                                    format={(value) => `${value.clone().format(getFormat('DATE_MINI'))}`}
                                                    value={[selectedDate, selectedEndDate]}
                                                    onChange={(e) => {
                                                        if (e && e[0] && e[1]) {
                                                            setSelectedDate(e[0]);
                                                            setSelectedEndDate(e[1]);
                                                        }
                                                    }}
                                                    allowClear={false}
                                                    inputReadOnly
                                                    style={{
                                                        textAlign: 'center'
                                                    }}
                                                    disabled={loading}
                                                    disabledDate={(current) => current.isSameOrAfter(moment(), "month")}
                                                />
                                            </div>
                                            :
                                            <>
                                                <CircleButton small icon={<Anticon><FiChevronLeft /></Anticon>} onClick={onPrevious} disabled={loading} />
                                                <div key={`custom-date-picker-div-${selectedDate?.format('YYYYMMDD')}`} className='work_control-datepicker-input-container'>
                                                    <DatePicker
                                                        className={`__weekly-planning-month-picker work_control-datepicker-input-container-picker`}
                                                        key={`year-picker-${selectedDate.format("YY-MM")}-${selectedDate?.month()}`}
                                                        suffixIcon={null}
                                                        placement={'bottomLeft'}
                                                        size='large'
                                                        picker={'month'}
                                                        bordered={false}
                                                        format={(value) => `${value.clone().startOf('month').format(getFormat('MONTH_AND_YEAR'))}`}
                                                        value={selectedDate}
                                                        onChange={(e) => {
                                                            if (e) {
                                                                setSelectedDate(e.clone().startOf('month'));
                                                                setSelectedEndDate(e.clone().endOf('month'));
                                                            }
                                                        }}
                                                        allowClear={false}
                                                        inputReadOnly
                                                        style={{
                                                            textAlign: 'center'
                                                        }}
                                                        disabled={loading}
                                                        disabledDate={(current) => current.isSameOrAfter(moment(), "month")}
                                                    />
                                                </div>
                                                <CircleButton small icon={<Anticon><FiChevronRight /></Anticon>} onClick={onNext} disabled={loading || !canGoNext} />
                                            </>
                                    }
                                </>
                                :
                                <span>{title}</span>
                        }
                        {
                            selectedIntegration === 'globaloffice' && props.tab !== 'link' && width > 1400 &&
                            <>
                                <Divider dashed={true} style={{ borderLeft: '1px dashed rgba(0, 0, 0, 0.3)' }} type={'vertical'} />
                                <Segmented
                                    block
                                    disabled={loading}
                                    style={{ width: "300px" }}
                                    key={'accounting_globaloffice-show-options'}
                                    value={display}
                                    options={[
                                        { value: 'weekly', label: intl.formatMessage({ defaultMessage: 'Weekly' }) },
                                        { value: 'monthly', label: intl.formatMessage({ defaultMessage: 'Monthly' }) }
                                    ]}
                                    onChange={(value) => {
                                        const date = selectedDate.clone();
                                        if (value === 'monthly') {
                                            setDisplay('monthly');
                                            setSelectedDate(date.clone().startOf('month'));
                                            setSelectedEndDate(date.clone().endOf('month'));
                                        }
                                        else {
                                            setDisplay('weekly');
                                            setSelectedEndDate(date.clone().add(7, 'days'));
                                        }
                                    }}
                                />
                            </>
                        }
                        {
                            width > 470 &&
                            <>
                                <Divider dashed={true} style={{ borderLeft: '1px dashed rgba(0, 0, 0, 0.3)' }} type={'vertical'} />
                                <RefreshButton />
                            </>
                        }
                        {
                            width > 1190 &&
                            <CircleButton
                                small
                                type={fullscreen ? 'primary' : 'default'}
                                title={fullscreen ? intl.formatMessage({ defaultMessage: 'Exit fullscreen' }) : intl.formatMessage({ defaultMessage: 'Enter fullscreen' })}
                                icon={fullscreen ? <FullscreenExitOutlined /> : <FullscreenOutlined />}
                                onClick={toggleFullScreen}
                            />
                        }

                    </div>
                }
                headerElements={[
                    <Space key='integration-selector-header'>
                        {
                            width > 1050 ?
                                <IntegrationButton />
                                :
                                <SpeedDial
                                    small={true}
                                    key='course-actions-speeddial'
                                    title={intl.formatMessage({ defaultMessage: 'Actions' })}
                                    icon={<MoreOutlined />}
                                    openIcon={<DashOutlined />}
                                    style={{ right: '35px' }}
                                    buttons={mobileButtons()}
                                />
                        }
                        {/* <CircleButton
                            small
                            title={intl.formatMessage({ defaultMessage: 'Integrations settings' })}
                            icon={<SettingOutlined />}
                            onClick={() => setShowSettings(true)}
                            />
                            <Divider type='vertical' />
                            <CircleButton
                            small
                            title={intl.formatMessage({ defaultMessage: 'Add new integration' })}
                            icon={<PlusOutlined />}
                            onClick={() => undefined}
                            disabled
                            /> */}
                    </Space>
                ]}
                containerStyle={{ height: 'calc(100% - 80px)', maxHeight: 'calc(100% - 80px)' }}
            >
                {
                    selectedIntegration !== undefined ?
                        <Element
                            ref={ref}
                            selectedIntegration={selectedIntegration}
                            selectedDate={selectedDate}
                            selectedEndDate={selectedEndDate}
                            setSelectedDate={setSelectedDate}
                            setSelectedEndDate={setSelectedEndDate}
                            onNext={onNext}
                            onPrevious={onPrevious}
                            loading={loading}
                            setLoading={setLoading}
                        />
                        :
                        <Empty description={<FormattedMessage defaultMessage={'No integration selected'} />} />
                }
            </Card>
            {/* {
                showSettings &&
                <IntegrationSettings
                    onClose={() => setShowSettings(false)}
                />
            } */}
        </>
    );
};
export default IntegrationSelector;