import { CheckCircleOutlined, CopyOutlined, DeleteOutlined, LoadingOutlined, PlusOutlined, ScissorOutlined, SwapOutlined } from "@ant-design/icons";
import { Dropdown, MenuProps, Popconfirm, Spin, Tooltip } from "antd";
import { Moment } from "moment";
import { ReactNode, useCallback, useMemo, useRef, useState } from "react";
import { FaCheckCircle } from "react-icons/fa";
import { MdPhotoCamera } from "react-icons/md";
import { RiCupFill, RiTimeFill } from "react-icons/ri";
import { TfiPencil } from "react-icons/tfi";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelectable } from "react-selectable-box";
import getFormat from "../../../../utils/Lang";
import { getSelectableValueForEvent } from "../../../../utils/planningUtils";
import { NetworkMonthlyPlanningRowPerfAddons } from "../../../../utils/types/networkTypes";
import { colorIsBright } from "../../../../utils/utils";
import Anticon from "../../../common/general/anticon";

export type onEventActionCallback<A extends boolean = false> = (userId: number, event: A extends false ? NetworkMonthlyPlanningRowPerfAddons : NetworkMonthlyPlanningRowPerfAddons[], dayStr: string) => void;

interface Props {
    event: NetworkMonthlyPlanningRowPerfAddons;
    day: Moment;
    dayStr: string;
    selected: boolean;
    hasSelectedEventsOnCurrentDay: boolean;
    hasSelectedEventsonAnyDay: boolean;
    canEdit: boolean;
    isCopied: boolean;
    isCutted: boolean;
    cellAlreadyLocked: boolean;
    onCopySelectedEvents: (userId: number, dayStr: string) => void;
    onOpen: (eventId: number) => void;
    onDelete: (eventId: number, userId: number, dayStr: string) => any;
    onCut: onEventActionCallback<true>;
    onConfirm: (userId: number, eventId: number) => void;
    onEdit: (eventId: number) => void | Promise<void>;
    onCopy: onEventActionCallback<true>;
    onSelect: (userId: number, eventId: NetworkMonthlyPlanningRowPerfAddons[], dayStr: string, shiftPressed?: boolean) => void;
    onCreate: (userId: number, dayStr: string) => void;
}

const Event = /*memo(*/(props: Props) => {
    const intl = useIntl();
    const { event, dayStr } = props;
    const userId = event.userId;
    const textColor = useMemo(() => event.colorHex && colorIsBright(event.colorHex) ? 'dark' : 'white', [event.colorHex]);
    const [hover, setIsHover] = useState(false);
    const [contextualMenuOpened, setContextualMenuOpened] = useState(false);

    const [confirmDeleteOpened, setConfirmDeleteOpened] = useState(false);

    const eventSelectValue = useMemo(() => getSelectableValueForEvent(props.dayStr, userId, event.id), [props.dayStr, userId, event.id]);

    const menuItems: MenuProps['items'] = useMemo(() => {
        if (!props.canEdit) return [];
        return !props.hasSelectedEventsOnCurrentDay
            ?
            [
                {
                    label: intl.formatMessage({ defaultMessage: 'Copy' }),
                    key: `monthly-planning-menu-copy-u${userId}-e${event.id}`,
                    icon: <CopyOutlined />,
                    onClick: () => {
                        props.onCopy(userId, [event], props.dayStr);
                    }
                },
                ...(props.cellAlreadyLocked || props.event.eventLocked ? [] : [
                    {
                        label: intl.formatMessage({ defaultMessage: 'Cut' }),
                        key: `monthly-planning-menu-cut-u${userId}-e${event.id}`,
                        icon: <ScissorOutlined />,
                        onClick: () => {
                            props.onCut(userId, [event], dayStr);
                        },
                        style: {},
                    },
                    {
                        type: 'divider',
                    },
                    {
                        label: intl.formatMessage({ defaultMessage: 'Confirm event' }),
                        key: `monthly-planning-menu-confirm-u${userId}-e${event.id}`,
                        icon: <CheckCircleOutlined />,
                        onClick: () => {
                            props.onConfirm(userId, event.id);
                        },
                        style: { color: 'var(--success-color)' },
                    },
                    {
                        label: intl.formatMessage({ defaultMessage: 'Modify event' }),
                        key: `monthly-planning-menu-modify-u${userId}-e${event.id}`,
                        icon: <TfiPencil />,
                        onClick: () => {
                            props.onEdit(event.id);
                        },
                        style: { color: 'var(--primary-color)' },
                    },
                    {
                        label: intl.formatMessage({ defaultMessage: 'Delete event' }),
                        key: `monthly-planning-menu-delete-u${userId}-e${event.id}`,
                        icon: <DeleteOutlined />,
                        onClick: () => {
                        // props.onDelete(userId, eventId, dayStr);
                            setConfirmDeleteOpened(true);
                        },
                        style: { color: 'var(--error-color)' },
                    }
                ] as any[]),
                ...(props.cellAlreadyLocked ? [] :  [
                    {
                        type: 'divider',
                    },
                    {
                        label: intl.formatMessage({ defaultMessage: 'Add an event' }),
                        key: `monthly-planning-menu-add-u${userId}-empty`,
                        icon: <PlusOutlined />,
                        onClick: () => {
                            props.onCreate(userId, dayStr);
                        },
                        style: { color: 'var(--positive-color)' },
                    }
                ] as any[]),
            ]
            :
            [
                {
                    label: intl.formatMessage({ defaultMessage: 'Duplicate selection of {time}' }, { time: props.day.format(getFormat('DAY_SHORT_AND_MONTH_HALF')) }),
                    key: `monthly-planning-menu-multiple-copy-u${userId}-e${event.id}`,
                    icon: <CopyOutlined />,
                    onClick: () => {
                        props.onCopySelectedEvents(userId, dayStr);
                    },
                    style: {},
                },

            ];
    }, [event, props, dayStr, userId, intl]);

    const iconList = useMemo((): ReactNode[] => {
        const list = [];
        if (event.countNotConfirmed === 0)
            list.push(<FaCheckCircle key={'confirmed'} className='__mp-e-icon' title={intl.formatMessage({ defaultMessage: 'Event confirmed' })} />);
        if (event.isClocked)
            list.push(<RiTimeFill key={'timeclock'} className='__mp-e-icon __mp-e-timeclock-icon' title={intl.formatMessage({ defaultMessage: 'Event clocked' })} />);
        if (event.countBreaktimes)
            list.push(<RiCupFill key={'breaktime'} className='__mp-e-icon anticon' title={intl.formatMessage({ defaultMessage: 'Event with breaks' })} />);
        if (event.countOvertimes)
            list.push(<RiTimeFill key={'overtime'} className='__mp-e-icon anticon' title={intl.formatMessage({ defaultMessage: 'Event with overtime' })} />);
        if (!event.isSingleDay)
            list.push(<SwapOutlined key={'multipleDays'} className='__overtime-count' title={intl.formatMessage({ defaultMessage: '2-day event' })} />);
        if (event.hasImages)
            list.push(<MdPhotoCamera key={'images'} className='__mp-e-icon' title={intl.formatMessage({ defaultMessage: 'Event with images' })} />);

        return list;
    }, [event, intl]);

    const timeoutRef = useRef<NodeJS.Timeout | null>(null);

    const setHover = useCallback(() => {
        // Clear the previous timeout if it exists
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
            timeoutRef.current = null; // Reset the ref
        }
        setIsHover(true);
    }, []);

    const removeHover = useCallback(() => {
        // Set a new timeout and store the ID in the ref
        timeoutRef.current = setTimeout(() => {
            setIsHover(false);
            timeoutRef.current = null; // Reset the ref after execution
        }, 300);
    }, []);

    const handleOpenContextualMenu = useCallback((open: boolean) => {
        if (open)
            setContextualMenuOpened(true);
        else
            setTimeout(() => setContextualMenuOpened(false), 300);
    }, []);

    const lockedIcon = useMemo(() => {
        return props.event.eventLocked && !props.cellAlreadyLocked ? <Tooltip title={<FormattedMessage defaultMessage={'Event locked'} />} mouseEnterDelay={0.5}><div className='event-lock' /></Tooltip> : undefined
    }, [props.cellAlreadyLocked, props.event.eventLocked])

    const cardContent = (<>
        <div className={`__e-icons ${iconList.length > 3 ? 'wrap' : ''}`}>
            {lockedIcon}
            <div className="__flex-column">
                <span className="__e-title">{event.title}</span>
                <span className="__e-times">{`${event.startTime} - ${event.endTime}`}</span>
            </div>
            <div className='__mp-e-icons' style={(iconList.length > 3) ? { flexDirection: 'row', gap: "4px" } : {}}>
                {iconList}
            </div>
        </div>
    </>);


    if (event.loading) {
        return (
            <SelectableItem value={eventSelectValue} disabled={true} key={event.id}>
                <Spin size='large' indicator={<LoadingOutlined spin />} spinning>
                    <div
                        className={`__mp-event`}
                        style={{ backgroundColor: event.colorHex ?? 'var(--primary-color)', color: textColor === 'dark' ? "var(--dark-color)" : "white", cursor: props.canEdit ? props.isCutted ? 'no-drop' : props.isCopied ? "cell" : "pointer" : "not-allowed", opacity: props.isCutted ? '0.7' : '1' }}
                    >
                        {cardContent}
                    </div>
                </Spin>
            </SelectableItem>
        );
    }
    return (
        <SelectableItem value={eventSelectValue} disabled={!props.canEdit} key={event.id}>
            <div onMouseEnter={setHover} onMouseLeave={removeHover}>
                <Dropdown
                    trigger={['contextMenu']}
                    onOpenChange={handleOpenContextualMenu}
                    menu={{ items: hover || contextualMenuOpened ? menuItems : [] }}
                    disabled={!props.canEdit}
                >
                    <div
                        onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            if (props.canEdit && !props.isCutted) {
                                if (e.ctrlKey || e.metaKey) {
                                    props.onSelect(userId, [event], dayStr);
                                } else if (e.shiftKey) {
                                    userId && props.onSelect(userId, [event], dayStr, true);
                                } else
                                    props.onOpen(event.id);
                            }
                            document.getSelection()?.removeAllRanges();
                        }}
                        title={props.canEdit ? '' : "Vous n'avez pas les droits pour cet utilisateur"}
                        className={`__mp-event ${props.canEdit && !props.isCutted ? 'hvr-float' : ''} ${textColor} ${props.selected ? "__mp-e-sel-on" : ""}`}
                        style={{ backgroundColor: event.colorHex ?? 'var(--primary-color)', color: textColor === 'dark' ? "var(--dark-color)" : "white", cursor: props.canEdit ? props.isCutted ? 'no-drop' : props.isCopied ? "cell" : "pointer" : "not-allowed", opacity: props.isCutted ? '0.7' : '1' }}
                    >
                        <div className='__mp-event-overlay'>
                            <span>+</span>
                            <span>-</span>
                        </div>
                        {cardContent}
                        <div className={`__mp-e-actions ${props.canEdit ? '__canedit' : ''}`}>
                            {hover && (!props.event.eventLocked && !props.cellAlreadyLocked)
                                ?
                                <>
                                    <div className='__mp-e-action'
                                        onClick={(e) => {
                                            e?.stopPropagation();
                                            props.onEdit(event.id);
                                        }}>
                                        <Anticon icon={<TfiPencil title={intl.formatMessage({ defaultMessage: 'Edit' })} />} />
                                    </div>
                                    <div
                                        className='__mp-e-action'
                                        onClick={(e) => {
                                            e?.stopPropagation();
                                        }}>
                                        <Popconfirm
                                            title={<FormattedMessage defaultMessage={'Do you want to delete this event?'} />}
                                            onConfirm={(e) => {
                                                e?.stopPropagation();
                                                props.onDelete(event.id, userId, props.dayStr);
                                            }}
                                            open={confirmDeleteOpened}
                                            onCancel={(e) => { e?.stopPropagation(); setConfirmDeleteOpened(false); }}
                                            okText={<FormattedMessage defaultMessage={'Delete'} />}
                                            placement={"top"}
                                        >
                                            <Anticon onClick={(e: any) => { e?.stopPropagation(); setConfirmDeleteOpened(true); }} icon={<DeleteOutlined title={intl.formatMessage({ defaultMessage: 'Delete' })} />} />
                                        </Popconfirm>
                                    </div>
                                    <div className='__mp-e-action'
                                        onClick={(e) => {
                                            e?.stopPropagation();
                                            props.onCopy(userId, [event], props.dayStr);
                                        }}>
                                        <Anticon icon={<CopyOutlined title={intl.formatMessage({ defaultMessage: 'Copy' })} />} />
                                    </div>
                                </>
                                :
                                <></>
                            }
                        </div>
                    </div>
                </Dropdown>
            </div>
        </SelectableItem>
    );
}/*)*/;

Event.displayName = "event";

const SelectableItem = ({ children, value, disabled }: { children: ReactNode, value: string, disabled: boolean; }) => {
    const { setNodeRef, isAdding, isSelected, isRemoving } = useSelectable({
        value,
        disabled
    });

    return (
        <div ref={setNodeRef} className={`__mp-one-cell-content ${isAdding ? 'isAdding' : ''} ${isSelected ? 'isSelected' : ''} ${isRemoving ? 'isRemoving' : ''}`} >
            {children}
        </div>
    );
};


export default Event;