import { CloudSyncOutlined } from "@ant-design/icons";
import { Button, Card, Col, Divider, Empty, Popconfirm, Popover, Row, Space, Spin } from "antd";
import { Moment } from "moment";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { customersFetched, mandatesFetched } from "../../../../store/features/customerManagement";
import { MOMENT_FORMAT_DATE_TO_NETWORK } from "../../../../utils/constants";
import Network from "../../../../utils/network";
import { ICustomer, IMandate } from "../../../../utils/types/customerTypes";
import { IUserExportHoursGlobalOfficeUserData, IUserExportHoursGlobalOfficeUserDataCustomer } from "../../../../utils/types/networkTypes";
import { ApplicationState } from "../../../../utils/types/storeTypes";
import { fixedDecimal, showNotification } from "../../../../utils/utils";
import FAIcon from "../../../common/FAIcon";
import CircleButton from "../../../common/fields/circleButton";

interface Props {
    selectedDate: Moment;
    selectedEndDate: Moment;
    selectedUserId: number;
    loading: boolean;
    userIds: number[];
    waitAggregated: boolean;
    waitTimeclock: boolean;
    setLoading: (loading: boolean) => void;
    loadUsers: () => void;
    reset: () => void;
}

//TODO: Show warning if isAuxiliary is true
const GlobalOfficeExport = (props: Props) => {
    const [userData, setUserData] = useState<IUserExportHoursGlobalOfficeUserData | undefined>(undefined);
    const [generateLoading, setGenerateLoading] = useState(false);
    const [isAuxiliary, setIsAuxiliary] = useState(false);

    const intl = useIntl();
    const dispatch = useDispatch();
    const customers = useSelector((state: ApplicationState) => state.customerManagement.customers);
    const mandates = useSelector((state: ApplicationState) => state.customerManagement.mandates);
    const width = useSelector((state: ApplicationState) => state.window.width);

    const selectedUseIdRef = useRef(props.selectedUserId);

    const clonedSelectedDate = useMemo(() => props.selectedDate.clone(), [props.selectedDate]);
    const clonedSelectedEndDate = useMemo(() => props.selectedEndDate.clone(), [props.selectedEndDate]);

    const generatePreview = useCallback(() => {
        selectedUseIdRef.current = props.selectedUserId;
        if (props.selectedUserId === -1) {
            showNotification(intl.formatMessage({ defaultMessage: 'Please select an user' }), 'warn');
            return;
        }

        setGenerateLoading(true);
        Network.getGlobalOfficeUserData(clonedSelectedDate.format(MOMENT_FORMAT_DATE_TO_NETWORK), clonedSelectedEndDate.format(MOMENT_FORMAT_DATE_TO_NETWORK), props.selectedUserId).then(
            (response) => {
                setUserData(response.data.userData || []);
                setIsAuxiliary(response.data.isAuxiliary);
            },
            () => {
                showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while loading the preview' }), 'error');
            }
        ).finally(() => setGenerateLoading(false));
    }, [props.selectedUserId, clonedSelectedDate, clonedSelectedEndDate, intl]);

    const validateData = useCallback(() => {
        setGenerateLoading(true);
        props.setLoading(true);
        Network.validateGlobalOfficeUserData(clonedSelectedDate.format(MOMENT_FORMAT_DATE_TO_NETWORK), clonedSelectedEndDate.format(MOMENT_FORMAT_DATE_TO_NETWORK), props.selectedUserId).then(
            () => {
                showNotification(intl.formatMessage({ defaultMessage: 'Data ready to be taken by Global Office' }), 'success');
                setUserData(undefined);
                props.reset();
            },
            (error) => {
                const json = JSON.parse(error.message);

                switch (json.code) {
                    case 'integrations-still-waiting-aggregated-confirmation-603':
                        showNotification(intl.formatMessage({ defaultMessage: 'Cannot validate the hours' }), 'error', intl.formatMessage({ defaultMessage: 'All events are not validated in the reports yet' }));
                        break;
                    default:
                        showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while validating the hours' }), 'error');
                }
            }
        ).finally(() => { setGenerateLoading(false); props.setLoading(false); });
    }, [props, clonedSelectedDate, clonedSelectedEndDate, intl]);

    useEffect(() => {
        setUserData(undefined);
        if (props.selectedUserId !== -1 && props.selectedUserId !== selectedUseIdRef.current)
            generatePreview();
    }, [generatePreview, props.selectedUserId]);

    useEffect(() => {
        if (props.selectedUserId === -1)
            selectedUseIdRef.current = -1;
        setUserData(undefined);
    }, [props.selectedUserId, props.userIds]);

    useEffect(() => {
        if (customers.data === undefined && !customers.loading) {
            dispatch(customersFetched({ forceReload: false }));
        }
        if (mandates.data === undefined && !mandates.loading) {
            dispatch(mandatesFetched({ forceReload: false }));
        }
    }, [customers.data, customers.loading, dispatch, mandates.data, mandates.loading]);

    const filterByCustomer = useMemo(() => {
        if (userData !== undefined) {
            return Object.keys(userData).map((customerId) => {
                let customer: ICustomer | undefined = undefined;
                let tmpMandates: { mandate: IMandate | undefined, totalHours?: number, timeOff?: number; }[] = [];
                if (customerId === 'orphelin') {
                    tmpMandates = [{
                        mandate: undefined,
                        totalHours: userData['orphelin'].totalHours,
                        timeOff: userData['orphelin'].totalTimeOff,
                    }];
                } else {
                    customer = customers.data?.find(c => c.id === parseInt(customerId));
                    const customerData = userData[customerId] as IUserExportHoursGlobalOfficeUserDataCustomer;  // Cast explicite
                    tmpMandates = Object.keys(customerData).map((mandateId) => {
                        const mandate = mandates.data?.find(m => m.id === parseInt(mandateId));

                        return {
                            mandate: mandate,
                            totalHours: customerData[mandateId].totalHours,
                            timeOff: customerData[mandateId].totalTimeOff
                        };
                    });
                }

                //const tmpMandates:any=[]
                return {
                    customer: customer,
                    mandates: tmpMandates
                };
            });
        }
        else {
            return [];
        }
    }, [customers, mandates.data, userData]);

    return (
        <Card
            size='small'
            title={
                <>
                    <FormattedMessage defaultMessage={'Data preview'} />
                    <Divider type='vertical' />
                    <CircleButton
                        small
                        icon={<CloudSyncOutlined />}
                        onClick={generatePreview}
                        loading={generateLoading}
                        disabled={userData === undefined}
                    />
                    {
                        isAuxiliary &&
                        <Popover
                            title={<FormattedMessage defaultMessage={'This user is an auxiliary employee and therefor exporting his data may break on Global Office.'} />}
                        >
                            <FAIcon name='exclamation-triangle' prefix='far' color='red' />
                        </Popover>
                    }
                </>
            }
            extra={[
                <Space key='export-hours-filter' >
                    {
                        props.waitAggregated || props.waitTimeclock ?
                            <Popover
                                title={<FormattedMessage defaultMessage={'Unable to validate'} />}
                                content={
                                    <>
                                        {
                                            props.waitAggregated &&
                                            <FormattedMessage defaultMessage={'Awaiting hours validations in reports'} />
                                        }
                                        {
                                            props.waitTimeclock &&
                                            <FormattedMessage defaultMessage={'Awaiting timeclock validation'} />
                                        }
                                    </>
                                }
                                placement='left'
                            >
                                <FAIcon name='exclamation-triangle' prefix='far' color='red' />
                            </Popover>
                            :
                            undefined
                    }
                    <Popconfirm
                        title={
                            <>
                                <p><strong><FormattedMessage defaultMessage={'Hours validation'} /></strong></p>
                                <br />
                                <p><FormattedMessage defaultMessage={"This action will validate and send the data to Global Office. Te user's planning will be blocked for the selected month."} /></p>
                                <br />
                                <p><FormattedMessage defaultMessage={'Do you want to continue?'} /></p>
                            </>
                        }
                        onConfirm={() => validateData()}
                        okText={<FormattedMessage defaultMessage={'Validate and send'} />}
                        disabled={userData === undefined || props.waitAggregated || props.waitTimeclock}
                        placement='left'
                    >
                        {
                            width > 620 ?
                                <Button
                                    disabled={userData === undefined || props.waitAggregated || props.waitTimeclock}
                                    type='primary'
                                >
                                    <FormattedMessage defaultMessage={'Validate and send to Global Office'} />
                                </Button>
                                :
                                <CircleButton
                                    disabled={userData === undefined || props.waitAggregated || props.waitTimeclock}
                                    type='primary'
                                    small
                                    icon={<FAIcon name='check' prefix='far' />}
                                />
                        }
                    </Popconfirm>
                </Space>
            ]}
        >
            <Spin spinning={generateLoading}>
                {
                    userData === undefined ?
                        <Empty />
                        :
                        <Row gutter={[10, 10]}>
                            {
                                filterByCustomer !== undefined && filterByCustomer.length > 0 &&
                                filterByCustomer.map((customer) => {
                                    return (
                                        <Col xs={{ span: 24 }} xxl={{ span: 12 }} key={`customer-${customer.customer?.id}`}>
                                            <Card size='small' title={customer.customer ? customer.customer.title : <FormattedMessage defaultMessage={'Without client'} />} className='integration-data-container'>
                                                {
                                                    customer.mandates.map((mandate) => {
                                                        return (
                                                            <p key={`mandate-${mandate.mandate?.id}`}>
                                                                <div><strong>{mandate.mandate ? mandate.mandate.title : <FormattedMessage defaultMessage={'Without mandate'} />}</strong></div>
                                                                <div className='integration-data-item'><FormattedMessage defaultMessage={'Total hours'} /><span>{mandate.totalHours ? fixedDecimal(mandate.totalHours.toString()) : 0.0}</span></div>
                                                                <div className='integration-data-item'><FormattedMessage defaultMessage={'Total hours off'} /><span>{mandate.timeOff ? fixedDecimal(mandate.timeOff.toString()) : 0.0}</span></div>
                                                            </p>
                                                        );
                                                    })
                                                }
                                            </Card>
                                        </Col>
                                    );
                                })
                            }
                        </Row>
                }
            </Spin>
        </Card>
    );
};

export default GlobalOfficeExport;