import moment from "moment";
import { FAST_RELOAD } from "../../utils/constants";
import Network from "../../utils/network";
import { Course, POI, Sector } from "../../utils/types/generalTypes";
import { ApplicationState, StoreAction, StoreDispatch } from "../../utils/types/storeTypes";
import { showNotification } from "../../utils/utils";
import { CHANGE_ALL_POIS, CHANGE_COURSES, CHANGE_ONLY_BASEPOIS, CHANGE_ONLY_POIS, CHANGE_PLANNING_POIS, CHANGE_SECTORS, RESET, TOGGLE_LOADING_POIS } from "../reducer/location";

/**
 * Change stored courses
 * @param courses the new array of courses to store
 * @return a store action
 */
export const changeCourses = (courses: Course[]): StoreAction => ({ type: CHANGE_COURSES, data: courses });

/**
 * Change stored sectors
 * @param sectors the new array of sectors to store
 * @return a store action
 */
export const changeSectors = (sectors: Sector[]): StoreAction => ({ type: CHANGE_SECTORS, data: sectors });

/**
 * Change stored pois
 * @param pois the new array of pois to store
 * @return a store action
 */
export const changePlanningPois = (pois: POI[]): StoreAction => ({ type: CHANGE_PLANNING_POIS, data: pois });
export const changeAllPois = (pois: POI[]): StoreAction => ({ type: CHANGE_ALL_POIS, data: pois });
export const changeOnlyPois = (pois: POI[]): StoreAction => ({ type: CHANGE_ONLY_POIS, data: pois });
export const changeOnlyBasePois = (pois: POI[]): StoreAction => ({ type: CHANGE_ONLY_BASEPOIS, data: pois });


export const toggleLoadingPois = (loading: boolean): StoreAction => ({ type: TOGGLE_LOADING_POIS, data: loading });

/**
 * Change stored types of Contract
 * @param typesOfContract the new types of Contract to store
 * @return a store action
 */
export const loadPois = (forceReload = false) => (dispatch: StoreDispatch, getState: () => ApplicationState) => {

    const applicationState: ApplicationState = getState();
    if (applicationState.location.pois?.updated === undefined || moment().diff(applicationState.location.pois.updated, "minutes") >= FAST_RELOAD ||
        applicationState.location.basePois?.updated === undefined || moment().diff(applicationState.location.pois.updated, "minutes") >= FAST_RELOAD ||
        forceReload) {
        dispatch(toggleLoadingPois(true));
        Network.getPoi().then(
            response => {
                dispatch(changeAllPois(response));
            },
            () => {
                showNotification("Un problème est survenu pendant le chargement des types de contrats", "warning")
                dispatch(toggleLoadingPois(false));
            },
        );
    }
}

export const loadPlanningPois = (forceReload = false) => (dispatch: StoreDispatch, getState: () => ApplicationState) => {

    const applicationState: ApplicationState = getState();
    if (applicationState.location.pois?.updated === undefined || moment().diff(applicationState.location.pois.updated, "minutes") >= FAST_RELOAD ||
        applicationState.location.basePois?.updated === undefined || moment().diff(applicationState.location.pois.updated, "minutes") >= FAST_RELOAD ||
        forceReload) {
        dispatch(toggleLoadingPois(true));
        Network.getPlanningPois().then(
            response => {
                dispatch(changeAllPois(response));
            },
            () => {
                showNotification("Un problème est survenu pendant le chargement des types de contrats", "warning")
                dispatch(toggleLoadingPois(false));
            },
        );
    }
}

export const reset = () => ({ type: RESET });