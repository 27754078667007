import { useCallback, useEffect, useMemo, useRef } from "react";
import { enabledIntegrationsFetched, globalOfficeCustomersSynced, globalOfficeDataSynced, globalOfficeDetailsFetched, globalOfficeMandatesSynced, globalOfficeUsersSynced, hotelaDetailsFetched, hotelaUsersSynced } from "../../store/features/integrationsSlice";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { StatusType } from "../../utils/types/storeTypes";

const useIntegration = () => {
    const dispatch = useAppDispatch();
    const integrations = useAppSelector(state => state.integrations);
    const interval = useRef<NodeJS.Timer | undefined>(undefined);

    const globalOfficeUsersSyncedComplete = useMemo(() => integrations.globalOfficeDetails.data ? integrations.globalOfficeDetails.data?.sync.users?.percentage && integrations.globalOfficeDetails.data.sync.users.lastSync && integrations.globalOfficeDetails.data?.sync.users.percentage === 100 : true, [integrations.globalOfficeDetails.data])
    const globalOfficeDataSyncedCompleted = useMemo(() => integrations.globalOfficeDetails.data ? integrations.globalOfficeDetails.data?.sync.data?.percentage && integrations.globalOfficeDetails.data.sync.data.lastSync && integrations.globalOfficeDetails.data?.sync.data.percentage === 100 : true, [integrations.globalOfficeDetails.data])
    const globalOfficeMandatesSyncedCompleted = useMemo(() => integrations.globalOfficeDetails.data ? integrations.globalOfficeDetails.data?.sync.mandates?.percentage && integrations.globalOfficeDetails.data.sync.mandates.lastSync && integrations.globalOfficeDetails.data?.sync.mandates.percentage === 100 : true, [integrations.globalOfficeDetails.data])
    const globalOfficeCustomersSyncedCompleted = useMemo(() => integrations.globalOfficeDetails.data ? integrations.globalOfficeDetails.data?.sync.customers?.percentage && integrations.globalOfficeDetails.data.sync.customers.lastSync && integrations.globalOfficeDetails.data?.sync.customers.percentage === 100 : true, [integrations.globalOfficeDetails.data])
    const hotelaUsersSyncedComplete = useMemo(() => integrations.hotelaDetails.data ? integrations.hotelaDetails.data?.sync.users?.percentage && integrations.hotelaDetails.data.sync.users.lastSync && integrations.hotelaDetails.data?.sync.users?.percentage === 100 : true, [integrations.hotelaDetails.data])

    if (integrations.enabledIntegrations?.status === StatusType.NONE)
        dispatch(enabledIntegrationsFetched({}));

    const refreshDetails = useCallback(() => {
        if (integrations.enabledIntegrations.data?.hotelaEnabled)
            dispatch(hotelaDetailsFetched({ forceReload: true }));
        if (integrations.enabledIntegrations.data?.globalOfficeEnabled)
            dispatch(globalOfficeDetailsFetched({ forceReload: true }));
    }, [dispatch, integrations.enabledIntegrations.data?.globalOfficeEnabled, integrations.enabledIntegrations.data?.hotelaEnabled])

    const launchInterval = useCallback((time = 3000) => {
        if (interval.current === undefined) {
            interval.current = setInterval(() => {
                refreshDetails();
            }, time);
        }

    }, [refreshDetails]);

    useEffect(() => {
        if(!globalOfficeUsersSyncedComplete || !globalOfficeDataSyncedCompleted || !globalOfficeMandatesSyncedCompleted || !globalOfficeCustomersSyncedCompleted || !hotelaUsersSyncedComplete) {
            if (interval.current === undefined)
                launchInterval(3000);
        }
        else {
            if (interval.current !== undefined) {
                clearInterval(interval.current);
                interval.current = undefined;
            }
        }
    }, [globalOfficeCustomersSyncedCompleted, globalOfficeDataSyncedCompleted, globalOfficeMandatesSyncedCompleted, globalOfficeUsersSyncedComplete, hotelaUsersSyncedComplete, integrations.globalOfficeDetails.data?.sync.users.percentage, integrations.hotelaDetails.data?.sync.users.lastSync, integrations.hotelaDetails.data?.sync.users?.percentage, launchInterval]);

    useEffect(() => {
        return () => {
            if (interval.current !== undefined)
                clearInterval(interval.current)
            interval.current = undefined
        };
    }, []);

    useEffect(() => {
        if (integrations.enabledIntegrations.data?.hotelaEnabled && integrations.hotelaDetails?.status === StatusType.NONE)
            dispatch(hotelaDetailsFetched({forceReload: false}));
        if (integrations.enabledIntegrations.data?.globalOfficeEnabled && integrations.globalOfficeDetails?.status === StatusType.NONE)
            dispatch(globalOfficeDetailsFetched({forceReload: false}));
    }, [dispatch, integrations.enabledIntegrations.data, integrations.enabledIntegrations.data?.hotelaEnabled, integrations.globalOfficeDetails?.status, integrations.hotelaDetails?.status, integrations.officeMakerDetails?.status])

    return {
        enabledIntegrations: integrations.enabledIntegrations.data,
        hotelaDetails: integrations.hotelaDetails.data,
        globalOfficeDetails: integrations.globalOfficeDetails.data,
        isLoading: integrations.enabledIntegrations.status === StatusType.PENDING
            || integrations.hotelaDetails.status === StatusType.PENDING && integrations.hotelaDetails.data === undefined
            || integrations.globalOfficeDetails.status === StatusType.PENDING && integrations.globalOfficeDetails.data === undefined,
        refreshDetails: refreshDetails,
        syncGlobalOfficeUsers: () => dispatch(globalOfficeUsersSynced({})),
        syncGlobalOfficeData: () => dispatch(globalOfficeDataSynced({})),
        syncGlobalOfficeCustomers: () => dispatch(globalOfficeCustomersSynced({})),
        syncGlobalOfficeMandates: () => dispatch(globalOfficeMandatesSynced({})),
        syncHotelaUsers: () => dispatch(hotelaUsersSynced({})),
    };
}

export default useIntegration;