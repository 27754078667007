import { CheckCircleOutlined, CloseCircleOutlined, DeleteOutlined, ProfileOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, DatePicker, Drawer, Empty, Input, Row, Space, Table } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import moment, { Moment } from 'moment';
import React from 'react';
import { AiOutlineFileAdd } from 'react-icons/ai';
import { BiArchiveIn, BiArchiveOut } from 'react-icons/bi';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { changeTypesOfDay } from '../../../store/actions/configurations';
import { CaseType } from '../../../utils/constants';
import getFormat from '../../../utils/Lang';
import Network from '../../../utils/network';
import { RouterProps } from '../../../utils/types/generalTypes';
import { Project, TypeOfDay } from '../../../utils/types/planningTypes';
import { ApplicationState, StoreDispatch } from '../../../utils/types/storeTypes';
import { getCaseAndPlural, showNotification } from '../../../utils/utils';
import { IntlProps } from '../../app/LanguageProvider';
import CircleButton from '../../common/fields/circleButton';
import DeleteButton from '../../common/fields/deleteButton';
import InputField from '../../common/fields/inputField';
import Card from '../../common/general/card';
import SpaceContent from '../../common/general/spaceContent';
// import TypeOfDayItem from './typeOfDayItem';

type ReduxProps = ConnectedProps<typeof connector>

interface IProps {
}

type Props = IProps & RouterProps & ReduxProps & IntlProps;

interface State {
    createTypeOfDay: TypeOfDay | undefined;
    projects?: Project[];
    loadingProjects: boolean;
    searchedProjects?: Project[];
    addingProject: boolean;
    showingProject: boolean;
    createProject?: Project;
    showProject?: Project;
    createProjectTitleError: boolean;
}

/**
 * Component for the types of day tab in the configurations page
 */
class ListProjects extends React.Component<Props, State> {
    projectsColumnsSmartphone: ColumnProps<Project>[] = [
        {
            title: <FormattedMessage defaultMessage={'Title'} />,
            dataIndex: 'title',
            key: 'title',
            fixed: true,
            sorter: (a: Project, b: Project) => (a.title && b.title) ? a.title.localeCompare(b.title) : 0,
            defaultSortOrder: 'ascend',
        },
        {
            className: '__table-text-centered',
            title: <FormattedMessage defaultMessage={'Actions'} />,
            key: 'actions',
            width: '115px',
            render: (record: Project) => {
                return <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Space>
                        <CircleButton
                            disabled={!record.isArchived}
                            icon={<DeleteOutlined />}
                            title={this.props.intl.formatMessage({ defaultMessage: 'Delete permanently' })}
                            // small={this.props.small}
                            // style={this.props.style}
                            onClick={(e) => {
                                e.stopPropagation();
                                this.deleteProject(record);
                            }}
                            placement={'left'} />
                        <CircleButton
                            icon={record.isArchived ? <BiArchiveOut className="anticon-svg" /> : <BiArchiveIn className="anticon-svg" />}
                            title={record.isArchived ? this.props.intl.formatMessage({ defaultMessage: 'Unarchive' }) : this.props.intl.formatMessage({ defaultMessage: 'Archive' })}
                            // small={this.props.small}
                            // style={this.props.style}
                            onClick={(e) => {
                                e.stopPropagation();
                                record.isArchived ? this.unarchiveProject(record) : this.archiveProject(record);
                            }}
                            placement={'left'} />
                    </Space>
                </div>
            }
        }
    ];
    projectsColumns: ColumnProps<Project>[] = [
        {
            title: <FormattedMessage defaultMessage={'Title'} />,
            dataIndex: 'title',
            key: 'title',
            fixed: true,
            sorter: (a: Project, b: Project) => (a.title && b.title) ? a.title.localeCompare(b.title) : 0,
        },
        {
            title: <FormattedMessage defaultMessage={'Identifier'} />,
            dataIndex: 'customId',
            key: 'customId',
            fixed: true,
            sorter: (a: Project, b: Project) => (a.customId && b.customId) ? a.customId.localeCompare(b.customId) : !a.customId && !b.customId ? 0 : !a.customId ? -1 : +1,
        },
        {
            title: <FormattedMessage defaultMessage={'Start date'} />,
            dataIndex: 'startDate',
            key: 'startDate',
            render: (startDate: Moment) => startDate !== null && startDate !== undefined ? moment(startDate).format(getFormat('DATE')) : "-",
            sorter: (a: Project, b: Project) => a.startDate && b.startDate ? moment(a.startDate).unix() - moment(b.startDate).unix() : !a.startDate && !b.startDate ? 0 : !a.startDate ? -1 : +1
            // sorter: (a: User, b: User) => a.email.localeCompare(b.email)
        },
        {
            title: <FormattedMessage defaultMessage={'End date'} />,
            dataIndex: 'endDate',
            key: 'endDate',
            render: (endDate: Moment) => endDate !== null && endDate !== undefined ? moment(endDate).format(getFormat('DATE')) : "-",
            sorter: (a: Project, b: Project) => a.endDate && b.endDate ? moment(a.endDate).unix() - moment(b.endDate).unix() : !a.endDate && !b.endDate ? 0 : !a.endDate ? -1 : +1
            // sorter: (a: User, b: User) => a.email.localeCompare(b.email)
        },
        {
            title: <FormattedMessage defaultMessage={'Created'} />,
            // dataIndex: 'creator',
            key: 'created',
            render: (c: Project) => c.creator && c.creator?.first_name + " " + c.creator?.last_name + " (" + moment(c.created).format(getFormat('DATE')) + ")",
            // sorter: (a: User, b: User) => a.email.localeCompare(b.email)
        },
        // {
        //     title: 'Modification',
        //     key: 'modified',
        //     render: (m: Project) => m.modifier ? m.modifier?.first_name + " " + m.modifier?.last_name + " (" + moment(m.modified).format("DD/MM/YYYY") + ")" : "Aucune modification",
        //     // sorter: (a: User, b: User) => a.email.localeCompare(b.email)
        // },
        {
            className: '__table-text-centered',
            title: <FormattedMessage defaultMessage={'Report'} />,
            dataIndex: 'inReports',
            key: 'inReports',
            render: (report: boolean) => report ? <CheckCircleOutlined className="__check-green" /> : <CloseCircleOutlined className="__uncheck-red" />,
            width: '120px',
            filters: [
                {
                    text: <FormattedMessage defaultMessage={'Yes'} />,
                    value: true,
                },
                {
                    text: <FormattedMessage defaultMessage={'No'} />,
                    value: false,
                }
            ],
            filterMultiple: false,
            onFilter: (value: string | number | boolean, project: Project) => project.inReports === value,
            // sorter: (a: User, b: User) => a.first_name.localeCompare(b.first_name),
        },
        {
            className: '__table-text-centered',
            title: <FormattedMessage defaultMessage={'Archived'} />,
            dataIndex: 'isArchived',
            key: 'isArchived',
            render: (archived: boolean) => archived ? <CheckCircleOutlined className="__check-green" /> : <CloseCircleOutlined className="__uncheck-red" />,
            filters: [
                {
                    text: <FormattedMessage defaultMessage={'Yes'} />,
                    value: true,
                },
                {
                    text: <FormattedMessage defaultMessage={'No'} />,
                    value: false,
                }
            ],
            filterMultiple: false,
            onFilter: (value: string | number | boolean, project: Project) => project.isArchived === value,
            width: '120px',
            // sorter: (a: User, b: User) => a.first_name.localeCompare(b.first_name),
        },
        {
            className: '__table-text-centered',
            title: <FormattedMessage defaultMessage={'Actions'} />,
            key: 'actions',
            width: '180px',
            render: (record: Project) => {
                return (
                    <SpaceContent align='center'>
                        <DeleteButton
                            disabled={!record.isArchived}
                            text={<FormattedMessage defaultMessage={'Do you want to delete this {project}'} values={{ project: this.props.company?.projectDisplayText ? getCaseAndPlural(this.props.company?.projectDisplayText, false, CaseType.FULL_LOWERCASE) : this.props.intl.formatMessage({ defaultMessage: 'project' }) }} />}
                            onConfirm={(e) => { e?.stopPropagation(); this.deleteProject(record) }}
                            placement="left"
                            buttonPlacement="right"
                        />
                        <CircleButton
                            icon={record.isArchived ? <BiArchiveOut className="anticon-svg" /> : <BiArchiveIn className="anticon-svg" />}
                            title={record.isArchived ? this.props.intl.formatMessage({ defaultMessage: 'Unarchive' }) : this.props.intl.formatMessage({ defaultMessage: 'Archive' })}
                            onClick={(e) => {
                                e.stopPropagation();
                                record.isArchived ? this.unarchiveProject(record) : this.archiveProject(record);
                            }}
                            placement={'left'} />
                    </SpaceContent>
                )
            }
        }
    ];

    constructor(props: Props) {
        super(props);

        this.state = {
            createTypeOfDay: undefined,
            addingProject: false,
            showingProject: false,
            createProjectTitleError: false,
            loadingProjects: false,
        };
    }

    componentDidMount() {
        this.refresh();
    }

    searchProjects = (projectTitle: string) => {
        const { projects } = this.state;
        if (projectTitle.length !== 0) {
            const searchedProjects = projects?.filter((p) => p.title?.toLowerCase().includes(projectTitle.toLowerCase()));
            this.setState({ searchedProjects });
        } else {
            this.setState({ searchedProjects: undefined });
        }
    }

    /**
     * Refresh the projects
     */
    refresh = () => {
        !this.state.loadingProjects && this.setState({ loadingProjects: true });
        Network.getProjects().then(
            (response: Project[]) => {
                this.setState({ projects: response, loadingProjects: false });
            },
            () => {
                this.setState({ loadingProjects: false });
                showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the {project}' }, { project: this.props.company?.projectDisplayText ? getCaseAndPlural(this.props.company?.projectDisplayText, true, CaseType.FULL_LOWERCASE) : this.props.intl.formatMessage({ defaultMessage: 'projects' }) }), "error")
            },
        );
    }

    showAddProject = (modifyProject?: Project) => {
        let newProject: Project;
        if (modifyProject) {
            const tmpModifyProject = { ...modifyProject }
            tmpModifyProject.startDate = tmpModifyProject.startDate == null ? undefined : moment(tmpModifyProject.startDate);
            tmpModifyProject.endDate = tmpModifyProject.endDate == null ? undefined : moment(tmpModifyProject.endDate);

            newProject = tmpModifyProject;
        } else {
            newProject = {
                inReports: true,
            };
        }
        this.setState({ addingProject: true, createProject: newProject });
    };

    closeAddProject = () => {
        if (this.state.showProject && this.state.showProject.id) {
            this.setState({ addingProject: false, createProject: undefined, createProjectTitleError: false });
        } else {
            this.setState({ addingProject: false, createProject: undefined, createProjectTitleError: false, showingProject: false, showProject: undefined });
        }
    };

    showDetailsProject = (project: Project) => {
        this.setState({ showingProject: true, showProject: project });
    }

    closeShowDetailsProject = () => {
        this.setState({ showingProject: false, showProject: undefined });
    };

    archiveProject = (project: Project) => {
        project.id ?
            Network.archiveProjects(project.id).then(
                () => {
                    let { projects } = this.state;
                    projects = projects?.map(p => p.id === project.id ? { ...p, isArchived: true } : p);
                    this.setState({ projects });
                    showNotification(this.props.intl.formatMessage({ defaultMessage: 'The {project} has been successfully archived' }, { project: this.props.company?.projectDisplayText ? getCaseAndPlural(this.props.company?.projectDisplayText, false, CaseType.FULL_LOWERCASE) : this.props.intl.formatMessage({ defaultMessage: 'projects' }) }), "success")
                },
                () => showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while archiving the {project}' }, { project: this.props.company?.projectDisplayText ? getCaseAndPlural(this.props.company?.projectDisplayText, false, CaseType.FULL_LOWERCASE) : this.props.intl.formatMessage({ defaultMessage: 'projects' }) }), "error")
            )
            :
            showNotification(this.props.intl.formatMessage({ defaultMessage: 'The {project} does not exist' }, { project: this.props.company?.projectDisplayText ? getCaseAndPlural(this.props.company?.projectDisplayText, false, CaseType.FULL_LOWERCASE) : this.props.intl.formatMessage({ defaultMessage: 'projects' }) }), "warning")
    }

    unarchiveProject = (project: Project) => {
        project.id ?
            Network.unarchiveProjects(project.id).then(
                () => {
                    let { projects } = this.state;
                    projects = projects?.map(p => p.id === project.id ? { ...p, isArchived: false } : p);
                    this.setState({ projects });
                    showNotification(this.props.intl.formatMessage({ defaultMessage: 'The {project} has been successfully unarchived' }, { project: this.props.company?.projectDisplayText ? getCaseAndPlural(this.props.company?.projectDisplayText, false, CaseType.FULL_LOWERCASE) : this.props.intl.formatMessage({ defaultMessage: 'projects' }) }), "success")
                },
                () => showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while unarchiving the {project}' }, { project: this.props.company?.projectDisplayText ? getCaseAndPlural(this.props.company?.projectDisplayText, false, CaseType.FULL_LOWERCASE) : this.props.intl.formatMessage({ defaultMessage: 'projects' }) }), "error")
            )
            :
            showNotification(this.props.intl.formatMessage({ defaultMessage: 'The {project} does not exist' }, { project: this.props.company?.projectDisplayText ? getCaseAndPlural(this.props.company?.projectDisplayText, false, CaseType.FULL_LOWERCASE) : this.props.intl.formatMessage({ defaultMessage: 'projects' }) }), "warning")
    }

    updateProject = () => {
        const { createProject, projects } = this.state;
        if (createProject !== undefined) {
            createProject.title && createProject.title.length > 0 ?
                Network.updateProject(createProject).then(
                    (response: Project) => {
                        // this.refresh();
                        const tmpProjects: Project[] = [...projects!];
                        if (createProject && createProject.id) {
                            const indexProject = tmpProjects!.map(p => p.id === response.id).indexOf(true);
                            tmpProjects![indexProject] = response;
                        } else {
                            tmpProjects!.unshift(response);
                        }
                        this.setState({ projects: tmpProjects, showProject: response });
                        this.closeAddProject();
                        showNotification(this.props.intl.formatMessage({ defaultMessage: 'The {project} has been successfully created' }, { project: this.props.company?.projectDisplayText ? getCaseAndPlural(this.props.company?.projectDisplayText, false, CaseType.FULL_LOWERCASE) : this.props.intl.formatMessage({ defaultMessage: 'projects' }) }), "success")
                    },
                    () => {
                        this.closeAddProject();
                        showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while creating the {project}' }, { project: this.props.company?.projectDisplayText ? getCaseAndPlural(this.props.company?.projectDisplayText, false, CaseType.FULL_LOWERCASE) : this.props.intl.formatMessage({ defaultMessage: 'projects' }) }), "error")
                    },
                )
                :
                this.setState({ createProjectTitleError: true });
        } else {
            showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while creating the {project}' }, { project: this.props.company?.projectDisplayText ? getCaseAndPlural(this.props.company?.projectDisplayText, false, CaseType.FULL_LOWERCASE) : this.props.intl.formatMessage({ defaultMessage: 'projects' }) }), "warning")
        }
    }

    deleteProject = (project: Project) => {
        project.id ?
            Network.deleteProject(project.id).then(
                () => {
                    let { projects } = this.state;
                    projects = projects?.filter(p => p.id !== project.id);
                    this.setState({ projects });
                    showNotification(this.props.intl.formatMessage({ defaultMessage: 'The {project} has been successfully deleted' }, { project: this.props.company?.projectDisplayText ? getCaseAndPlural(this.props.company?.projectDisplayText, false, CaseType.FULL_LOWERCASE) : this.props.intl.formatMessage({ defaultMessage: 'projects' }) }), "success")
                },
                () => showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while deleting the {project}' }, { project: this.props.company?.projectDisplayText ? getCaseAndPlural(this.props.company?.projectDisplayText, false, CaseType.FULL_LOWERCASE) : this.props.intl.formatMessage({ defaultMessage: 'projects' }) }), "error")
            )
            :
            showNotification(this.props.intl.formatMessage({ defaultMessage: 'The {project} does not exist' }, { project: this.props.company?.projectDisplayText ? getCaseAndPlural(this.props.company?.projectDisplayText, false, CaseType.FULL_LOWERCASE) : this.props.intl.formatMessage({ defaultMessage: 'projects' }) }), "warning")
    }

    projectsOnRow = (record: Project) => ({
        onClick: () => this.showDetailsProject(record)
    });

    render() {
        const { createProject, showProject, loadingProjects } = this.state;
        const { intl } = this.props;
        return (
            <>
                <Card
                    className="team-card"
                    icon={<ProfileOutlined />}
                    title={this.props.company?.projectDisplayText ? getCaseAndPlural(this.props.company?.projectDisplayText, true, CaseType.FIRST_LETTER_UPPERCASE) : <FormattedMessage defaultMessage={'Projects'} />}
                    headerElements={[
                        <CircleButton
                            disabled={loadingProjects}
                            onClick={() => this.setState({ showingProject: true, showProject: {} }, () => this.showAddProject())}
                            icon={<AiOutlineFileAdd className="anticon-svg" />}
                            title={intl.formatMessage({ defaultMessage: 'Add a {project}' }, { project: this.props.company?.projectDisplayText ? getCaseAndPlural(this.props.company?.projectDisplayText, false, CaseType.FULL_LOWERCASE) : intl.formatMessage({ defaultMessage: 'project' }) })}
                            placement="left"
                            key="team-management-add-project" />
                    ]}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <InputField
                            allowClear
                            // onChange={this.handleSearchUsers}
                            onChange={(e: { target: { value: string; }; }) => this.searchProjects(e.target.value)}
                            style={{ width: '300px', marginBottom: '10px' }}
                            type="search" />
                        {
                            // this.state.selectedUsers.length > 0 &&
                            // <Popconfirm
                            //     title={`Cette action supprimera ${this.state.selectedUsers.length > 1 ? 'les utilisateurs' : 'l\'utilisateur'} et tous ${this.state.selectedUsers.length > 1 ? 'leurs' : 'ses'} événements, périodes et exclusions.`}
                            //     okText={"Supprimer"}
                            //     cancelText={"Annuler"}
                            //     okButtonProps={{ type: 'link', danger: true }}
                            //     cancelButtonProps={{ type: 'link' }}
                            //     icon={<QuestionCircleOutlined style={{ color: '#ff2626' }} />}
                            //     onConfirm={this.deleteUsers}>
                            //     <Button
                            //         className="fade-in-animation"
                            //         style={{ animationDuration: '0.5s' }}
                            //         type="primary"
                            //         icon={<DownloadOutlined />}
                            //     // disabled={Boolean(this.state.isLoading)}
                            //     // loading={this.state.isLoading === -1}
                            //     >
                            //         {`Supprimer ${this.state.selectedUsers.length} utilisateur${this.state.selectedUsers.length > 1 ? 's' : ''}`}
                            //     </Button>
                            // </Popconfirm>

                        }
                    </div>
                    <Table
                        loading={loadingProjects}
                        className={"__team-management-user-list"}
                        rowKey={(u: any) => u.id}
                        dataSource={this.state.searchedProjects ? this.state.searchedProjects : this.state.projects}
                        columns={this.props.isSmartphone ? this.projectsColumnsSmartphone : this.projectsColumns}
                        // pagination={false}
                        // loading={this.state.usersLoading}
                        onRow={this.projectsOnRow}
                        // rowSelection={{ type: 'checkbox', onChange: this.onChangeSelectedUsers, selectedRowKeys: this.state.selectedUsers }}
                        locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<FormattedMessage defaultMessage={'No project'} />} /> }}
                        pagination={{
                            defaultPageSize: 16,
                            pageSizeOptions: ["8", "16", "32", "64", "72"],
                            showSizeChanger: true,
                            hideOnSinglePage: true,
                            showTotal: (total, range) => <FormattedMessage defaultMessage={'{range0}-{range1} of {total} {project}'} values={{ range0: range[0], range1: range[1], total, project: this.props.company?.projectDisplayText ? getCaseAndPlural(this.props.company?.projectDisplayText, true, CaseType.FULL_LOWERCASE) : intl.formatMessage({ defaultMessage: 'projects' }) }} />
                        }} />
                </Card>
                <Drawer
                    destroyOnClose={true}
                    // zIndex={1011}
                    className="__drawer"
                    title={showProject?.id ? <FormattedMessage defaultMessage={'{project} {name}'} values={{ project: this.props.company?.projectDisplayText ? getCaseAndPlural(this.props.company?.projectDisplayText, false, CaseType.FIRST_LETTER_UPPERCASE) : intl.formatMessage({ defaultMessage: 'Project' }), name: showProject?.title }} /> : <FormattedMessage defaultMessage={'Creation in progress'} />}
                    width={this.props.isSmartphone ? '100%' : '650px'}
                    onClose={this.closeShowDetailsProject}
                    visible={this.state.showingProject}
                    // bodyStyle={{ paddingBottom: 80 }}
                    footer={
                        <div
                            style={{
                                textAlign: 'right',
                            }}
                        >
                            {
                                <>
                                    <Button onClick={this.closeShowDetailsProject} style={{ marginRight: 8 }}>
                                        <FormattedMessage defaultMessage={'Close'} />
                                    </Button>
                                    <Button onClick={() => this.showAddProject(showProject)} type="primary">
                                        <FormattedMessage defaultMessage={'Edit'} />
                                    </Button>
                                </>
                            }
                        </div>
                    }
                >
                    {
                        showProject &&
                        <>
                            <Row gutter={[16, 24]}>
                                <Col xs={{ span: 24 }} md={{ span: 24 }}>
                                    <p><span style={{ fontWeight: 'bold' }}><FormattedMessage defaultMessage={'Identifier'} />{':'} </span>{showProject.customId}</p>
                                </Col>
                                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                                    <p><span style={{ fontWeight: 'bold' }}><FormattedMessage defaultMessage={'Start date'} />{':'}  </span>{showProject.startDate && showProject.startDate !== null ? moment(showProject.startDate).format(getFormat('DATE')) : "-"}</p>
                                </Col>
                                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                                    <p><span style={{ fontWeight: 'bold' }}><FormattedMessage defaultMessage={'End date'} />{':'} </span>{showProject.endDate && showProject.endDate !== null ? moment(showProject.endDate).format(getFormat('DATE')) : "-"}</p>
                                </Col>
                                <Col xs={{ span: 24 }}>
                                    <p><span style={{ fontWeight: 'bold' }}><FormattedMessage defaultMessage={'Description'} />{':'} </span><pre style={{fontFamily: 'inherit'}}>{showProject.description}</pre></p>
                                </Col>
                                <Col xs={{ span: 24 }}>
                                    <p><span style={{ fontWeight: 'bold' }}><FormattedMessage defaultMessage={'Options'} />{':'} </span><FormattedMessage defaultMessage={'This {project} {included}.'} values={{ project: this.props.company?.projectDisplayText ? getCaseAndPlural(this.props.company?.projectDisplayText, false, CaseType.FULL_LOWERCASE) : intl.formatMessage({ defaultMessage: 'project' }), included: showProject.inReports ? intl.formatMessage({ defaultMessage: 'appears in the reports' }) : intl.formatMessage({ defaultMessage: 'does not appear in the reports' }) }} />{' '}
                                        <FormattedMessage defaultMessage={'This {project} {archived}.'} values={{ project: this.props.company?.projectDisplayText ? getCaseAndPlural(this.props.company?.projectDisplayText, false, CaseType.FULL_LOWERCASE) : intl.formatMessage({ defaultMessage: 'project' }), archived: showProject.isArchived ? intl.formatMessage({ defaultMessage: 'is archived' }) : intl.formatMessage({ defaultMessage: 'is not archived' }) }} /></p>
                                </Col>
                                <Col xs={{ span: 24 }}>
                                    {showProject.creator && <p><span style={{ fontWeight: 'bold' }}><FormattedMessage defaultMessage={'Creation'} />{':'} </span><FormattedMessage defaultMessage={'This {project} has been created on {date} by {user}'} values={{ project: this.props.company?.projectDisplayText ? getCaseAndPlural(this.props.company?.projectDisplayText, false, CaseType.FULL_LOWERCASE) : intl.formatMessage({ defaultMessage: 'project' }), date: moment(showProject.created).format(getFormat('DATE')), user: `${showProject.creator?.first_name} ${showProject.creator?.last_name}` }} /></p>}
                                    {showProject.modifier && <p><span style={{ fontWeight: 'bold' }}><FormattedMessage defaultMessage={'Modified'} />{':'} </span><FormattedMessage defaultMessage={'This {project} has been modified on {date} by {user}'} values={{ project: this.props.company?.projectDisplayText ? getCaseAndPlural(this.props.company?.projectDisplayText, false, CaseType.FULL_LOWERCASE) : intl.formatMessage({ defaultMessage: 'project' }), date: moment(showProject.modified).format(getFormat('DATE')), user: `${showProject.modifier?.first_name} ${showProject.modifier?.last_name}` }} /></p>}
                                </Col>
                            </Row>
                            <Drawer
                                destroyOnClose={true}
                                // zIndex={1011}
                                className="__drawer"
                                title={showProject?.id ? <FormattedMessage defaultMessage={'Edit {project} {name}'} values={{ project: this.props.company?.projectDisplayText ? getCaseAndPlural(this.props.company?.projectDisplayText, false, CaseType.FULL_LOWERCASE) : intl.formatMessage({ defaultMessage: 'project' }), name: showProject?.title }} /> : <FormattedMessage defaultMessage={'Create a new {project}'} values={{ project: this.props.company?.projectDisplayText ? getCaseAndPlural(this.props.company?.projectDisplayText, false, CaseType.FULL_LOWERCASE) : intl.formatMessage({ defaultMessage: 'project' }) }} />}
                                width={this.props.isSmartphone ? '100%' : '650px'}
                                onClose={this.closeAddProject}
                                visible={this.state.addingProject}
                                // bodyStyle={{ paddingBottom: 80 }}
                                footer={
                                    <div
                                        style={{
                                            textAlign: 'right',
                                        }}
                                    >
                                        {
                                            <>
                                                <Button onClick={this.closeAddProject} style={{ marginRight: 8 }}>
                                                    <FormattedMessage defaultMessage={'Cancel'} />
                                                </Button>
                                                <Button onClick={this.updateProject} type="primary">
                                                    <FormattedMessage defaultMessage={'Save'} />
                                                </Button>
                                            </>
                                        }
                                    </div>
                                }
                            >
                                {
                                    <Row gutter={[16, 24]}>
                                        <Col xs={{ span: 24 }} md={{ span: 12 }}>
                                            <Input
                                                style={this.state.createProjectTitleError ? { borderColor: 'red' } : {}}
                                                value={createProject?.title}
                                                placeholder={intl.formatMessage({ defaultMessage: 'Title' })}
                                                onChange={(v) => {
                                                    createProject!.title = v.target.value
                                                    this.setState({ createProject })
                                                }}
                                            />
                                            {this.state.createProjectTitleError &&
                                                <span style={{ color: 'red', fontStyle: 'italic', fontSize: '86%' }}><FormattedMessage defaultMessage={'Please enter a title'} /></span>}
                                        </Col>
                                        <Col xs={{ span: 24 }} md={{ span: 12 }}>
                                            <Input
                                                value={createProject?.customId}
                                                placeholder={intl.formatMessage({ defaultMessage: 'Identifier' })}
                                                onChange={(v) => {
                                                    createProject!.customId = v.target.value
                                                    this.setState({ createProject })
                                                }}
                                            />
                                        </Col>
                                        <Col xs={{ span: 24 }} md={{ span: 12 }}>
                                            <DatePicker
                                                value={createProject?.startDate}
                                                style={{ width: '100%' }}
                                                format={getFormat('DATE')}
                                                placeholder={intl.formatMessage({ defaultMessage: 'Please select a start date' })}
                                                onChange={(v) => {
                                                    createProject!.startDate = v == null ? undefined : v
                                                    this.setState({ createProject })
                                                }}
                                            />
                                        </Col>
                                        <Col xs={{ span: 24 }} md={{ span: 12 }}>
                                            <DatePicker
                                                value={createProject?.endDate}
                                                style={{ width: '100%' }}
                                                format={getFormat('DATE')}
                                                placeholder={intl.formatMessage({ defaultMessage: 'Please select an end date' })}
                                                onChange={(v) => {
                                                    createProject!.endDate = v == null ? undefined : v
                                                    this.setState({ createProject })
                                                }}
                                            />
                                        </Col>
                                        <Col xs={{ span: 24 }}>
                                            <Input.TextArea
                                                value={createProject?.description}
                                                rows={4}
                                                placeholder={intl.formatMessage({ defaultMessage: 'Description' })}
                                                onChange={(v) => {
                                                    createProject!.description = v.target.value
                                                    this.setState({ createProject })
                                                }}
                                            />
                                        </Col>
                                        <Col xs={{ span: 24 }}>
                                            <Checkbox
                                                checked={createProject?.inReports}
                                                onChange={(v) => {
                                                    createProject!.inReports = v.target.checked
                                                    this.setState({ createProject })
                                                }}
                                            ><FormattedMessage defaultMessage={'Appears in the report?'} /></Checkbox>
                                        </Col>
                                    </Row>
                                }

                            </Drawer>
                        </>
                    }

                </Drawer>
            </>
        )
    }
}

const mapDispatchToProps = (dispatch: StoreDispatch) => ({
    changeTypesOfDay: (t: TypeOfDay[]) => dispatch(changeTypesOfDay(t)),
});

const mapStateToProps = (state: ApplicationState) => ({
    company: state.user.company,
    isSmartphone: state.window.isSmartphone,
    typesOfDay: state.configurations.typesOfDay,
});
const connector = connect(mapStateToProps, mapDispatchToProps)

export default connector(withRouter(injectIntl(ListProjects)));