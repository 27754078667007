import { CheckOutlined, CloseOutlined, DeleteOutlined, EditOutlined, InfoCircleOutlined, LeftOutlined, PlusCircleOutlined, PlusOutlined, RightOutlined } from '@ant-design/icons';
import { Badge, Button, Card, Checkbox, Col, Collapse, Divider, Empty, InputNumber, Modal, Row, Select, Spin, Steps, Switch, Tag, Tooltip } from 'antd';
import cloneDeep from 'lodash/cloneDeep';
import toFinite from 'lodash/toFinite';
import moment, { Moment } from 'moment';
import React, { ReactElement } from 'react';
import isEqual from 'react-fast-compare';
import { AiOutlinePicture } from 'react-icons/ai';
import { MdAttachFile } from 'react-icons/md';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { Rules } from '../../rbacRules';
import { loadDepartments, loadTypesOfVehicle } from '../../store/actions/configurations';
import { loadPois } from '../../store/actions/location';
import { changeTemplates, updateTemplates } from '../../store/actions/planning';
import { CaseType } from '../../utils/constants';
import { EventDataType } from '../../utils/enumerations';
import getFormat from '../../utils/Lang';
import Network from '../../utils/network';
import { UserEventsData } from '../../utils/objects/cct/userEventsData';
import { GenericFile, StaffType, ValidateFile } from '../../utils/types/generalTypes';
import { NetworkReminder } from '../../utils/types/networkTypes';
import { BreakTime, BreakTimeEdit, PlanningTemplate, Project, Reminder } from '../../utils/types/planningTypes';
import { ApplicationState, StoreDispatch } from '../../utils/types/storeTypes';
import { checkRBACRule, convertNetworkReminderToReminder, convertNetworkTemplateToPlanningTemplate, convertPlanningEventToSimpleEvent, displayErrorMessage, getCaseAndPlural, getWeekdayText, momentDurationFormat, roundDecimals, showNotification } from '../../utils/utils';
import { IntlProps } from '../app/LanguageProvider';
import CircleButton from '../common/fields/circleButton';
import DateTimePicker, { DateTimePickerType, DateTimePickerVersion } from '../common/fields/dateTimePicker';
import DeleteButton from '../common/fields/deleteButton';
import InputField, { InputFieldOnChangeEvent } from '../common/fields/inputField';
import Anticon from '../common/general/anticon';
import SpaceContent from '../common/general/spaceContent';
import ReminderModal, { automaticDurationTypeSelection } from './reminderModal';
import UploadModal from './templateUploadModal';

type ReduxProps = ConnectedProps<typeof connector>;

interface Props extends ReduxProps, IntlProps {
    visible: boolean;
    isEdit: boolean;
    template?: PlanningTemplate;
    templateId?: number;
    project: Project[];
    notToSave?: boolean;
    onClose: () => void;
    onSave?: (template: PlanningTemplate) => void;
}

interface State {
    template?: PlanningTemplate;
    loadingTemplate: boolean;
    currentStep: number;
    untilShowTime: boolean;
    allDay: boolean;
    loading: boolean;
    editStaffType: StaffType;
    editStaffTypeError: boolean;
    staffTypes?: StaffType[];
    addBreaktime: boolean;
    editBreakTime: BreakTimeEdit;
    editBreakTimeError: boolean;

    addDocuments: boolean;
    addImages: boolean;
    editReminder?: Reminder;
    newReminderIndex: number;
    addReminder: boolean;

    files: GenericFile[];
    loadingFiles: boolean;
    filesToValidate: ValidateFile[];
    imagesToValidate: ValidateFile[];
}

/**
 * Component for the modal to create and edit a template
 */
class TemplateModal extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            filesToValidate: [],
            imagesToValidate: [],
            files: [],
            loadingFiles: false,
            addDocuments: false,
            addImages: false,
            addReminder: false,
            newReminderIndex: -1,

            template: this.props.template ? cloneDeep(this.props.template) : undefined,
            loadingTemplate: this.props.template ? false : true,
            currentStep: 0,
            untilShowTime: true,
            allDay: false,
            loading: false,
            editBreakTime: {
                startDate: undefined,
                endDate: undefined,
            },
            editBreakTimeError: false,
            editStaffType: {},
            editStaffTypeError: false,
            addBreaktime: false
        };
    }

    componentDidMount() {
        this.props.loadTypesOfVehicle();
        this.props.loadPois();
        this.props.loadDepartments();

        this.state.template === undefined && this.initializeTemplate();

        Network.getStaffType().then(
            response => {
                this.setState({ staffTypes: response });
            },
            () => showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the abilities' }), "error")
        );

    }

    componentDidUpdate(prevProps: Props) {
        if (!isEqual(prevProps.template, this.props.template) || prevProps.templateId !== this.props.templateId) {
            this.initializeTemplate();
        }
    }

    initializeTemplate = () => {
        const { templateId, template } = this.props;
        if (templateId !== undefined) {

            if (templateId > 0) {
                this.setState({ loadingTemplate: true });
                Network.getTemplate(templateId).then(
                    (response) => {
                        if (response.error) {
                            showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the template to edit' }), "error");
                            this.props.onClose();
                        } else {
                            this.setState({ template: convertNetworkTemplateToPlanningTemplate(response.data) }, () => this.loadTemplateFiles());
                        }
                    },
                    () => {
                        showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the template to edit' }), "error");
                        this.props.onClose();
                    }
                ).finally(() => this.setState({ loadingTemplate: false }));
            } else {
                this.setState({
                    template: {
                        startDate: moment(),
                        endDate: moment(),
                        title: "",
                        color: undefined,
                    },
                    loadingTemplate: false
                });
            }
        } else if (template !== undefined) {
            this.setState({ template: cloneDeep(template), loadingTemplate: false }, () => this.loadTemplateFiles());
        }
    };

    removeEventReminder = (reminder: Reminder) => {
        const template = cloneDeep(this.state.template);
        if (reminder && template) {
            if (template.id) {
                Network.removeTemplateReminder(reminder.id, template.id).then(
                    (response: { error: boolean, data: NetworkReminder, message: string; }) => {
                        if (response.error === false) {
                            template.reminders = template.reminders?.filter(r => r.id !== response.data.id);

                            this.setState({ template, editReminder: undefined, addReminder: false });
                        } else {
                            showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while deleting the reminder' }), "warning");
                        }
                    },
                    () => {
                        this.setState({ loadingFiles: false });
                        showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while deleting the reminder' }), "warning");
                    }
                );
            } else {
                template.reminders = template.reminders?.filter(r => r.id !== reminder.id);

                this.setState({ template, editReminder: undefined, addReminder: false });
            }
        }
    };


    saveTemplateReminder = (reminder: Reminder) => {
        const template = cloneDeep(this.state.template);
        if (reminder && template) {
            if (template.id) {
                if (reminder.id > 0 && reminder.id !== undefined) {
                    Network.updateTemplateReminder(reminder.id, template.id, reminder.minBeforeEventStart).then(
                        (response: { error: boolean, data: NetworkReminder, message: string; }) => {
                            if (response.error === false) {
                                const reminder = convertNetworkReminderToReminder(response.data);
                                const findReminderId = template.reminders?.findIndex(r => r.id === response.data.id);
                                if (findReminderId === undefined || findReminderId < 0) {
                                    template.reminders?.push(reminder);
                                } else {
                                    if (!template.reminders) {
                                        template.reminders = [reminder];
                                    } else {
                                        template.reminders[findReminderId] = reminder;
                                    }
                                }
                                this.setState({ template, editReminder: undefined, addReminder: false });
                            } else {
                                showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while updating the reminder' }), "warning");
                            }
                        },
                        () => {
                            showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while updating the reminder' }), "warning");
                        }
                    );
                } else {
                    Network.addTemplateReminder(template.id, reminder.minBeforeEventStart).then(
                        (response: { error: boolean, data: NetworkReminder, message: string; }) => {
                            if (response.error === false) {
                                const reminder = convertNetworkReminderToReminder(response.data);
                                if (!template.reminders) {
                                    template.reminders = [reminder];
                                } else {
                                    template.reminders.push(reminder);
                                }
                                this.setState({ template, editReminder: undefined, addReminder: false });
                            } else {
                                showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while creating the reminder' }), "warning");
                            }
                        },
                        () => {
                            showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while creating the reminder' }), "warning");
                        }
                    );
                }
            } else {
                const findReminderId = (reminder !== undefined && reminder.id !== undefined) ? template.reminders?.findIndex(r => r.id === reminder.id) : -1;
                if (findReminderId === undefined || findReminderId < 0) {
                    if (template.reminders) {
                        template.reminders.push(reminder);
                    } else {
                        template.reminders = [reminder];
                    }
                } else {
                    if (!template.reminders) {
                        template.reminders = [reminder];
                    } else {
                        template.reminders[findReminderId] = reminder;
                    }
                }

                this.setState({ template, editReminder: undefined, addReminder: false });
            }

        }

    };

    loadTemplateFiles = () => {
        const { template, loadingFiles } = this.state;
        if (!loadingFiles) {
            this.setState({ loadingFiles: true }, () => {
                if (template && template.id && template.id > 0) {
                    Network.getTemplateFiles(template.id).then(
                        (response) => {
                            if (response.error === false && response.data !== undefined) {
                                this.setState({ loadingFiles: false, files: response.data });
                            } else {
                                this.setState({ loadingFiles: false });
                            }
                        },
                        () => {
                            this.setState({ loadingFiles: false });
                            showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the files' }), "warning");
                        }
                    );
                } else {
                    this.setState({ loadingFiles: false });
                }
            });
        }
    };

    /**
     * Render method for the weekday select
     * @param props the props of the select option
     * @returns the react element to render
     */
    renderWeekdaySelect = (props: any): ReactElement<number> => (
        <Tag className="select-tags" closable={props.closable} onClose={props.onClose}>
            {getWeekdayText(props.value)}
        </Tag>
    );

    /**
     * Called when the user click on the create/save button
     */
    onDone = () => {
        const { template } = this.state;
        if (template === undefined) return;
        if (template.title.length === 0) displayErrorMessage(this.props.intl.formatMessage({ defaultMessage: 'Please give the template a name' }), "template-modal-ok-button");
        else if (template.shouldLast !== undefined && roundDecimals(template.shouldLast * 3600) !== UserEventsData.eventDuration(convertPlanningEventToSimpleEvent(template), true, true, true, true, true)) {
            // Convert to duration
            const durationExpected = moment.duration(roundDecimals(template.shouldLast * 3600), 'seconds');
            const textExpected = `${Math.floor(durationExpected.asHours())}h${durationExpected.minutes()}`;

            const durationActual = moment.duration(UserEventsData.eventDuration(convertPlanningEventToSimpleEvent(template), true, true, true, true, true), 'seconds');
            const textActual = `${Math.floor(durationActual.asHours())}h${durationActual.minutes()}`;
            displayErrorMessage(`La durée du template avec les pauses est de ${textActual} au lieu de ${textExpected}`, "template-modal-ok-button");
        }
        else this.onUpdateTemplate();
    };

    /**
     * Update a template
     */
    onUpdateTemplate = () => {
        const { notToSave, isEdit, onClose, onSave, intl } = this.props;
        const { template } = this.state;

        if (template === undefined) return;
        if (notToSave) {
            onSave ? onSave(cloneDeep(template)) : onClose();
        } else {
            this.setState({ loading: true });
            Network.updateTemplate(template).then(
                (response) => {
                    if (response.error) {
                        showNotification(isEdit ? intl.formatMessage({ defaultMessage: 'An error occurred while updating the template' }) : intl.formatMessage({ defaultMessage: 'An error occurred while creating the template' }), "warning");
                    } else {

                        this.props.updateTemplates([convertNetworkTemplateToPlanningTemplate(response.data)]);
                        showNotification(isEdit ? "Modèle mis à jour avec succès." : "Modèle créé avec succès.", "success");
                        if (!(template && template.id) && response.data.id) {
                            this.validateFiles(response.data.id);
                        }
                        this.setState({ loading: false });
                        onClose();
                    }
                },
                () => {
                    showNotification(isEdit ? intl.formatMessage({ defaultMessage: 'An error occurred while updating the template' }) : intl.formatMessage({ defaultMessage: 'An error occurred while creating the template' }), "warning");
                },
            );
        }
    };

    onChangeStartDate = (date: Moment | null) => {
        const { template } = this.state;

        if (template === undefined) return;
        if (date) {
            const allDay = template.startDate.format('HHmmss') === '000000' && template.endDate.format('HHmmss') === '235959';
            template.startDate = date;
            this.setState({ template, allDay });
        }
    };

    onChangeEndDate = (date: Moment | null) => {
        const { template } = this.state;
        if (template === undefined) return;
        if (date) {
            const allDay = template.startDate.format('HHmmss') === '000000' && template.endDate.format('HHmmss') === '235959';
            template.endDate = date;
            this.setState({ template, allDay });
        }
    };

    onBlur = () => {
        const { template } = this.state;
        if (template === undefined) return;
        const allDay = template.startDate.format('HHmmss') === '000000' && template.endDate.format('HHmmss') === '235959';
        this.setState({ allDay });
    };

    dataChanged = (type: EventDataType, value: any) => {
        const { template } = this.state;
        if (template === undefined) return;

        switch (type) {
            case EventDataType.Title:
                template.title = value.target.value;
                break;
            case EventDataType.Description:
                template.description = value.target.value;
                break;
            case EventDataType.Range:
                template.startDate = this.state.allDay ? moment().set("hour", 0).set("minute", 0).set('second', 0) : value[0];
                template.endDate = this.state.allDay ? moment().set("hour", 23).set("minute", 59).set('second', 59) : value[1];
                break;
            case EventDataType.SendMessage:
                template.sendMessage = value.target.checked;
                break;
            case EventDataType.Color:
                template.color = this.props.settings.colors!.filter(c => c.id === value)[0];
                break;
            case EventDataType.StaffType:
                template.staffType = this.state.staffTypes!.find(t => t.id === value);
                break;
            case EventDataType.TypeOfDay:
                template.typeOfDay = this.props.typesOfDay!.find(t => t.id === value);
                if (template.typeOfDay != undefined) {
                    template.typeOfDayOff = undefined;
                }
                break;
            case EventDataType.TypeOfDayOff:
                template.typeOfDayOff = this.props.typesOfDayOff!.find(t => t.id === value);
                if (template.typeOfDayOff !== undefined) {
                    template.typeOfDay = undefined;
                }
                break;
            case EventDataType.IgnoreTimeClock:
                template.ignoreTimeClock = value;
                break;
            case EventDataType.Project:
                template.project = this.props.project.find(t => t.id === value);
                break;
            case EventDataType.Poi:
                template.poi = this.props.pois?.data?.find(t => t.id === value);
                break;
            case EventDataType.Department:
                template.department = this.props.departments.data.find(department => department.id === value);
                break;
            case EventDataType.BasePoi:
                template.basePoi = this.props.basePois?.data?.find(t => t.id === value);
                break;
            case EventDataType.Vehicle:
                template.vehicle = this.props.vehicles?.data?.find(t => t.id === value);
                break;
            case EventDataType.Distance:
                template.distance = toFinite(value);
                break;

        }
        this.setState({ template });
    };

    checkBreaktimeFields = () => {
        const { editBreakTime } = this.state;
        return (editBreakTime === undefined || editBreakTime.title === undefined || !editBreakTime.startDate || !editBreakTime.endDate);
    };

    validateBreakTime = () => {
        const { template, editBreakTime } = this.state;
        if (template === undefined) return;
        if (editBreakTime === undefined || editBreakTime.title === undefined || editBreakTime.startDate === undefined || editBreakTime.endDate === undefined) {
            this.setState({ editBreakTimeError: true });
            return false;
        } else {
            const breakTimesTmp: BreakTime = {
                title: editBreakTime.title,
                startDate: editBreakTime.startDate,
                endDate: editBreakTime.endDate,
                isPaid: editBreakTime.isPaid ? editBreakTime.isPaid : false,
                active: true,
                isClocked: false,
                totalHours: roundDecimals(editBreakTime.endDate.diff(editBreakTime.startDate, 'hours', true)),
                totalSeconds: roundDecimals(editBreakTime.endDate.diff(editBreakTime.startDate, 'seconds', true)),
                id: -1,
            };
            if (template.breakTimes === undefined) {
                template.breakTimes = [breakTimesTmp];
            } else {
                template.breakTimes.push(breakTimesTmp);
            }

            this.setState({
                template,
                editBreakTime: {
                    startDate: moment(),
                    endDate: moment().add(10, "minutes"),
                },
                editBreakTimeError: false
            });
            return true;
        }
    };

    validateFiles = async (templateId: number) => {
        if (templateId != null) {
            if (this.state.filesToValidate.length > 0) {
                const flatFilesToValidate = this.state.filesToValidate.map(fTV => fTV.fileId);
                await Network.validateTemplateFiles(templateId, this.state.filesToValidate).then(
                    (response) => {
                        if (response.error === false && response.data) {
                            const actualFilesToValidate = cloneDeep(this.state.filesToValidate);
                            this.setState({ files: response.data, filesToValidate: actualFilesToValidate.filter(fTV => !flatFilesToValidate.includes(fTV.fileId)) });
                        }
                    },
                    () => {
                        showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while validating the files' }), "warning");
                    }
                );
            }
            if (this.state.imagesToValidate.length > 0) {
                const flatImagesToValidate = this.state.imagesToValidate.map(fTV => fTV.fileId);
                Network.validateTemplateFiles(templateId, this.state.imagesToValidate).then(
                    (response) => {
                        if (response.error === false && response.data) {
                            const actualImagesToValidate = cloneDeep(this.state.imagesToValidate);
                            this.setState({ files: response.data, imagesToValidate: actualImagesToValidate.filter(fTV => !flatImagesToValidate.includes(fTV.fileId)) });
                        }
                    },
                    () => {
                        showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while validating the images' }), "warning");
                    }
                );
            }
        }
    };

    genExtra = (index: number) => {
        return (
            <DeleteOutlined
                onClick={() => {
                    const { template } = this.state;
                    if (template === undefined) return;
                    template.breakTimes?.splice(index, 1);
                    this.setState({ template });
                }}
            />
        );
    };

    renderModalContent = () => {
        const { Panel } = Collapse;
        const { template } = this.state;
        const { intl } = this.props;
        const range = template?.startDate && template?.endDate ? [moment(template?.startDate, "DD-MM-YYYY HH:mm"), moment(template?.endDate, "DD-MM-YYYY HH:mm")] : undefined;

        switch (this.state.currentStep) {
            case 0: {
                const colors = this.props.settings.colors?.map(c => <Select.Option label={c.title} value={c.id!} key={`planning-colors-${c.id}`}><span className="flex-row"><p>{c.title}</p><p style={{ fontSize: '30px', color: c.color, display: template?.color?.id === c.id ? 'none' : undefined }}>•</p></span></Select.Option>);
                colors?.unshift(<Select.Option label={intl.formatMessage({ defaultMessage: 'Default' })} value={-1} key={`planning-colors-default`}>{<span className="flex-row"><p><FormattedMessage defaultMessage={'Default'} /></p><p style={{ color: 'var(--primary-color)', fontSize: '30px', display: template?.color ? undefined : 'none' }}>•</p></span>}</Select.Option>);

                return (
                    <Row gutter={[15, 15]}>
                        <Col xs={{ span: 24 }} md={{ span: 24 }}>


                            <div className="display-flex">
                                <Row gutter={[15, 15]}>
                                    <Col xs={{ span: 12 }} md={{ span: 12 }}>
                                        <div className="flex-column">

                                            <Card size="small" title={<FormattedMessage defaultMessage={'Details'} />}>
                                                <div className="planning-modal-section">
                                                    <span className="planning-modal-section-title-template flex">
                                                        <p style={{ marginRight: '100px' }}><FormattedMessage defaultMessage={'Start and end'} /></p>

                                                        <Checkbox onChange={() => this.setState({ allDay: !this.state.allDay }, () => this.dataChanged(EventDataType.Range, range))} checked={this.state.allDay}>
                                                            <FormattedMessage defaultMessage={'All day'} />
                                                        </Checkbox>

                                                    </span>
                                                    <DateTimePicker
                                                        valueFrom={range && range[0]}
                                                        onChangeFrom={this.onChangeStartDate}
                                                        valueTo={range && range[1]}
                                                        onChangeTo={this.onChangeEndDate}
                                                        onBlur={this.onBlur}
                                                        showTime={true}
                                                        type={DateTimePickerType.RANGE}
                                                        version={DateTimePickerVersion.FIELD}
                                                    />
                                                </div>
                                            </Card>
                                            <Card style={{ marginTop: "10px" }} size="small" title={<FormattedMessage defaultMessage={'Informations'} />}>
                                                <div className="planning-modal-section">
                                                    <p className="planning-modal-section-title-first"><FormattedMessage defaultMessage={'Title'} /></p>
                                                    <InputField
                                                        className="planning-template-input"
                                                        value={template?.title}
                                                        placeholder={intl.formatMessage({ defaultMessage: 'Event name' })}
                                                        onChange={(e: InputFieldOnChangeEvent) => this.dataChanged(EventDataType.Title, e)} />
                                                </div>
                                                <div className="planning-modal-section">
                                                    <span className="flex-row planning-modal-section-title-template">
                                                        <FormattedMessage defaultMessage={'Description'} />
                                                        <span>
                                                            <Badge
                                                                offset={[-3, 4]}
                                                                size={'small'}
                                                                style={{ border: 'none', boxShadow: 'none', zIndex: 100000 }}
                                                                count={template?.id ? this.state.files.filter(f => f.res_type === 'IMG').length : this.state.imagesToValidate.filter(fTV => fTV.res_type === 'IMG').length}
                                                            >
                                                                <CircleButton size={'small'} style={{ border: 'none', background: 'none' }} icon={<Anticon icon={<AiOutlinePicture />} />} onClick={() => this.setState({ addImages: true })} />
                                                            </Badge>
                                                            <Badge
                                                                offset={[-3, 4]}
                                                                size={'small'}
                                                                style={{ border: 'none', boxShadow: 'none', zIndex: 100000 }}
                                                                count={template?.id ? this.state.files.filter(f => f.res_type === 'FLE').length : this.state.filesToValidate.filter(fTV => fTV.res_type === 'FLE').length}
                                                            >
                                                                <CircleButton size={'small'} style={{ border: 'none', background: 'none' }} icon={<Anticon icon={<MdAttachFile />} />} onClick={() => this.setState({ addDocuments: true })} />
                                                            </Badge>
                                                        </span>
                                                    </span>

                                                    <InputField
                                                        type="textarea"
                                                        value={template?.description}
                                                        onChange={(e: InputFieldOnChangeEvent) => this.dataChanged(EventDataType.Description, e)}
                                                        autoSize={{ minRows: 2 }} />
                                                </div>
                                            </Card>
                                        </div>
                                    </Col>
                                    <Col xs={{ span: 12 }} md={{ span: 12 }}>
                                        <Card size="small" title={<FormattedMessage defaultMessage={'Criteria'} />}>
                                            <Row gutter={[10, 10]}>
                                                {
                                                    checkRBACRule(Rules.Project.Visit, this.props.currentUser?.role, this.props.currentUser?.company_id) &&
                                                    <>
                                                        <Col xs={{ span: 10 }}>
                                                            <span>{this.props.company?.projectDisplayText ? getCaseAndPlural(this.props.company?.projectDisplayText, false, CaseType.FIRST_LETTER_UPPERCASE) : <FormattedMessage defaultMessage={'Project'} />}</span>
                                                        </Col>
                                                        <Col xs={{ span: 14 }}>
                                                            <Select
                                                                className="planning-modal-input-100"
                                                                showSearch
                                                                allowClear
                                                                disabled={this.props.project === undefined ? true : this.props.project.length <= 0}
                                                                onChange={(id: number) => this.dataChanged(EventDataType.Project, id)}
                                                                value={template?.project?.id}
                                                                filterOption={true}
                                                                optionFilterProp="label">
                                                                {this.props.project?.map(p => <Select.Option label={p.title} value={p.id!} key={`planning-project-${p.id}`}>{p.title}</Select.Option>)}
                                                            </Select>
                                                        </Col>
                                                    </>
                                                }
                                                <Col xs={{ span: 10 }}>
                                                    <span><FormattedMessage defaultMessage={'Type of day'} /></span>
                                                </Col>
                                                <Col xs={{ span: 14 }}>
                                                    <Select
                                                        className="planning-modal-input-100"
                                                        showSearch
                                                        disabled={this.props.typesOfDay === undefined ? true : this.props.typesOfDay.length <= 0}
                                                        allowClear
                                                        onChange={(id: number) => this.dataChanged(EventDataType.TypeOfDay, id)}
                                                        value={template?.typeOfDay?.id}
                                                        filterOption={true}
                                                        optionFilterProp="label">
                                                        {this.props.typesOfDay?.map(t => <Select.Option label={t.title} value={t.id!} key={`planning-tod-${t.id}`}>{t.title}</Select.Option>)}
                                                    </Select>
                                                </Col>
                                                <Col xs={{ span: 10 }}>
                                                    <span><FormattedMessage defaultMessage={'Type of vacation'} /></span>
                                                </Col>
                                                <Col xs={{ span: 14 }}>
                                                    <Select
                                                        className="planning-modal-input-100"
                                                        showSearch
                                                        allowClear
                                                        disabled={this.props.typesOfDayOff === undefined ? true : this.props.typesOfDayOff.length <= 0}
                                                        onChange={(id: number) => this.dataChanged(EventDataType.TypeOfDayOff, id)}
                                                        value={template?.typeOfDayOff?.id}
                                                        filterOption={true}
                                                        optionFilterProp="label">
                                                        {this.props.typesOfDayOff?.map(t => <Select.Option label={t.title} value={t.id!} key={`planning-tod-${t.id}`}>{t.title}</Select.Option>)}
                                                    </Select>
                                                </Col>
                                                <Col xs={{ span: 10 }}>
                                                    <span><FormattedMessage defaultMessage={'Ability'} /></span>
                                                </Col>
                                                <Col xs={{ span: 14 }}>
                                                    <Select
                                                        className="planning-modal-input-100"
                                                        showSearch
                                                        allowClear
                                                        disabled={this.state.staffTypes === undefined ? true : this.state.staffTypes.length <= 0}
                                                        onChange={(id: number) => this.dataChanged(EventDataType.StaffType, id)}
                                                        value={template?.staffType?.id}
                                                        filterOption={true}
                                                        optionFilterProp="label">
                                                        {this.state.staffTypes?.map(t => <Select.Option label={t.name} value={t.id!} key={`planning-tod-${t.id}`}>{t.name}</Select.Option>)}
                                                    </Select>
                                                </Col>
                                                {
                                                    checkRBACRule(Rules.CourseManagement.Visit, this.props.currentUser?.role, this.props.currentUser?.company_id) &&
                                                    <>
                                                        <Col xs={{ span: 10 }}>
                                                            <span><FormattedMessage defaultMessage={'Workplace'} /></span>
                                                        </Col>
                                                        <Col xs={{ span: 14 }}>
                                                            <Select
                                                                className="planning-modal-input-100"
                                                                showSearch
                                                                allowClear
                                                                disabled={this.props.pois === undefined ? true : this.props.pois.data === undefined ? true : this.props.pois.data.length <= 0}
                                                                onChange={(id: number) => this.dataChanged(EventDataType.Poi, id)}
                                                                value={template?.poi?.id}
                                                                filterOption={true}
                                                                optionFilterProp="label">
                                                                {this.props.pois?.data?.map(t => <Select.Option label={t.title} value={t.id!} key={`planning-tod-${t.id}`}>{t.title}</Select.Option>)}
                                                            </Select>
                                                        </Col>
                                                    </>
                                                }
                                            </Row>
                                        </Card>
                                        <Card style={{ marginTop: "10px" }} size="small" title={<FormattedMessage defaultMessage={'Extra'} />}>
                                            <Row gutter={[10, 10]}>
                                                {
                                                    checkRBACRule(Rules.Project.Visit, this.props.currentUser?.role, this.props.currentUser?.company_id) &&
                                                    <>
                                                        <Col xs={{ span: 10 }}>
                                                            <span>{this.props.company?.projectDisplayText ? getCaseAndPlural(this.props.company?.projectDisplayText, false, CaseType.FIRST_LETTER_UPPERCASE) : <FormattedMessage defaultMessage={'Project'} />}</span>
                                                        </Col>
                                                        <Col xs={{ span: 14 }}>
                                                            <Select
                                                                className="planning-modal-input-100"
                                                                showSearch
                                                                allowClear
                                                                disabled={this.props.project === undefined ? true : this.props.project.length <= 0}
                                                                onChange={(id: number) => this.dataChanged(EventDataType.Project, id)}
                                                                value={template?.project?.id}
                                                                filterOption={true}
                                                                optionFilterProp="label">
                                                                {this.props.project?.map(p => <Select.Option label={p.title} value={p.id!} key={`planning-project-${p.id}`}>{p.title}</Select.Option>)}
                                                            </Select>
                                                        </Col>
                                                    </>
                                                }
                                                {
                                                    this.props.departments.updated && this.props.departments.data.length > 0 ?
                                                        <>
                                                            <Col xs={{ span: 10 }}>
                                                                <span><FormattedMessage defaultMessage={'Department'} /></span>
                                                            </Col>
                                                            <Col xs={{ span: 14 }}>
                                                                <Select
                                                                    className="team-create-user-inputs"
                                                                    showSearch
                                                                    allowClear
                                                                    onChange={(e: number) => this.dataChanged(EventDataType.Department, e)}
                                                                    placeholder={<FormattedMessage defaultMessage={'Department'} />}
                                                                    value={template?.department?.id}
                                                                    filterOption={true}
                                                                    optionFilterProp="label">
                                                                    {this.props.departments.data.map(t => <Select.Option label={t.name} value={t.id!} key={`planning-department-${t.id}`}>{t.name}</Select.Option>)}
                                                                </Select>
                                                            </Col>
                                                        </>
                                                        : null
                                                }
                                                {
                                                    this.props.company?.isTimeClockEnabled ?
                                                        <>
                                                            <Col xs={{ span: 20 }}>
                                                                <span><FormattedMessage defaultMessage={'Disable timeclock'} /></span>
                                                            </Col>
                                                            <Col xs={{ span: 4 }}>
                                                                <Switch
                                                                    onChange={(checked) => this.dataChanged(EventDataType.IgnoreTimeClock, checked)}
                                                                    checked={template?.ignoreTimeClock}
                                                                    checkedChildren={<CheckOutlined />}
                                                                    unCheckedChildren={<CloseOutlined />}
                                                                />
                                                            </Col>
                                                        </>
                                                        : null
                                                }
                                                <Divider style={{ margin: 0 }} type={'horizontal'} />
                                                <Col xs={{ span: 24 }}>
                                                    {
                                                        this.props.settings && this.props.settings.colors &&
                                                        <>
                                                            <div className="flex-row">
                                                                <span><FormattedMessage defaultMessage={'Color'} /></span>
                                                                <div style={{ marginRight: '5px', height: '20px', width: '30px', borderRadius: '5px', backgroundColor: template?.color?.color ? template?.color?.color : 'var(--primary-color)' }} />
                                                            </div>
                                                            <Select
                                                                className="event-modal-data-entry"
                                                                showSearch
                                                                allowClear
                                                                onChange={(id: number) => this.dataChanged(EventDataType.Color, id)}
                                                                value={template?.color?.id ? template?.color?.id as number : -1}
                                                                filterOption={true}
                                                                optionFilterProp="label">
                                                                {colors}
                                                            </Select>
                                                        </>
                                                    }
                                                </Col>
                                            </Row>
                                        </Card>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                );
            }
            case 1:
                return (
                    <Row gutter={[16, 16]}>
                        <Col xs={{ span: 24 }} md={{ span: 12 }}>
                            <Card size="small"
                                title={<FormattedMessage defaultMessage={'Breaks ({count})'} values={{ count: template?.breakTimes?.length || 0 }} />}
                                extra={[
                                    <CircleButton
                                        key={`templatemodal-breaktime-action-add`}
                                        icon={<PlusOutlined />}
                                        title={intl.formatMessage({ defaultMessage: 'Add a break' })}
                                        onClick={() => this.setState({
                                            addBreaktime: true, editBreakTime: {
                                                startDate: undefined,
                                                endDate: undefined,
                                            }
                                        })}
                                        small
                                    />
                                ]}>
                                <Row gutter={[10, 10]}>
                                    <Col xs={{ span: 24 }}>
                                        {
                                            template && template.breakTimes && template.breakTimes.length > 0 ?
                                                <Collapse accordion={true}>
                                                    {
                                                        template.breakTimes.map((bt, index) => {
                                                            return (
                                                                <Panel header={bt.title} key={index + 1} extra={this.genExtra(index)}>
                                                                    <p><FormattedMessage defaultMessage={'Start'} />{':'} {bt.startDate.format(getFormat('DATE_AND_TIME_SHORT'))}</p>
                                                                    <p><FormattedMessage defaultMessage={'End'} />{':'} {bt.endDate.format(getFormat('DATE_AND_TIME_SHORT'))}</p>
                                                                    <p><FormattedMessage defaultMessage={'Duration: {hours} ({totalHours}h)'} values={{ hours: momentDurationFormat(moment.duration(bt.totalHours, "hours")), totalHours: bt.totalHours }} /></p>
                                                                    <p>{bt.isPaid ? <FormattedMessage defaultMessage={"At the employer's expense"} /> : <FormattedMessage defaultMessage={"At employee's expense"} />}</p>
                                                                </Panel>
                                                            );
                                                        })
                                                    }
                                                </Collapse>
                                                :
                                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<FormattedMessage defaultMessage={'No planned breaks'} />} />
                                        }
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col xs={{ span: 24 }} md={{ span: 12 }}>
                            <Card size="small" title={<FormattedMessage defaultMessage={'General'} />}>
                                <Checkbox
                                    checked={template?.sendMessage === undefined ? false : template.sendMessage}
                                    onChange={(e) => this.dataChanged(EventDataType.SendMessage, e)}>
                                    <FormattedMessage defaultMessage={'Send by message'} />
                                </Checkbox>
                                <Tooltip overlay={<FormattedMessage defaultMessage={'Send a notification message to the users concerned'} />} placement="right">
                                    <InfoCircleOutlined style={{ marginLeft: '10px', cursor: 'pointer' }} />
                                </Tooltip>
                                <div style={{ marginTop: 10 }}>
                                    {
                                        template && template.reminders && template.reminders.length > 0 ?
                                            <Collapse className='__collapse-background-transparent reminder-collapse' bordered={true} accordion={true}>
                                                {
                                                    template.reminders.sort((a, b) => a.minBeforeEventStart > b.minBeforeEventStart ? 1 : -1).map((reminder, index) => {
                                                        const user = this.props.users.find(u => u.id === reminder.sentTo);
                                                        const selectedDurationType = automaticDurationTypeSelection(reminder.minBeforeEventStart);
                                                        return (
                                                            <Panel header={<FormattedMessage defaultMessage={'Reminder: {time} {type}'} values={{ time: reminder.minBeforeEventStart / selectedDurationType.lengthInMin, type: (reminder.minBeforeEventStart / selectedDurationType.lengthInMin) <= 1 ? intl.formatMessage(selectedDurationType.name).toLowerCase() : intl.formatMessage(selectedDurationType.pluralName).toLowerCase() }} />} key={index + 1} extra={[
                                                                <SpaceContent key={`space-reminder-button-${reminder.id}`}>
                                                                    <CircleButton onClick={(e) => { e.stopPropagation(); this.setState({ addReminder: true, editReminder: reminder }); }} disabled={reminder.retreived || reminder.sent} small key={`edit-reminder-button-${reminder.id}`} icon={<EditOutlined />} title={intl.formatMessage({ defaultMessage: 'Edit reminder' })} />
                                                                    <DeleteButton
                                                                        disabled={reminder.retreived || reminder.sent}
                                                                        small
                                                                        key={`remove-reminder-button-${reminder.id}`}
                                                                        title={intl.formatMessage({ defaultMessage: 'Remove reminder' })}
                                                                        text={<FormattedMessage defaultMessage={'Do you want to delete this reminder?'} />}
                                                                        onConfirm={e => {
                                                                            e?.stopPropagation();
                                                                            this.removeEventReminder(reminder);
                                                                        }}
                                                                        placement="left"
                                                                    />
                                                                </SpaceContent>
                                                            ]}>
                                                                <p>
                                                                    <FormattedMessage defaultMessage={'Send a reminder {time} {type} before the event'} values={{ time: reminder.minBeforeEventStart / selectedDurationType.lengthInMin, type: (reminder.minBeforeEventStart / selectedDurationType.lengthInMin) <= 1 ? intl.formatMessage(selectedDurationType.name).toLowerCase() : intl.formatMessage(selectedDurationType.pluralName).toLowerCase() }} />
                                                                </p>
                                                                {(reminder.retreived && !reminder.sent) && <p><FormattedMessage defaultMessage={'The reminder is being sent'} /><div className='dots-loader' /></p>}
                                                                {reminder.sent && <p><FormattedMessage defaultMessage={'Reminder was sent at {time} {user}'} values={{ time: reminder.modified?.format(getFormat('DATE_LONG_AND_ON_TIME_LONG')), user: user && intl.formatMessage({ defaultMessage: 'to user {user}' }, { user: `${user?.first_name} ${user?.last_name}` }) }} /></p>}
                                                            </Panel>
                                                        );
                                                    })
                                                }
                                                <Panel className='__collapse-main __collapse-normal' collapsible={'icon'} showArrow={false} header={<div onClick={(e) => { e.stopPropagation(); this.setState((state) => ({ addReminder: true, newReminderIndex: state.newReminderIndex - 1, editReminder: { id: state.newReminderIndex, minBeforeEventStart: 5, retreived: false, sent: false, created: moment() } })); }} style={{ cursor: 'pointer', display: 'flex', justifyContent: 'center', gap: 8, alignItems: 'center' }}><PlusCircleOutlined /><FormattedMessage defaultMessage={'Add a reminder'} /></div>} key={`colapse-reminder-button`} />

                                            </Collapse>
                                            :
                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <span><FormattedMessage defaultMessage={'No scheduled reminder'} /></span>
                                                <CircleButton onClick={() => this.setState((state) => ({ addReminder: true, newReminderIndex: state.newReminderIndex - 1, editReminder: { id: state.newReminderIndex, minBeforeEventStart: 5, retreived: false, sent: false, created: moment() } }))} size='small' icon={<PlusOutlined />} title={`Ajouter un rappel`} />
                                            </div>
                                    }
                                </div>
                            </Card>
                            {
                                checkRBACRule(Rules.CourseManagement.Visit, this.props.currentUser?.role, this.props.currentUser?.company_id) ?
                                    <Card style={{ marginTop: "10px" }} size="small" title={<FormattedMessage defaultMessage={'Travel & Vehicle'} />}>
                                        <Row gutter={[10, 10]}>
                                            <Col xs={{ span: 10 }}>
                                                <span><FormattedMessage defaultMessage={'Departure point'} /></span>
                                            </Col>
                                            <Col xs={{ span: 14 }}>
                                                <Select
                                                    className="planning-modal-input-100"
                                                    showSearch
                                                    allowClear
                                                    onChange={(id: number) => this.dataChanged(EventDataType.BasePoi, id)}
                                                    value={template?.basePoi?.id}
                                                    filterOption={true}
                                                    optionFilterProp="label">
                                                    {this.props.basePois?.data?.map(t => <Select.Option label={t.title} value={t.id!} key={`planning-tod-${t.id}`}>{t.title}</Select.Option>)}
                                                </Select>
                                            </Col>

                                            <Col xs={{ span: 10 }}>
                                                <span title={intl.formatMessage({ defaultMessage: 'Distance in km' })}><FormattedMessage defaultMessage={'Distance'} /></span>
                                                <Tooltip title={<FormattedMessage defaultMessage={'Total distance of the outward journey, in Km'} />}>
                                                    <InfoCircleOutlined className="tab-tooltip-info" />
                                                </Tooltip>
                                            </Col>
                                            <Col xs={{ span: 14 }}>
                                                <InputNumber
                                                    min={0}
                                                    max={1000}
                                                    id="planning-distance-input"
                                                    className="planning-modal-input"
                                                    value={template?.distance}
                                                    placeholder={intl.formatMessage({ defaultMessage: 'Distance in km' })}
                                                    onChange={e => this.dataChanged(EventDataType.Distance, e)} />
                                            </Col>
                                            <Col xs={{ span: 10 }}>
                                                <span><FormattedMessage defaultMessage={'Vehicles'} /></span>
                                            </Col>
                                            <Col xs={{ span: 14 }}>
                                                <Select
                                                    className="planning-modal-input-100"
                                                    showSearch
                                                    allowClear
                                                    onChange={(id: number) => this.dataChanged(EventDataType.Vehicle, id)}
                                                    value={template?.vehicle?.id}
                                                    filterOption={true}
                                                    optionFilterProp="label">
                                                    {this.props.vehicles?.data?.map(t => <Select.Option label={t.name} value={t.id!} key={`planning-tod-${t.id}`}>{t.name}</Select.Option>)}
                                                </Select>
                                            </Col>
                                        </Row>
                                    </Card>
                                    : null
                            }

                        </Col>

                    </Row>
                );
        }
    };

    render() {
        const { editBreakTime, template, loading, loadingTemplate, currentStep, addBreaktime, editBreakTimeError, addDocuments, addImages, files, filesToValidate, imagesToValidate, addReminder, editReminder } = this.state;
        const { isEdit, intl } = this.props;
        return (
            <Modal
                className="planning-modal"
                open={this.props.visible}
                title={isEdit ? <FormattedMessage defaultMessage={'Edit template'} /> : <FormattedMessage defaultMessage={'Add a template'} />}
                width="880px"
                onCancel={this.props.onClose}
                destroyOnClose={true}
                footer={[
                    <Button type="dashed" onClick={this.props.onClose} key="template-modal-cancel-button">
                        <FormattedMessage defaultMessage={'Cancel'} />
                    </Button>,
                    <div key="template-next-previous-buttons">
                        <Button disabled={currentStep === 0} onClick={() => this.setState({ currentStep: currentStep - 1 })}>
                            <LeftOutlined />
                            <span className="modal-button-next-previous-title"><FormattedMessage defaultMessage={'Previous'} /></span>
                        </Button>
                        <Button disabled={currentStep === 1} onClick={() => this.setState({ currentStep: currentStep + 1 })}>
                            <span className="modal-button-next-previous-title"><FormattedMessage defaultMessage={'Next'} /></span>
                            <RightOutlined />
                        </Button>
                    </div>,
                    <Button id="template-modal-ok-button" type="primary" onClick={this.onDone} loading={loading} key="planning-modal-button-ok">
                        {isEdit ? <FormattedMessage defaultMessage={'Save'} /> : <FormattedMessage defaultMessage={'Create'} />}
                    </Button>
                ]}
            >
                <Steps
                    type="navigation"
                    size="small"
                    current={currentStep}
                    onChange={(current) => this.setState({ currentStep: current })}
                    style={{ marginBottom: '20px', boxShadow: '0px -1px 0 0 #e8e8e8 inset' }}>
                    <Steps.Step title={<FormattedMessage defaultMessage={'General'} />} key="planning-modal-step-0" />
                    <Steps.Step title={<FormattedMessage defaultMessage={'Options'} />} key="planning-modal-step-1" />
                </Steps>
                <Spin size='large' spinning={loadingTemplate || template === undefined}>
                    {this.renderModalContent()}
                </Spin>
                <Modal
                    width="400px"
                    style={{ top: "150px" }}
                    title={editBreakTime.id ? <FormattedMessage defaultMessage={'Edit break: {name}'} values={{ name: editBreakTime.title }} /> : <FormattedMessage defaultMessage={'Edit break'} />}
                    open={addBreaktime}
                    onCancel={() => this.setState({ addBreaktime: false })}
                    destroyOnClose={true}
                    footer={[
                        <div key={`templatemodal-add-breaktime-action`} style={{ display: 'flex', justifyContent: editBreakTime.id === undefined ? 'space-between' : 'flex-end' }}>
                            {
                                editBreakTime.id === undefined ?
                                    <Button title={intl.formatMessage({ defaultMessage: 'Save the break and add another' })} type="default" onClick={this.validateBreakTime} disabled={editBreakTimeError || this.checkBreaktimeFields()} key="breaktime-modal-button-ok">
                                        <FormattedMessage defaultMessage={'Add and continue'} />
                                    </Button>
                                    : null
                            }
                            <Button type="primary" onClick={() => { this.validateBreakTime(); this.setState({ addBreaktime: false }); }} disabled={editBreakTimeError || this.checkBreaktimeFields()} key="breaktime-modal-button-ok">
                                {editBreakTime.id ? <FormattedMessage defaultMessage={'Edit'} /> : <FormattedMessage defaultMessage={'Save and close'} />}
                            </Button>
                        </div>
                    ]}>
                    <Row gutter={[10, 10]}>
                        <Col xs={{ span: 24 }}>
                            <InputField
                                className={editBreakTimeError === true && editBreakTime.title === undefined ? "planning-modal-input-error" : ""}
                                value={editBreakTime.title}
                                placeholder={intl.formatMessage({ defaultMessage: 'Title' })}
                                onChange={(e: InputFieldOnChangeEvent) => {
                                    editBreakTime.title = e.target.value;
                                    editBreakTime.title === "" && (editBreakTime.title = undefined);
                                    this.setState({ editBreakTime });
                                }} />
                        </Col>
                        <Col xs={{ span: 24 }}>
                            <DateTimePicker
                                valueFrom={editBreakTime.startDate}
                                minFrom={template?.startDate.clone()}
                                maxFrom={template?.endDate.clone()}
                                onChangeFrom={(e) => {
                                    if (e) editBreakTime.startDate = e;
                                    this.setState({ editBreakTime });
                                }}
                                placeHolderDateFrom={intl.formatMessage({ defaultMessage: 'Start date' })}
                                placeHolderTimeFrom={intl.formatMessage({ defaultMessage: 'Start hour' })}
                                formatTimeFrom={getFormat('TIME_SHORT')}
                                timeViewFrom={["hours", "minutes"]}
                                disableDateFrom={(date) => date.isBefore(template?.startDate, 'days') || date.isAfter(template?.endDate, 'days')}

                                valueTo={editBreakTime.endDate}
                                minTo={template?.startDate.clone()}
                                maxTo={template?.endDate.clone()}
                                onChangeTo={(e) => {
                                    if (e) editBreakTime.endDate = e;
                                    this.setState({ editBreakTime });
                                }}

                                placeHolderDateTo={intl.formatMessage({ defaultMessage: 'End date' })}
                                placeHolderTimeTo={intl.formatMessage({ defaultMessage: 'End hour' })}
                                formatTimeTo={getFormat('TIME_SHORT')}
                                timeViewTo={["hours", "minutes"]}
                                disableDateTo={(date) => date.isBefore(template?.startDate, 'days') || date.isAfter(template?.endDate, 'days')}

                                showTime={true}
                                type={DateTimePickerType.RANGE}
                                version={DateTimePickerVersion.FIELD}
                                setError={(error) => this.setState({ editBreakTimeError: error })}
                            />
                        </Col>
                        <Col xs={{ span: 24 }}>
                            <Checkbox
                                checked={editBreakTime.isPaid}
                                onChange={(e) => {
                                    editBreakTime.isPaid = e.target.checked;
                                    this.setState({ editBreakTime });
                                }}>
                                <FormattedMessage defaultMessage={"At the employer's expense (paid break)"} />
                            </Checkbox>
                            <Tooltip overlay={<FormattedMessage defaultMessage={'If the break is paid for by the employer, it will not be deducted from the duration of the event'} />} placement="right">
                                <InfoCircleOutlined className="tab-tooltip-info" />
                            </Tooltip>
                        </Col>
                    </Row>
                </Modal>
                <UploadModal
                    templateId={template?.id ?? null}
                    type={addDocuments ? 'FLE' : addImages ? 'IMG' : 'VDO'}
                    title={addDocuments ? intl.formatMessage({ defaultMessage: 'Add documents' }) : addImages ? intl.formatMessage({ defaultMessage: 'Add images' }) : undefined}
                    onClose={(cancel?: boolean) => this.setState((state) => ({ addDocuments: false, addImages: false, filesToValidate: cancel ? [] : state.filesToValidate, imagesToValidate: cancel ? [] : state.imagesToValidate }))}
                    open={addDocuments || addImages}
                    files={files}
                    addFiles={(files) => this.setState({ files: files })}
                    filesToValidate={addDocuments ? filesToValidate : addImages ? imagesToValidate : []}
                    setFilesToValidate={(newFilesToValidate: ValidateFile[]) => {
                        if (addDocuments) {
                            this.setState({ filesToValidate: newFilesToValidate.filter(f => f.type === 'ValidateFile' && f.res_type === 'FLE') });
                        } else if (addImages) {
                            this.setState({ imagesToValidate: newFilesToValidate.filter(f => f.type === 'ValidateFile' && f.res_type === 'IMG') });
                        }
                    }}
                />
                {
                    template ?
                        <ReminderModal
                            event={template}
                            onClose={() => this.setState({ addReminder: false, editReminder: undefined })}
                            open={addReminder}
                            reminder={editReminder}
                            onSave={(reminder: Reminder) => this.saveTemplateReminder(reminder)}
                            noErrors
                        />
                        : null
                }
            </Modal>
        );
    }
}

const mapDispatchToProps = (dispatch: StoreDispatch) => ({
    changeTemplates: (t: PlanningTemplate[]) => dispatch(changeTemplates(t)),
    updateTemplates: (t: PlanningTemplate[]) => dispatch(updateTemplates(t)),
    loadTypesOfVehicle: (fr?: boolean) => dispatch(loadTypesOfVehicle(fr)),
    loadPois: (fr?: boolean) => dispatch(loadPois(fr)),
    loadDepartments: (fr?: boolean) => dispatch(loadDepartments(fr)),

});

const mapStateToProps = (state: ApplicationState) => ({
    company: state.user.company,
    settings: state.planning.settings,
    typesOfDay: state.configurations.typesOfDay,
    typesOfDayOff: state.configurations.typesOfDayOff,
    project: state.configurations.project,
    vehicles: state.configurations.vehicles,
    pois: state.location.pois,
    basePois: state.location.basePois,
    currentUser: state.user.currentUser,
    departments: state.configurations.departments,
    users: state.teamManagement.users,
});
const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(injectIntl(TemplateModal));