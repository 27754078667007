import { Button, Modal, Tooltip } from 'antd';
import moment from 'moment';
import { useCallback, useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import getFormat from '../../../../utils/Lang';
import { DictionaryNumber, DictionaryString, PlanningUserPerf } from '../../../../utils/types/generalTypes';
import FAIcon from '../../../common/FAIcon';

interface Props {
    selectedEvents: DictionaryString<{ eventId: number; userId: number; }[]>;
    usersEvents: DictionaryNumber<PlanningUserPerf>;
    onClose: () => void;
    onDelete: () => void;
    opened: boolean;
    isLoading: boolean;
    setIsLoading: (value: boolean) => void;
}

const DeleteEventsModal = (props: Props) => {
    const { opened } = props;

    const onSubmit = useCallback(() => {
        props.onDelete();
    }, [props]);

    useEffect(() => {
        if (!opened) {
            props.setIsLoading(false);
        }
    }, [opened, props]);

    const eventsToDisplay = useMemo(() => {
        const events: DictionaryNumber<{ username: string; events: { id: number; title: string; date: string; locked: boolean; }[]; }> = {};
        for (const day in props.selectedEvents) {
            const dayMomentFormatted = moment(day, "YYYYMMDD").format(getFormat('DATE_SHORT'));
            for (const event of props.selectedEvents[day]) {
                const usersEvents = props.usersEvents[event.userId];
                const dayEvents = usersEvents.days[day].events;
                const eventToDelete = dayEvents.find(e => e.id === event.eventId);
                if (eventToDelete) {
                    {
                        const eventToPush = {
                            id: eventToDelete.id,
                            date: dayMomentFormatted,
                            title: eventToDelete.title,
                            locked: eventToDelete.eventLocked
                        };

                        if (event.userId in events)
                            events[event.userId].events.push(eventToPush);
                        else
                            events[event.userId] = {
                                username: usersEvents.fullName,
                                events: [{
                                    id: eventToDelete.id,
                                    date: dayMomentFormatted,
                                    title: eventToDelete.title,
                                    locked: eventToDelete.eventLocked
                                }]
                            };
                    }
                }
            }
        }

        return events;
    }, [props]);

    return (
        <Modal
            closable={true}
            title={<FormattedMessage defaultMessage={'Do you want to delete the events permanently?'} />}
            open={opened}
            onCancel={props.onClose}
            destroyOnClose={true}
            width={550}
            footer={[
                <Button key={`monthlyPlanningv3-delete-cancel`} disabled={props.isLoading} type="dashed" onClick={props.onClose}>
                    <FormattedMessage defaultMessage={'Cancel'} />
                </Button>,
                <Button key={`monthlyPlanningv3-delete-confirm`} loading={props.isLoading} type="primary" onClick={onSubmit}>
                    <FormattedMessage defaultMessage={'Delete'} />
                </Button>

            ]}>

            {Object.values(eventsToDisplay).map((u) => <div key={u.username}>
                {u.username}
                <ul>
                    {
                        u.events.map(e => (
                            <li key={`selected-to-delete${e.id}`}>
                                {
                                    e.locked &&
                                    <Tooltip title={<FormattedMessage defaultMessage={'This event is locked and will not be treated during the deletion'} />}>
                                        <FAIcon name='lock' prefix='fas' className='monthlyPlanningv3-delete-lock' />
                                    </Tooltip>
                                }
                                {e.title} - {e.date}
                            </li>
                        )
                        )
                    }
                </ul>
            </div>)}
        </Modal>
    );
};

export default DeleteEventsModal;