import { CHANGE_CURRENT_COMPANY, GET_CURRENT_USER_DETAILS_FINISHED, GET_CURRENT_USER_DETAILS_STARTED, RESET, TOGGLE_LOADING } from '../reducer/user'

import { ThunkAction } from 'redux-thunk'
import Network from '../../utils/network'
import { Company, User } from '../../utils/types/generalTypes'
import { StoreAction, UserState } from '../../utils/types/storeTypes'

export type Effect = ThunkAction<any, UserState, any, StoreAction>

/**
 * Get detail of current connected user
 */
export const getCurrentUser = (): Effect => (dispatch) => {
    dispatch({
        type: GET_CURRENT_USER_DETAILS_STARTED,
    })

    Network.getCurrentUser().then(
        (response) => {
            dispatch({
                type: GET_CURRENT_USER_DETAILS_FINISHED,
                data: response,
            })
        },
        (error) => {
            console.error(error)
            dispatch({
                type: GET_CURRENT_USER_DETAILS_FINISHED,
                data: {},
            })
        }
    )
}

/**
 * Change the current stored user
 * @param user the user's details
 */
export const changeUserDetails = (user: User): StoreAction => ({ type: GET_CURRENT_USER_DETAILS_FINISHED, data: user })

/**
 * Change the current stored company
 * @param company the company
 */
export const changeCompany = (company: Company): StoreAction => ({ type: CHANGE_CURRENT_COMPANY, data: company })

/**
 * Toggle the stored loading property
 * @param state the new state
 * @returns a store action
 */
export const toggleLoading = (state: boolean): StoreAction => ({ type: TOGGLE_LOADING, data: state })
export const reset = () => ({ type: RESET })
