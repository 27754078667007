import { Button } from 'antd';
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js';
import moment from 'moment';
import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import getFormat from '../../../utils/Lang';
import FAIcon from '../FAIcon';

ChartJS.register(ArcElement, Tooltip, Legend);

interface Props {
    title: ReactNode;
    percentage: number;
    lastSync?: string;
    onClick: () => void;
}

const IntegrationSync = (props: Props) => {
    return (
        <Button className={`integration-button${props.percentage !== 100 ? ' loading' : ''}`} style={{ height: '60px' }} size={'large'} type={'primary'} onClick={props.onClick}>
            <div className='integration-button-loading'  style={{width: `${props.percentage}%`, visibility: props.percentage === 100 ? 'hidden' : 'visible'}} />
            <div className='integration-button-loading-icon' style={{visibility: props.percentage === 100 ? 'hidden' : 'visible'}}><FAIcon prefix='fas' name='spinner-third' spin /></div>
            <div className='integration-button-content'>
                <div className='integration-button-title'>{props.title}</div>
                <div className='integration-button-sync' style={{textAlign: 'start'}}>{props.lastSync ? moment(props.lastSync).format(getFormat('DATE_AND_ON_TIME')) : <FormattedMessage defaultMessage={'Never synced'} />}</div>
            </div>
        </Button>
    );
};

export default IntegrationSync;
