
import { faBoxArchive, faRotate, faRotateLeft, faScreenUsers } from '@fortawesome/pro-light-svg-icons';
import { faArrowRight, faBroomWide, faCalendar, faCheck, faChevronLeft, faChevronRight, faCircleCheck, faCirclePlus, faCircleQuestion, faCirclesOverlap, faCircleXmark, faClock, faFlag, faFloppyDisk, faFolderClosed, faFolderOpen, faFolderTree, faHashtag, faHourglassClock, faInfoCircle, faLink, faLinkSlash, faMessageLines, faMug, faPuzzlePieceSimple, faEmptySet as farEmptySet, faGrid2Plus as farGrid2Plus, faRightFromBracket, faLinkSlash as farLinkSlash, faTriangleExclamation as farTriangleExclamation, faUser as farUser, faSackDollar, faUserGear, faUserSlash, faUserTieHair } from '@fortawesome/pro-regular-svg-icons';
import { faArrowUpRightDots, faCaretLeft, faCaretRight, faCircleInfo, faClone, faFileContract, faGlobeStand, faGridHorizontal, faHotel, faLock, faPlus, faRotateReverse, faEmptySet as fasEmptySet, faGrid2Plus as fasGrid2Plus, faShield, faLinkSlash as fasLinkSlash, faMug as faSolidMug, faSpinnerThird, faTriangleExclamation as fasTriangleExclamation, faUser as fasUser, faUsers, faXmark } from '@fortawesome/pro-solid-svg-icons';
import { faHorizontalRule } from '@fortawesome/pro-thin-svg-icons';
import FA from './fontawesome';
import { IconPacks } from './IconPacks';


/**
 * Pack used for the sunkhronos library
 */
export const sunkhronosPack = FA.createIconPack(
    IconPacks.Sunkhronos,
    faHorizontalRule,
    faSackDollar,
    faArrowRight,
    faCirclePlus,
    faMessageLines,
    faSolidMug,
    faMug,
    faClock,
    faCircleCheck,
    faHourglassClock,
    fasTriangleExclamation,
    faRightFromBracket,
    faCircleQuestion,
    faBoxArchive,
    faXmark,
    faInfoCircle,
    faUsers,
    faCircleXmark,
    farTriangleExclamation,
    faClone,
    fasEmptySet,
    faBroomWide,
    faShield,
    faLinkSlash,
    faFloppyDisk,
    faCaretRight,
    faCaretLeft,
    faCircleInfo,
    faRotateLeft,
    faFileContract,
    fasUser,
    faCalendar,
    faInfoCircle,
    faScreenUsers,
    farLinkSlash,
    fasLinkSlash,
    faLink,
    farUser,
    faUsers,
    fasGrid2Plus,
    faRotateReverse,
    faGridHorizontal,
    faHotel,
    faGlobeStand,
    faArrowUpRightDots,
    faLock,
    faPuzzlePieceSimple,
    faSpinnerThird,
    faUserTieHair,
    faFolderTree,
    faCirclePlus,
    faFlag,
    faFolderClosed,
    faFolderOpen,
    faCircleXmark,
    faRotate,
    faChevronRight,
    faChevronLeft,
    faPlus,
    faUserSlash,
    faCirclesOverlap,
    faUserGear,
    faHashtag,
    faCheck,
    farEmptySet,
    farGrid2Plus
);