import { CheckCircleOutlined, CloseCircleOutlined, DeleteOutlined, FileOutlined, LoadingOutlined, QuestionCircleOutlined, UserOutlined } from '@ant-design/icons';
import { Alert, Avatar, Button, Card, Col, Collapse, Comment, Divider, Empty, message, Modal, Popconfirm, Popover, Row, Spin, Tag, Tooltip } from 'antd';
import cloneDeep from 'lodash/cloneDeep';
import toInteger from 'lodash/toInteger';
import moment, { Moment } from 'moment';
import React from 'react';
import isEqual from 'react-fast-compare';
import { MdMoreTime } from 'react-icons/md';
import { TbClockEdit, TbClockShield } from 'react-icons/tb';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ByWeekday, Frequency } from 'rrule';
import { Privileges } from '../../privileges';
import { Rules } from '../../rbacRules';
import { changeUsers } from '../../store/actions/teamManagement';
import '../../styles/eventModal.css';
import { BLUE_COLOR, BreaktimeCalculatedTypes, CaseType, MOMENT_FORMAT_DISPLAY_LONG, MOMENT_SHORT_TIME_FORMAT, MOMENT_TIME_FORMAT, OvertimesCalculatedStatus, OvertimesCalculatedTypes, YELLOW_COLOR } from '../../utils/constants';
import { EventFrequences } from '../../utils/enumerations';
import getFormat from '../../utils/Lang';
import Network from '../../utils/network';
import { UserEventsData } from '../../utils/objects/cct/userEventsData';
import { EventClockedDates, EventClockedSmall, GenericFile, Group, RouterProps, User } from '../../utils/types/generalTypes';
import { NetworkEventClockedSetAdmin, NetworkEventClockedSetManually, NetworkEventConfirmed, NetworkEventImage } from '../../utils/types/networkTypes';
import { BreakTime, PlanningEvent, PlanningOvertime, PlanningTemplate } from '../../utils/types/planningTypes';
import { SimpleEvent } from '../../utils/types/reportTypes';
import { ApplicationState, StoreDispatch } from '../../utils/types/storeTypes';
import { checkPrivilege, checkRBACRule, convertPlanningEventToSimpleEvent, getCaseAndPlural, getEventClockedStartedOrManualOrAdmin, momentDurationFormat, showNotification } from '../../utils/utils';
import { IntlProps } from '../app/LanguageProvider';
import Can from '../common/general/can';
import Carousel from '../common/general/carousel';
import Fieldset from '../common/general/fieldset';
import SpaceContent from '../common/general/spaceContent';

type ReduxProps = ConnectedProps<typeof connector>;

interface Props extends RouterProps, ReduxProps, IntlProps {
    event: PlanningEvent | undefined;
    user: User | undefined;
    group: Group | undefined;
    canEdit?: boolean;
    onChangeEvent: (event: PlanningEvent) => void;
    onDeleteOvertime: (overtime: PlanningOvertime) => void;
    onCancel: () => void;
    onDelete: (eventId: number, userId: number, day: string) => void;
    onEdit: (eventId: number) => void;
    onCreateTemplate: () => void;
}

interface State {
    createTemplateLoading: boolean;
    confirmed: NetworkEventConfirmed[] | undefined;
    notConfirmed: NetworkEventConfirmed[] | undefined;
    loadingEventConfirmed: boolean;
    key: 'extra' | 'summary' | 'break' | 'overtime' | 'journey' | 'eventclocked';
    adminEventClockedActionsLoading: boolean;
    userEventClockedActionsLoading: boolean;
    editEventClockedShowModal: boolean;
    manualEventClockedShowModal: boolean;
    creator: User | undefined;
    modifier: User | undefined;
    eventImages?: NetworkEventImage[];
    eventImagesLoading: boolean;
    files: GenericFile[];
    loadingFiles: boolean;
}

class ShowEventModal extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            files: [],
            loadingFiles: false,
            createTemplateLoading: false,
            confirmed: undefined,
            notConfirmed: undefined,
            key: 'summary',
            adminEventClockedActionsLoading: false,
            editEventClockedShowModal: false,
            manualEventClockedShowModal: false,
            creator: undefined,
            modifier: undefined,
            userEventClockedActionsLoading: false,
            eventImages: undefined,
            eventImagesLoading: false,
            loadingEventConfirmed: false
        };
    }

    componentDidMount(): void {
        const { event, users } = this.props;

        if (this.props.event) {
            this.getEventConfirmed(this.props.event.id!);
            this.getEventImages(this.props.event.id!);
            this.loadEventFiles();

        }
        if (users) {
            if (event) {
                const creator = users.find(u => u.id === event.creatorId);
                const modifier = users.find(u => u.id === event.modifierId);
                this.setState({ creator, modifier });
            }
        } else {
            Network.getAllUsers().then(
                response => {
                    this.props.changeUsers(response);
                },
                () => showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the users' }), "warning")
            );
        }
    }

    componentDidUpdate(prevProps: Readonly<Props>) {
        const { event, users } = this.props;

        if (event && (!isEqual(event, prevProps.event) || !isEqual(users, prevProps.users))) {
            this.getEventConfirmed(event.id!);
            this.getEventImages(event.id!);
            this.loadEventFiles();
            if (users) {
                if (event) {
                    const creator = users.find(u => u.id === event.creatorId);
                    const modifier = users.find(u => u.id === event.modifierId);
                    this.setState({ creator, modifier });
                }
            } else {
                Network.getAllUsers().then(
                    response => {
                        this.props.changeUsers(response);
                    },
                    () => showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the users' }), "warning")
                );
            }
        }

    }

    loadEventFiles = () => {
        if (!this.state.loadingFiles) {
            this.setState({ loadingFiles: true }, () => {
                if (this.props.event && this.props.event.id) {
                    Network.getEventFiles(this.props.event.id).then(
                        (response) => {
                            if (response.error === false && response.data !== undefined) {
                                this.setState({ loadingFiles: false, files: response.data });
                            } else {
                                this.setState({ loadingFiles: false });
                            }
                        },
                        () => {
                            this.setState({ loadingFiles: false });
                            showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the files' }), "warning");
                        }
                    );
                } else {
                    this.setState({ loadingFiles: false });
                }

            });

        }
    };

    getEventConfirmed = (eventId: number) => {
        this.setState({ loadingEventConfirmed: true }, () => {
            Network.eventConfirmed(eventId).then(
                (response: NetworkEventConfirmed[]) => {
                    const confirmed: NetworkEventConfirmed[] = response.filter(c => c.confirmed);
                    const notConfirmed: NetworkEventConfirmed[] = response.filter(c => !c.confirmed);
                    this.setState({ confirmed, notConfirmed, loadingEventConfirmed: false });
                },
                () => {
                    this.setState({ loadingEventConfirmed: false });
                    showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the event' }), "warning");
                },
            );
        });

    };

    getEventImages = (eventId: number) => {
        this.setState({ eventImagesLoading: true }, () => {
            Network.getEventsImages(eventId).then(
                (response: NetworkEventImage[]) => {
                    this.setState({ eventImages: response, eventImagesLoading: false });
                },
                () => {
                    this.setState({ eventImages: undefined, eventImagesLoading: false });
                    showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the images' }), "warning");
                },
            );
        });

    };
    activeImage = (imageId: number, state = true) => this.updateEventImages({ id: imageId, active: state });

    renameImage = (imageId: number, name: string) => this.updateEventImages({ id: imageId, name: name });

    updateEventImages = (data: { id: number, name?: string, active?: boolean; }) => {
        this.setState({ eventImagesLoading: true }, () => {
            Network.updateEventsImage(data, this.props.event!.id).then(
                (response: NetworkEventImage[]) => {
                    this.setState({ eventImages: response, eventImagesLoading: false });
                },
                () => {
                    this.setState({ eventImages: undefined, eventImagesLoading: false });
                    showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while updating the images' }), "warning");
                },
            );
        });
    };

    deleteEventImages = (imageId: number) => {
        this.setState({ eventImagesLoading: true }, () => {
            Network.deleteEventsImage(imageId, this.props.event!.id).then(
                (response: NetworkEventImage[]) => {
                    this.setState({ eventImages: response, eventImagesLoading: false });
                },
                () => {
                    this.setState({ eventImages: undefined, eventImagesLoading: false });
                    showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while deleting the images' }), "warning");
                },
            );
        });
    };

    getFrequenceIntervalText = (frequence: Frequency, interval: number) => this.props.intl.formatMessage({ defaultMessage: 'occurs every {interval} {text}' }, { time: interval > 1 ? interval : '', text: this.getFrequenceText(frequence) });

    getWeekdaysText = (weekdays: ByWeekday[]) => this.props.intl.formatMessage({ defaultMessage: 'occurs every {days}' }, { days: weekdays.slice(1).reduce((a: string, v: ByWeekday) => a + ", " + this.getWeekdayText(v), this.getWeekdayText(weekdays[0])) });

    getDtstartText = (start: Moment) => this.props.intl.formatMessage({ defaultMessage: 'Starts from {start}' }, { start: start.format(getFormat('DATE_LONG')) });

    getUntilText = (until: Moment) => this.props.intl.formatMessage({ defaultMessage: 'until {end}' }, { end: until.format(getFormat('DATE_LONG')) });

    getCountText = (count: number) => this.props.intl.formatMessage({ defaultMessage: 'repeats {count} times' }, { count });

    getFrequenceText = (frequence: Frequency) => {
        const { intl } = this.props;
        switch (frequence) {
            case EventFrequences.Hourly:
                return intl.formatMessage({ defaultMessage: 'hours' });
            case EventFrequences.Daily:
                return intl.formatMessage({ defaultMessage: 'day' });
            case EventFrequences.Weekly:
                return intl.formatMessage({ defaultMessage: 'weeks' });
            case EventFrequences.Monthly:
                return intl.formatMessage({ defaultMessage: 'month' });
            case EventFrequences.Yearly:
                return intl.formatMessage({ defaultMessage: 'year' });
        }
    };

    getWeekdayText = (weekday: ByWeekday): string => {
        const { intl } = this.props;
        switch (weekday) {
            case 0:
                return intl.formatMessage({ defaultMessage: 'Monday' });
            case 1:
                return intl.formatMessage({ defaultMessage: 'Tuesday' });
            case 2:
                return intl.formatMessage({ defaultMessage: 'Wednesday' });
            case 3:
                return intl.formatMessage({ defaultMessage: 'Thursday' });
            case 4:
                return intl.formatMessage({ defaultMessage: 'Friday' });
            case 5:
                return intl.formatMessage({ defaultMessage: 'Saturday' });
            default:
                return intl.formatMessage({ defaultMessage: 'Sunday' });
        }
    };

    getTimerDuration = () => {
        const { event } = this.props;
        const diff = event!.timerStop!.diff(event!.timerStart, 'minutes');
        const hours = toInteger(diff / 60);
        const minutes = diff - (hours * 60);
        return `${hours ? (hours < 10 ? `0${hours} h ` : `${hours} h `) : ''}${minutes < 10 ? `0${minutes}` : minutes} ${this.props.intl.formatMessage({ defaultMessage: 'minutes' })}`;
    };

    createTemplate = () => {
        const { event } = this.props;
        if (!event) return;
        this.setState({ createTemplateLoading: true });
        let i = -1;
        const template: PlanningTemplate = {
            eventId: event?.id,
            startDate: event!.startDate,
            endDate: event!.endDate,
            title: event!.title,
            color: event!.color,
            poi: event!.poi,
            vehicle: event!.vehicle,
            distance: event!.distance,
            basePoi: event!.basePoi,
            breakTimes: event.breakTimes,
            reminders: event?.reminders?.map(r => {
                return ({ ...r, id: i-- });
            })
        };

        Network.copyTemplate(Number(event.id), template).then(
            () => {
                message.success(this.props.intl.formatMessage({ defaultMessage: 'The template has been successfully created' }));
                this.setState({ createTemplateLoading: false });
                this.props.onCreateTemplate();
            },
            () => {
                message.error(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while creating the template' }));
                this.setState({ createTemplateLoading: false });
            }
        );
    };

    getEventClockedDuration = (eventClockedDates: EventClockedDates) => {
        return moment.duration(eventClockedDates.endDate!.diff(eventClockedDates.startDate));
    };

    convertMillisecondsToHoursMinutesSeconds = (milliseconds: number) => {
        const hours = Math.floor(milliseconds / 3600000);
        const minutes = Math.floor((milliseconds % 3600000) / 60000);
        const seconds = Math.floor(((milliseconds % 360000) % 60000) / 1000);
        return `${hours}h ${minutes}m ${seconds}s`;
    };

    getEventClockedDurationContent = (event: PlanningEvent, isDateClocked?: boolean, mode?: 'AUTO' | 'CLOCKED' | 'MANUAL' | 'ADMIN') => {
        const eventClocked = event.clocked;
        const clockDates: EventClockedDates = eventClocked
            ? isDateClocked
                ? (!mode || mode === 'AUTO')
                    ? getEventClockedStartedOrManualOrAdmin(eventClocked)
                    : mode === 'CLOCKED'
                        ? { startDate: eventClocked.startDate, endDate: eventClocked.endDate }
                        : mode === 'ADMIN'
                            ? { startDate: eventClocked.adminStartDate, endDate: eventClocked.adminEndDate }
                            : { startDate: eventClocked.manualStartDate, endDate: eventClocked.manualEndDate }
                : { startDate: eventClocked.planStartDate, endDate: eventClocked.planEndDate }
            : { startDate: event.startDate, endDate: event.endDate };

        const clockedBreakTimeDuration = event && event.breakTimes ? event.breakTimes.reduce((value: number, breakTimeClocked: BreakTime) => value = value + (breakTimeClocked.active && breakTimeClocked.clocked == null && breakTimeClocked.isPaid === false ? breakTimeClocked.totalHours : 0), 0) : 0;
        const eventBreakTimeDuration = event.clocked && event.breakTimes ? event.breakTimes.reduce((value: number, breakTimeClocked: BreakTime) => value = value + ((breakTimeClocked.active && breakTimeClocked.clocked != null && breakTimeClocked.isPaid === false) ? breakTimeClocked.totalHours : 0), 0) : 0;

        const breakTimeDuration = eventClocked ? eventBreakTimeDuration : clockedBreakTimeDuration;

        const clockedEventClockedDuration = this.getEventClockedDuration(clockDates).subtract(breakTimeDuration, 'hours');

        if (clockedEventClockedDuration.asSeconds() < 0) {
            return moment.utc(Math.abs(clockedEventClockedDuration.asMilliseconds())).format(`-${MOMENT_TIME_FORMAT}`);
        }
        else {
            return moment.utc(Math.abs(clockedEventClockedDuration.asMilliseconds())).format(MOMENT_TIME_FORMAT);
        }
    };

    getEventClockDurationDiffContent = (event: PlanningEvent) => {
        const eventClocked = event.clocked;
        if (eventClocked === undefined) return;
        const clockDates = getEventClockedStartedOrManualOrAdmin(eventClocked);
        const planDates: EventClockedDates = {
            startDate: eventClocked.planStartDate,
            endDate: eventClocked.planEndDate
        };
        const clockedBreakTimeDuration = event && event.breakTimes ? event.breakTimes.reduce((value: number, breakTimeClocked: BreakTime) => value = value + (breakTimeClocked.active && breakTimeClocked.clocked == null && breakTimeClocked.isPaid === false ? breakTimeClocked.totalHours : 0), 0) : 0;
        const eventBreakTimeDuration = event.clocked && event.breakTimes ? event.breakTimes.reduce((value: number, breakTimeClocked: BreakTime) => value = value + ((breakTimeClocked.active && breakTimeClocked.clocked != null && breakTimeClocked.isPaid === false) ? breakTimeClocked.totalHours : 0), 0) : 0;



        const planDuration = this.getEventClockedDuration(planDates).subtract(eventBreakTimeDuration, 'hours');
        const clockDuration = clockDates.endDate ? this.getEventClockedDuration(clockDates).subtract(clockedBreakTimeDuration, 'hours') : undefined;

        const planDurationIsHigher = clockDuration ? planDuration.asMinutes() > clockDuration.asMinutes() ? true : false : undefined;
        const difference: string | undefined = clockDuration ? planDurationIsHigher ? moment.utc(planDuration.asMilliseconds() - clockDuration.asMilliseconds()).format(MOMENT_TIME_FORMAT) : moment.utc(clockDuration.asMilliseconds() - planDuration.asMilliseconds()).format(MOMENT_TIME_FORMAT) : undefined;
        return (
            <>
                <Row>
                    <Col xs={{ span: 24 }}>
                        {
                            clockDuration ?
                                planDuration.asMinutes() > clockDuration.asMinutes() ?
                                    <Tag color='green' style={{ fontSize: 15, fontWeight: 500 }}>{`- ${difference}`}</Tag>
                                    :
                                    planDuration.asMinutes() < clockDuration.asMinutes() ?
                                        <Tag color='red' style={{ fontSize: 15, fontWeight: 500 }}>{`+ ${difference}`}</Tag>
                                        :
                                        <Tag color='gold' style={{ fontSize: 15, fontWeight: 500 }}><FormattedMessage defaultMessage={'Identical'} /></Tag>
                                :
                                <Tag color='blue' style={{ fontSize: 15, fontWeight: 500 }}><FormattedMessage defaultMessage={'In progress'} /></Tag>
                        }
                    </Col>
                </Row>
            </>
        );
    };

    onConfirmEventClocked = (eventClocked: EventClockedSmall) => {
        this.setState({ adminEventClockedActionsLoading: true });
        Network.confirmEventClocked(eventClocked.id).then(
            () => {
                this.setState({ adminEventClockedActionsLoading: false, });
                const event = cloneDeep(this.props.event);
                if (event && event.clocked) {
                    event.clocked = {
                        ...event.clocked,
                        isConfirmed: true,
                    };
                    this.props.onChangeEvent(event);
                }
                showNotification(this.props.intl.formatMessage({ defaultMessage: 'The event has been successfully confirmed' }), "success");
            },
            () => {
                this.setState({ adminEventClockedActionsLoading: false });
                showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while confirming the event' }), "warning");
            }
        );
    };

    onRejectEventClocked = (eventClocked: EventClockedSmall) => {
        this.setState({ adminEventClockedActionsLoading: true });
        Network.rejectEventClocked(eventClocked.id).then(
            () => {
                this.setState({ adminEventClockedActionsLoading: false });
                const event = cloneDeep(this.props.event);
                if (event && event.clocked) {
                    event.clocked = {
                        ...event.clocked,
                        isRefused: true,
                    };
                    this.props.onChangeEvent(event);
                }
                showNotification(this.props.intl.formatMessage({ defaultMessage: 'The event has been successfully rejected' }), "success");
            },
            () => {
                this.setState({ adminEventClockedActionsLoading: false });
                showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while rejecting the event' }), "warning");
            }
        );
    };

    onConfirmEditEventClocked = (eventClocked: EventClockedSmall, params: NetworkEventClockedSetAdmin) => {
        let error = false;
        this.setState({ adminEventClockedActionsLoading: true });
        Network.setAdminEventClocked(eventClocked.id, params).then(
            response => {
                showNotification(this.props.intl.formatMessage({ defaultMessage: 'The event has been successfully updated' }), "success");
                const event = cloneDeep(this.props.event);
                if (event && event.clocked) {
                    event.clocked = {
                        ...event.clocked,
                        isConfirmed: true,
                        adminStartDate: moment(response.data.adminStartDate),
                        adminEndDate: moment(response.data.adminEndDate)
                    };
                    this.props.onChangeEvent(event);
                }
                this.setState({ adminEventClockedActionsLoading: false, editEventClockedShowModal: false });
            },
            () => {
                showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while updating the event' }), "warning");
                error = true;
            }
        );

        return error;
    };

    onStopEventClocked = (eventClocked: EventClockedSmall) => {
        this.setState({ userEventClockedActionsLoading: true });
        Network.stopEventClocked(eventClocked.id).then(
            response => {
                showNotification(this.props.intl.formatMessage({ defaultMessage: 'The timeclock has been successfully stopped' }), 'success');
                const event = cloneDeep(this.props.event);
                if (event && event.clocked) {
                    event.clocked = {
                        ...event.clocked,
                        endDate: moment(response.data.endDate),
                    };
                    this.props.onChangeEvent(event);
                }
                this.setState({ userEventClockedActionsLoading: false });
            },
            () => {
                this.setState({ userEventClockedActionsLoading: false });
                showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while stopping the timeclock' }), 'warning');
            }
        );
    };

    onSetManuallyEventClocked = (eventClockedId: number, parameters: NetworkEventClockedSetManually) => {
        this.setState({ userEventClockedActionsLoading: true });
        Network.setManuallyEventClocked(eventClockedId, parameters).then(
            response => {
                showNotification(this.props.intl.formatMessage({ defaultMessage: 'The timeclock has been successfully updated' }), 'success');
                const event = cloneDeep(this.props.event);
                if (event && event.clocked) {
                    event.clocked = {
                        ...event.clocked,
                        manualStartDate: moment(response.data.manualStartDate),
                        manualEndDate: moment(response.data.manualEndDate),
                        manualDescription: response.data.manualDescription,
                        isManual: true
                    };
                    this.props.onChangeEvent(event);
                }
                this.setState({ userEventClockedActionsLoading: false, manualEventClockedShowModal: false });
            },
            () => {
                this.setState({ userEventClockedActionsLoading: false });
                showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while setting the timeclock manually' }), 'warning');
            }
        );
    };

    getClockedDateStyle = (eventClocked: EventClockedSmall, isStartDate?: boolean): React.CSSProperties => {
        const date = isStartDate ? eventClocked.startDate : eventClocked.endDate;
        const adminDate = isStartDate ? eventClocked.adminStartDate : eventClocked.adminEndDate;
        const manualDate = isStartDate ? eventClocked.manualStartDate : eventClocked.manualEndDate;
        const isManual = eventClocked.isManual;

        const dateIsSame = adminDate?.isSame(isManual && manualDate ? manualDate : date);

        return {
            textAlign: 'right',
            fontStyle: adminDate && !dateIsSame || !date ? 'italic' : '',
            color: eventClocked.isConfirmed || eventClocked.isManual ? '' : adminDate ? '' : isManual ? '#d46b08' : !date ? 'gray' : '',
            cursor: eventClocked.isConfirmed || eventClocked.isManual ? '' : adminDate && !dateIsSame || isManual && !adminDate ? 'pointer' : ''
        };
    };
    getDurationText = (date: Moment) => {
        const { intl } = this.props;
        const duration = moment.duration(moment().diff(date));
        let durationText = intl.formatMessage({ defaultMessage: '{time} seconds' }, { time: duration.format('ss') });
        if (duration.asMonths() >= 12) {
            durationText = intl.formatMessage({ defaultMessage: '{time} years' }, { time: moment().diff(date, 'years') });
        } else if (duration.asWeeks() >= 8) {
            durationText = intl.formatMessage({ defaultMessage: '{time} months' }, { time: moment().diff(date, 'months') });
        } else if (duration.asDays() >= 14) {
            durationText = intl.formatMessage({ defaultMessage: '{time} weeks' }, { time: moment().diff(date, 'weeks') });
        } else if (duration.asHours() >= 24) {
            durationText = intl.formatMessage({ defaultMessage: '{time} days' }, { time: moment().diff(date, 'days') });
        } else if (duration.asMinutes() >= 60) {
            durationText = intl.formatMessage({ defaultMessage: '{time} hours' }, { time: duration.format('HH') });
        } else if (duration.asSeconds() >= 60) {
            durationText = intl.formatMessage({ defaultMessage: '{time} minutes' }, { time: duration.format('mm') });
        }
        return durationText;
    };

    renderEventclockedDateBloc = (title: string, from: Moment | null, to: Moment | null) => {
        if (!from && !to) return null;

        const noData = <span style={{ fontStyle: 'italic', fontWeight: '400', color: 'grey' }}>Aucune donnée</span>;
        return (
            <Fieldset legendStyle={{ fontSize: '16px', marginLeft: '10px' }} legend={title}>
                <Row gutter={[15, 15]}>
                    <Col xs={{ span: 12 }}><FormattedMessage defaultMessage={'Start'} /></Col>
                    <Col xs={{ span: 12 }} style={{ textAlign: 'right' }}>{from ? from.format(MOMENT_FORMAT_DISPLAY_LONG) : noData}</Col>
                </Row>
                <Row gutter={[15, 15]}>
                    <Col xs={{ span: 12 }}><FormattedMessage defaultMessage={'End'} /></Col>
                    <Col xs={{ span: 12 }} style={{ textAlign: 'right' }}>{to ? to.format(MOMENT_FORMAT_DISPLAY_LONG) : noData}</Col>
                </Row>
                {
                    from && to ?
                        <Row gutter={[15, 15]}>
                            <Col xs={{ span: 12 }}><FormattedMessage defaultMessage={'Duration'} /></Col>
                            <Col xs={{ span: 12 }} style={{ textAlign: 'right' }}>
                                {this.getEventClockedDurationContent({ startDate: from, endDate: to, title: 'tmp' })}
                            </Col>
                        </Row>
                        : null
                }
            </Fieldset>
        );
    };

    TabContent = (props: { activeKey: 'extra' | 'summary' | 'break' | 'overtime' | 'journey' | 'eventclocked', creator: User | undefined; modifier: User | undefined; }) => {
        const { activeKey, creator, modifier } = props;
        const { event, user, group, intl } = this.props;
        const { Panel } = Collapse;
        const { eventImages } = this.state;
        const images = this.state.files.filter(f => f.type === 'GenericFile' && f.res_type === 'IMG');
        const files = this.state.files.filter(f => f.type === 'GenericFile' && f.res_type === 'FLE');
        const eventUser = event ? this.props.users.find(u => event.userId === u.id) : undefined;

        if (!event) return <></>;
        switch (activeKey) {
            case "extra": {
                return (
                    <div style={{ position: 'relative' }}>
                        <span className="" style={{ position: 'absolute', top: 10, display: 'flex', justifyContent: 'center', width: '100%', padding: 10, margin: 0, fontSize: '20px', fontWeight: 'bold' }}>{event.title}</span>

                        <Comment
                            className='show-event-modal'
                            style={{ padding: 10 }}
                            author={`${event.userName}`}
                            datetime={
                                <Tooltip overlayInnerStyle={{ width: 370 }} title={<div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}><span>{`${moment(event.startDate).format(getFormat('DATE_AND_ON_TIME'))}`}</span><span>{'-'}</span><span>{`${moment(event.endDate).format(getFormat('DATE_AND_ON_TIME'))}`}</span></div>}>
                                    <span>{`${moment(event.startDate).format(getFormat('TIME_SHORT'))} - ${moment(event.endDate).format(getFormat('TIME_SHORT'))}`}</span>
                                </Tooltip>
                            }
                            content={
                                ((images && images.length > 0) || (event.description) || (files && files.length > 0) || event.userNote || creator || modifier) &&
                                <Row style={{
                                    fontSize: '18px',
                                    backgroundColor: 'rgba(251,192,45,.03549019607843137)',
                                    borderRadius: 'var(--border-radius)',
                                    padding: 10,
                                }} >

                                    <Col span={24}>
                                        {
                                            images && images.length > 0 ?
                                                <div>
                                                    <Carousel
                                                        leftText=''
                                                        rightText=''
                                                        images={images.map(i => {
                                                            return {
                                                                id: i.id,
                                                                name: i.file_name,
                                                                image: i.file_name,
                                                                fullImageUrl: i.auth_url,
                                                                src: i.res_url,
                                                                file: i.file_name,
                                                                active: i.active
                                                            };
                                                        })}
                                                        width={650}
                                                        height={250}
                                                    />
                                                </div>
                                                : null
                                        }
                                    </Col>
                                    {
                                        event.description ?
                                            <Col span={24}>
                                                <div style={{ width: '100%', display: 'flex', justifyContent: images && images.length > 0 ? 'start' : 'start', alignItems: 'end' }}>
                                                    <pre style={{
                                                        width: '100%',
                                                        fontFamily: 'inherit',
                                                        whiteSpace: 'pre-wrap',
                                                        fontSize: '18px',
                                                        padding: 15,
                                                        marginBottom: 0
                                                    }}>{event.description}</pre>
                                                </div>
                                            </Col>
                                            : null
                                    }
                                    {
                                        files && files.length > 0 ?
                                            <>
                                                {
                                                    (images && images.length > 0) || (event.description) ?
                                                        <Col span={24}>
                                                            <Divider type={'horizontal'} style={{
                                                                marginTop: 0,
                                                                width: '100%',
                                                                fontFamily: 'inherit',
                                                                whiteSpace: 'pre-wrap',
                                                                fontSize: '18px',
                                                                //padding: 15
                                                            }} />
                                                        </Col>
                                                        : null
                                                }
                                                <Col xs={{ span: 24 }} md={{ span: 24 }}>
                                                    <div style={{ width: '100%', fontSize: '14px' }}>
                                                        <h1 style={{ fontSize: '16px', fontWeight: 'bold' }}><FormattedMessage defaultMessage={'Files'} /></h1>
                                                        <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
                                                            {files.map(f => {
                                                                return <div style={{ display: 'flex', flexDirection: 'row', gap: 8, justifyContent: 'space-between', alignItems: 'center' }} key={`file-${f.id}-${f.file_name}`}>
                                                                    <div style={{ width: 500, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}><span>{f.file_name}</span></div>
                                                                    <div style={{ display: 'flex', flexDirection: 'row', gap: 8, alignItems: 'center', justifyContent: 'end' }}>
                                                                        <a target={'_blank'} href={f.auth_url} rel="noreferrer"><FormattedMessage defaultMessage={'Download'} /></a>
                                                                        <FileOutlined />
                                                                    </div>
                                                                </div>;
                                                            })}
                                                        </div>
                                                    </div>
                                                </Col>
                                            </>
                                            :
                                            null
                                    }
                                    {
                                        event.userNote ?
                                            <>
                                                <Col span={24}>
                                                    <Divider type={'horizontal'} style={{
                                                        width: '100%',
                                                        fontFamily: 'inherit',
                                                        whiteSpace: 'pre-wrap',
                                                        fontSize: '18px',
                                                        marginBottom: 0
                                                    }} />
                                                </Col>
                                                <Col span={24}>
                                                    <Comment
                                                        className='show-event-modal'
                                                        avatar={<Avatar icon={<UserOutlined />} src={eventUser !== undefined ? eventUser.image : undefined} alt={event.userName} />}
                                                        author={`${event.userName}`}
                                                        content={
                                                            <div style={{ width: '100%', display: 'flex', justifyContent: images && images.length > 0 ? 'start' : 'start', alignItems: 'center' }}>
                                                                <pre style={{
                                                                    fontFamily: 'inherit',
                                                                    whiteSpace: 'pre-wrap',
                                                                    fontSize: '14px',
                                                                    borderRadius: 'var(--border-radius)',
                                                                }}>{event.userNote}</pre>
                                                            </div>
                                                        }
                                                    />
                                                </Col>
                                            </>
                                            : null
                                    }
                                    {
                                        eventImages ?
                                            <Col xs={{ span: 24 }} md={{ span: 24 }}>
                                                {
                                                    eventImages.map(eI => {
                                                        return (
                                                            <Comment
                                                                key={`comment-user-image-${eI.id}-${eI.modified}`}
                                                                className='show-event-modal'
                                                                avatar={<Avatar icon={<UserOutlined />} src={eventUser !== undefined ? eventUser.image : undefined} alt={event.userName} />}
                                                                author={`${event.userName}`}
                                                                datetime={
                                                                    <Tooltip title={moment(eI.created).format(getFormat('DATE_LONG_AND_ON_TIME_SHORT'))}>
                                                                        <span><FormattedMessage defaultMessage={'{time} ago'} values={{ time: this.getDurationText(moment(eI.created)) }} /></span>
                                                                    </Tooltip>
                                                                }
                                                                content={
                                                                    <Carousel images={[eI]} width={600} height={200} deleteImage={this.deleteEventImages} setImageName={this.renameImage} activeImage={this.activeImage} />
                                                                }
                                                            />
                                                        );
                                                    })
                                                }
                                            </Col>
                                            : null
                                    }
                                    <Col span={24}>
                                        {
                                            modifier ?
                                                <Comment
                                                    className='show-event-modal'
                                                    avatar={<Avatar icon={<UserOutlined />} src={modifier.image} alt={modifier.username} />}
                                                    author={`${modifier.last_name} ${modifier.first_name}`}
                                                    datetime={
                                                        <Tooltip title={moment(event.modified).format(getFormat('DATE_LONG_AND_ON_TIME_SHORT'))}>
                                                            <span><FormattedMessage defaultMessage={'{time} ago'} values={{ time: this.getDurationText(moment(event.modified)) }} /></span>
                                                        </Tooltip>
                                                    }
                                                    content={
                                                        <span><FormattedMessage defaultMessage={'Updated the event'} /></span>
                                                    }
                                                />
                                                : null
                                        }
                                    </Col>
                                    <Col span={24}>
                                        {
                                            creator ?
                                                <Comment
                                                    className='show-event-modal'
                                                    avatar={<Avatar icon={<UserOutlined />} src={creator.image} alt={creator.username} />}
                                                    author={`${creator.last_name} ${creator.first_name}`}
                                                    datetime={
                                                        <Tooltip title={moment(event.created).format(getFormat('DATE_LONG_AND_ON_TIME_SHORT'))}>
                                                            <span><FormattedMessage defaultMessage={'{time} ago'} values={{ time: this.getDurationText(moment(event.created)) }} /></span>
                                                        </Tooltip>
                                                    }
                                                    content={
                                                        <span><FormattedMessage defaultMessage={'Created the event'} /></span>
                                                    }
                                                />
                                                : null
                                        }
                                    </Col>
                                </Row>
                            } />
                    </div>

                );

            }
            case "summary": {
                const simpleEvent: SimpleEvent = convertPlanningEventToSimpleEvent(event);

                const eventDuration = moment.duration(UserEventsData.eventDuration(simpleEvent, undefined, undefined, undefined, undefined, true), "seconds");
                const eventDurationString = momentDurationFormat(eventDuration);

                const eventEffective = moment.duration(UserEventsData.eventDuration(simpleEvent, true, true, undefined, undefined, true), "seconds");
                const eventEffectiveString = momentDurationFormat(eventEffective);

                const paidBreaktimeDuration = moment.duration(UserEventsData.breaktimesDuration([simpleEvent], BreaktimeCalculatedTypes.PAID, undefined, undefined, true), "seconds");
                const paidBreaktimeDurationStr = momentDurationFormat(paidBreaktimeDuration);

                const notPaidBreaktimeDuration = moment.duration(UserEventsData.breaktimesDuration([simpleEvent], BreaktimeCalculatedTypes.NOTPAID, undefined, undefined, true), "seconds");
                const notPaidBreaktimeDurationStr = momentDurationFormat(notPaidBreaktimeDuration);

                const overtimesDuration = moment.duration(UserEventsData.overtimesDuration([simpleEvent], OvertimesCalculatedTypes.ALL, OvertimesCalculatedStatus.CONFIRMED, undefined, undefined, true), "seconds");
                const overtimesDurationStr = momentDurationFormat(overtimesDuration);
                let confirmed = undefined;
                if (this.state.confirmed && this.state.confirmed.length > 0) {
                    confirmed = this.state.confirmed.find(c => c.user.id === event.userId);
                }
                return (
                    <>
                        {event.isGlobal &&
                            <Row>
                                <Col xs={{ span: 24 }}>
                                    <p><FormattedMessage defaultMessage={'Global event'} /></p>
                                </Col>
                            </Row>
                        }

                        {user &&
                            <Row>
                                <Col xs={{ span: 24 }}>
                                    <div>
                                        <p className="planning-details-group-name" onClick={() => this.props.history.push(`/${this.props.match.params.lang}/team-management/user-details/informations?id=${user!.id}`)}><FormattedMessage defaultMessage={'User event {user}'} values={{ user: `${user.last_name} ${user.first_name}` }} /></p>
                                    </div>
                                </Col>
                            </Row>
                        }
                        {group &&
                            <Row>
                                <Col xs={{ span: 24 }}>
                                    <div>
                                        <p className="planning-details-group-name" onClick={() => this.props.history.push(`/${this.props.match.params.lang}/team-management/group-details/informations?id=${group!.id}`)}> <FormattedMessage defaultMessage={'Group event'} /> {group.name}</p>
                                    </div>
                                </Col>
                            </Row>
                        }
                        {
                            event.department ?
                                <Row>
                                    <Col xs={{ span: 12 }} md={{ span: 12 }}>
                                        <p><FormattedMessage defaultMessage={'Department'} />{':'}</p>
                                    </Col>
                                    <Col xs={{ span: 12 }} md={{ span: 12 }} style={{ textAlign: 'right' }}>
                                        <p>{event.department.name}</p>
                                    </Col>
                                </Row>
                                : null
                        }
                        <Row>
                            <Col xs={{ span: 12 }} md={{ span: 12 }}>
                                <p><FormattedMessage defaultMessage={'Start'} />{':'}</p>
                            </Col>
                            <Col xs={{ span: 12 }} md={{ span: 12 }} style={{ textAlign: 'right' }}>
                                <p>{this.props.isSmartphone ?
                                    moment(event.startDate).format(getFormat('DATE_AND_TIME_SHORT'))
                                    : moment(event.startDate).format(getFormat('DATE_LONG_AND_ON_TIME_SHORT'))}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={{ span: 12 }} md={{ span: 12 }}>
                                <p><FormattedMessage defaultMessage={'End'} />{':'}</p>
                            </Col>
                            <Col xs={{ span: 12 }} md={{ span: 12 }} style={{ textAlign: 'right' }}>
                                <p>{this.props.isSmartphone ?
                                    moment(event.endDate).format(getFormat('DATE_AND_TIME_SHORT'))
                                    : moment(event.endDate).format(getFormat('DATE_LONG_AND_ON_TIME_SHORT'))}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={{ span: 12 }} md={{ span: 12 }}>
                                <p><FormattedMessage defaultMessage={'Event duration'} />{':'}</p>
                            </Col>
                            <Col xs={{ span: 12 }} md={{ span: 12 }} style={{ textAlign: 'right' }}>
                                <p>{eventDurationString}</p>
                            </Col>
                        </Row>
                        {
                            paidBreaktimeDuration.asMinutes() !== 0 ?
                                <Row>
                                    <Col xs={{ span: 12 }} md={{ span: 12 }}>
                                        <p style={{ fontStyle: 'italic', opacity: '0.9' }}><FormattedMessage defaultMessage={'Paid breaks duration'} />{':'}</p>
                                    </Col>
                                    <Col xs={{ span: 12 }} md={{ span: 12 }} style={{ textAlign: 'right' }}>
                                        <p style={{ fontStyle: 'italic', opacity: '0.9' }}>(-{paidBreaktimeDurationStr})</p>
                                    </Col>
                                </Row>
                                : ''
                        }
                        {
                            notPaidBreaktimeDuration.asMinutes() !== 0 ?
                                <Row>
                                    <Col xs={{ span: 12 }} md={{ span: 12 }}>
                                        <p><FormattedMessage defaultMessage={'Unpaid breaks duration'} />{':'}</p>
                                    </Col>
                                    <Col xs={{ span: 12 }} md={{ span: 12 }} style={{ textAlign: 'right' }}>
                                        <p>-{notPaidBreaktimeDurationStr}</p>
                                    </Col>
                                </Row>
                                : ''
                        }
                        {
                            overtimesDuration.asMinutes() !== 0 ?
                                <Row>
                                    <Col xs={{ span: 12 }} md={{ span: 12 }}>
                                        <p><FormattedMessage defaultMessage={'Overtimes duration'} />{':'}</p>
                                    </Col>
                                    <Col xs={{ span: 12 }} md={{ span: 12 }} style={{ textAlign: 'right' }}>
                                        <p>{overtimesDurationStr}</p>
                                    </Col>
                                </Row>
                                : ''
                        }
                        <Row>
                            <Col xs={{ span: 12 }} md={{ span: 12 }}>
                                <p><FormattedMessage defaultMessage={'Total duration'} />{':'}</p>
                            </Col>
                            <Col xs={{ span: 12 }} md={{ span: 12 }} style={{ textAlign: 'right' }}>
                                {
                                    <p>{eventEffectiveString}</p>
                                    // event.breakTimes && event.breakTimes.length > 0 ?
                                    //     <p>{parseFloat((event.endDate.diff(event.startDate, "hours", true)).toFixed(2)) - parseFloat(event.breakTimes.reduce((acc, val) => !val.isPaid ? acc + val.totalHours : acc, 0.0).toFixed(2))} heures</p>
                                    //     :
                                    //     <p>{event.endDate.diff(event.startDate, "hours", true).toFixed(2)} heures</p>
                                }
                            </Col>
                        </Row>
                        {event.typeOfDay &&
                            <Row>
                                <Col xs={{ span: 12 }} md={{ span: 12 }}>
                                    <p><FormattedMessage defaultMessage={'Type of day'} />{':'}</p>
                                </Col>
                                <Col xs={{ span: 12 }} md={{ span: 12 }} style={{ textAlign: 'right' }}>
                                    {event.typeOfDay?.title}
                                </Col>
                            </Row>
                        }
                        {event.typeOfDayOff &&
                            <Row>
                                <Col xs={{ span: 12 }} md={{ span: 12 }}>
                                    <p><FormattedMessage defaultMessage={'Type of vacation'} />{':'}</p>
                                </Col>
                                <Col xs={{ span: 12 }} md={{ span: 12 }} style={{ textAlign: 'right' }}>
                                    {event.typeOfDayOff?.title}
                                </Col>
                            </Row>
                        }
                        {event.project &&
                            <Row>
                                <Col xs={{ span: 12 }} md={{ span: 12 }}>
                                    <p>{this.props.company?.projectDisplayText ? getCaseAndPlural(this.props.company?.projectDisplayText, false, CaseType.FIRST_LETTER_UPPERCASE) : <FormattedMessage defaultMessage={'Projects'} />}</p>
                                </Col>
                                <Col xs={{ span: 12 }} md={{ span: 12 }} style={{ textAlign: 'right' }}>
                                    {event.project?.title}
                                </Col>
                            </Row>
                        }
                        {
                            event.staffType &&
                            <Row>
                                <Col xs={{ span: 12 }} md={{ span: 12 }}>
                                    <p><FormattedMessage defaultMessage={'Required ability'} />{':'}</p>
                                </Col>
                                <Col xs={{ span: 12 }} md={{ span: 12 }} style={{ textAlign: 'right' }}>
                                    {event.staffType.name}
                                </Col>
                            </Row>
                        }
                        {/* {
                            event.basePoi &&
                            <Row>
                                <Col xs={{ span: 12 }} md={{ span: 12 }}>
                                    <p>{"Lieu de départ:"}</p>
                                </Col>
                                <Col xs={{ span: 12 }} md={{ span: 12 }} style={{ textAlign: 'right' }}>
                                    <a target="_blank" rel="noreferrer" href={`https://www.google.com/maps/search/?api=1&query=${event.basePoi.latitude},${event.basePoi.longitude}`}>{event.basePoi?.title}</a>
                                </Col>
                            </Row>
                        }
                        {
                            event.poi &&
                            <Row>
                                <Col xs={{ span: 12 }} md={{ span: 12 }}>
                                    <p>{"Lieu de travail:"}</p>
                                </Col>
                                <Col xs={{ span: 12 }} md={{ span: 12 }} style={{ textAlign: 'right' }}>
                                    <a target="_blank" rel="noreferrer" href={`https://www.google.com/maps/search/?api=1&query=${event.poi.latitude},${event.poi.longitude}`}>{event.poi?.title}</a>
                                </Col>
                                {/* <Col xs={{span : 24}} style={{ height: '200px'}}>
                                    <MapLeaflet key={`poi-display`} pois={[event.poi]} isEditMode={false} onlyDisplay={true} />
                                </Col> 
                            </Row>
                        } */}

                        {
                            event.color &&

                            <Row>
                                <Col xs={{ span: 12 }} md={{ span: 12 }}>
                                    <p><FormattedMessage defaultMessage={'Color'} />{':'}</p>
                                </Col>
                                <Col xs={{ span: 12 }} md={{ span: 12 }} style={{ textAlign: 'right', display: 'flex', justifyContent: 'flex-end' }}>
                                    <p style={{ paddingTop: '', margin: '5px 6px 0 0', display: 'inline-flex', borderRadius: '50%', height: '16px', width: '16px', backgroundColor: event.color.color }}> </p>
                                    <p>{event.color?.title}</p>
                                    {/* <div style={{ borderRadius: '50%', width: '30px', height: '20px', backgroundColor: event.color.color }} /> */}
                                </Col>
                            </Row>
                        }
                        {/* {
                            // Lieu d'engagement
                            event.basePoi &&
                            <Row>
                                <Col xs={{ span: 12 }} md={{ span: 12 }}>
                                    <p>{"Lieu d'engagement:"}</p>
                                </Col>
                                <Col xs={{ span: 12 }} md={{ span: 12 }} style={{ textAlign: 'right' }}>
                                    <a target="_blank" rel="noreferrer" href={`https://www.google.com/maps/search/?api=1&query=${event.basePoi?.latitude},${event.basePoi?.longitude}`}>{event.basePoi?.title}</a>
                                </Col>
                            </Row>
                        } */}
                        {/* {
                            // Distance
                            event.distance &&
                            <Row>
                                <Col xs={{ span: 12 }} md={{ span: 12 }}>
                                    <p>{"Distance (aller):"}</p>
                                </Col>
                                <Col xs={{ span: 12 }} md={{ span: 12 }} style={{ textAlign: 'right' }}>
                                    <p>{event.distance?.toString()} km</p>
                                </Col>
                            </Row>
                        }
                                                {
                            // Vehicle
                            event.vehicle &&
                            <Row>
                                <Col xs={{ span: 12 }} md={{ span: 12 }}>
                                    <p>{"Véhicule:"}</p>
                                </Col>
                                <Col xs={{ span: 12 }} md={{ span: 12 }} style={{ textAlign: 'right' }}>
                                    <p>{event.vehicle?.name}</p>
                                </Col>
                            </Row>
                        } */}

                        <Row>
                            <Col xs={{ span: 12 }} md={{ span: 12 }}>
                                <p><FormattedMessage defaultMessage={'Confirmed'} />{':'}</p>
                            </Col>
                            <Col xs={{ span: 12 }} md={{ span: 12 }} style={{ textAlign: 'right' }}>
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', alignItems: 'end', fontSize: '22px' }}>
                                    {this.state.loadingEventConfirmed ?
                                        <Spin spinning={this.state.loadingEventConfirmed} />
                                        : (confirmed !== undefined) ?
                                            <CheckCircleOutlined title={intl.formatMessage({ defaultMessage: 'The event was confirmed on {date}' }, { date: moment(confirmed.date).format(getFormat('DATE_LONG_AND_ON_TIME_SHORT')) })} style={{ color: 'var(--success-color)', lineHeight: '30px' }} />
                                            : <CloseCircleOutlined style={{ color: 'var(--error-color)', lineHeight: '30px' }} />
                                    }
                                </div>
                            </Col>
                        </Row>
                    </>
                );
            }
            case "break":
                return (
                    <>
                        {
                            event.breakTimes && event.breakTimes.length > 0 ?
                                <>
                                    <Row>
                                        <Col xs={{ span: 24 }} md={{ span: 24 }}>
                                            <Collapse bordered={false} style={{ backgroundColor: 'transparent' }}>
                                                {
                                                    event.breakTimes.map((bt, index) => {
                                                        let breaktimeDuration = bt.totalHours;
                                                        let nbDays = 0;
                                                        while (breaktimeDuration > 24.0) {
                                                            breaktimeDuration -= 24.0;
                                                            nbDays += 1;
                                                        }
                                                        let breaktimeDurationStr = "";
                                                        if (nbDays > 0) breaktimeDurationStr += (nbDays + "j ");

                                                        breaktimeDurationStr += moment.utc(moment.duration(breaktimeDuration, "hours").asMilliseconds()).format(MOMENT_SHORT_TIME_FORMAT);
                                                        return (
                                                            <Panel header={bt.title} key={index}
                                                            // extra={
                                                            //     <DeleteOutlined onClick={e => {
                                                            //         e.stopPropagation();
                                                            //     }} />
                                                            // }
                                                            >
                                                                <p><b><FormattedMessage defaultMessage={'Start date'} />{':'} </b>{bt.startDate.format(getFormat('DATE_AND_TIME_SHORT'))}</p>
                                                                <p><b><FormattedMessage defaultMessage={'End date'} />{':'} </b>{bt.endDate.format(getFormat('DATE_AND_TIME_SHORT'))}</p>
                                                                <p><b><FormattedMessage defaultMessage={'Duration'} />{':'} </b>{breaktimeDurationStr}</p>
                                                                <p>{bt.isPaid ? <FormattedMessage defaultMessage={"At the employer's expense"} /> : <FormattedMessage defaultMessage={"At the employee's expense"} />}</p>
                                                            </Panel>
                                                        );
                                                    })
                                                }
                                            </Collapse>
                                        </Col>
                                    </Row>
                                </>
                                :
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        }
                    </>
                );
            case "overtime":
                return (
                    <>
                        {
                            event.overtimes && event.overtimes.length > 0 && event.overtimes.filter(o => o.isConfirmed === true).length > 0 ?
                                <>
                                    <Row>
                                        <Col xs={{ span: 24 }} md={{ span: 24 }}>
                                            <Collapse bordered={false} style={{ backgroundColor: 'transparent' }}>
                                                {
                                                    event.overtimes.filter(o => o.isConfirmed === true).map((o, index) => {
                                                        return (
                                                            <Panel header={o.title} key={index} extra={
                                                                <Popconfirm
                                                                    title={<FormattedMessage defaultMessage={'Do you want to delete this overtime?'} />}
                                                                    onConfirm={() => {
                                                                        this.props.onDeleteOvertime(o);
                                                                    }}
                                                                    // onCancel={cancel}
                                                                    okText={<FormattedMessage defaultMessage={'Yes'} />}
                                                                    cancelText={<FormattedMessage defaultMessage={'No'} />}
                                                                >
                                                                    <DeleteOutlined onClick={e => e.stopPropagation()} />
                                                                </Popconfirm>

                                                            }>
                                                                <p><b><FormattedMessage defaultMessage={'Start date'} />{':'} </b>{moment(o.startDate).format(getFormat('DATE_AND_TIME_SHORT'))}</p>
                                                                <p><b><FormattedMessage defaultMessage={'End date'} />{':'} </b>{moment(o.endDate).format(getFormat('DATE_AND_TIME_SHORT'))}</p>
                                                                <p>{o.isNegative ? <FormattedMessage defaultMessage={'This is an undertime'} /> : ""}</p>
                                                            </Panel>
                                                        );
                                                    })
                                                }
                                            </Collapse>
                                        </Col>
                                    </Row>
                                </>
                                :
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        }
                    </>
                );
            case "journey":
                return (
                    <>
                        {
                            event.basePoi || event.poi || event.vehicle || event.distance ?
                                <>
                                    {
                                        event.basePoi ?
                                            <Row>
                                                <Col xs={{ span: 12 }} md={{ span: 12 }}>
                                                    <p><FormattedMessage defaultMessage={'Departure location'} />{':'}</p>
                                                </Col>
                                                <Col xs={{ span: 12 }} md={{ span: 12 }} style={{ textAlign: 'right' }}>
                                                    <a target="_blank" rel="noreferrer" href={`https://www.google.com/maps/search/?api=1&query=${event.basePoi.latitude},${event.basePoi.longitude}`}>{event.basePoi?.title}</a>
                                                </Col>
                                            </Row>
                                            : null
                                    }
                                    {
                                        event.poi ?
                                            <Row>
                                                <Col xs={{ span: 12 }} md={{ span: 12 }}>
                                                    <p><FormattedMessage defaultMessage={'Workplace'} />{':'}</p>
                                                </Col>
                                                <Col xs={{ span: 12 }} md={{ span: 12 }} style={{ textAlign: 'right' }}>
                                                    <a target="_blank" rel="noreferrer" href={`https://www.google.com/maps/search/?api=1&query=${event.poi.latitude},${event.poi.longitude}`}>{event.poi?.title}</a>
                                                </Col>
                                            </Row>
                                            : null
                                    }
                                    {
                                        event.distance ?
                                            <Row>
                                                <Col xs={{ span: 12 }} md={{ span: 12 }}>
                                                    <p><FormattedMessage defaultMessage={'Distance (one-way)'} />{':'}</p>
                                                </Col>
                                                <Col xs={{ span: 12 }} md={{ span: 12 }} style={{ textAlign: 'right' }}>
                                                    <p>{event.distance?.toString()} km</p>
                                                </Col>
                                            </Row>
                                            : null
                                    }
                                    {
                                        event.vehicle ?
                                            <Row>
                                                <Col xs={{ span: 12 }} md={{ span: 12 }}>
                                                    <p><FormattedMessage defaultMessage={'Vehicle'} />{':'}</p>
                                                </Col>
                                                <Col xs={{ span: 12 }} md={{ span: 12 }} style={{ textAlign: 'right' }}>
                                                    <p>{event.vehicle?.name}</p>
                                                </Col>
                                            </Row>
                                            : null
                                    }
                                </>
                                :
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<FormattedMessage defaultMessage={'No travel'} />} />
                        }
                    </>
                );
            case "eventclocked":
                return (
                    <Row gutter={[10, 10]} justify='center'>
                        <Col md={{ span: 24 }}>
                            <Row gutter={[15, 15]}>
                                <Col xs={{ span: 12 }}><FormattedMessage defaultMessage={'Timeclock status'} />{':'}</Col>
                                <Col xs={{ span: 12 }} style={{ textAlign: 'right' }}>
                                    <SpaceContent style={{ alignItems: 'center', justifyContent: 'flex-end' }}>
                                        {
                                            event.clocked ?
                                                <>
                                                    {

                                                        event.clocked.isConfirmed ?
                                                            <>
                                                                <Tag color='green' style={{ fontSize: 17, marginRight: '0' }}> <FormattedMessage defaultMessage={'Confirmed'} /></Tag>
                                                                {
                                                                    event.clocked.adminStartDate && event.clocked.adminEndDate ?
                                                                        <Popover content={<FormattedMessage defaultMessage={'This event has been modified by an administrator'} />}>
                                                                            <TbClockShield style={{ color: BLUE_COLOR, fontSize: 19, cursor: 'pointer' }} />
                                                                        </Popover>
                                                                        : null
                                                                }

                                                            </>
                                                            :
                                                            event.clocked.isRefused ?
                                                                <Tag color='red' style={{ fontSize: 17, marginRight: '0' }}><FormattedMessage defaultMessage={'Rejected'} /></Tag>
                                                                :
                                                                !event.clocked.endDate && !event.clocked.isManual ?
                                                                    <Tag color='blue' style={{ fontSize: 17, marginRight: '0' }}><FormattedMessage defaultMessage={'In progress'} /></Tag>
                                                                    :
                                                                    <Tag color='volcano' style={{ fontSize: 17, marginRight: '0' }}><FormattedMessage defaultMessage={'Awaiting confirmation'} /></Tag>

                                                    }
                                                    {
                                                        event.clocked.isManual ?
                                                            <Popover content={<FormattedMessage defaultMessage={'This event has been clocked manually'} />}>
                                                                <TbClockEdit style={{ fontSize: 19.5, color: YELLOW_COLOR, cursor: 'pointer' }} />
                                                            </Popover>
                                                            : null
                                                    }
                                                    {
                                                        event.fromTimeClock ?
                                                            <Popover content={<FormattedMessage defaultMessage={'This event was clocked without being planned'} />}>
                                                                <MdMoreTime style={{ fontSize: 21, color: '#FF7F50', cursor: 'pointer' }} />
                                                            </Popover>
                                                            : null
                                                    }
                                                </>
                                                :
                                                <Tag color='default' style={{ fontSize: 17, marginRight: '0' }}><FormattedMessage defaultMessage={'The timeclock has not been started'} /></Tag>
                                        }
                                    </SpaceContent>
                                </Col>
                                {
                                    event.fromTimeClock ?
                                        null
                                        :
                                        <Col xs={{ span: 24 }}>
                                            {this.renderEventclockedDateBloc(intl.formatMessage({ defaultMessage: 'Planned' }), event.clocked ? event.clocked.planStartDate : event.startDate, event.clocked ? event.clocked.planEndDate : event.endDate)}
                                        </Col>
                                }
                                {
                                    event.clocked ?
                                        <>
                                            {
                                                event.clocked.startDate ?
                                                    <Col xs={{ span: 24 }} className={`${event.clocked.isConfirmed ? '__is-confirmed-bloc' : ''}`}>
                                                        {this.renderEventclockedDateBloc(intl.formatMessage({ defaultMessage: 'Clocked' }), event.clocked.startDate, event.clocked.endDate ?? null)}
                                                    </Col>
                                                    : null
                                            }
                                            {
                                                event.clocked.manualStartDate && event.clocked.manualEndDate ?
                                                    <Col xs={{ span: 24 }} className={`${event.clocked.isConfirmed ? '__is-confirmed-bloc' : ''}`}>
                                                        {this.renderEventclockedDateBloc(intl.formatMessage({ defaultMessage: 'Manual entry' }), event.clocked.manualStartDate, event.clocked.manualEndDate)}
                                                    </Col>
                                                    : null
                                            }
                                            {
                                                event.clocked.adminStartDate && event.clocked.adminEndDate ?
                                                    <Col xs={{ span: 24 }} className={`${event.clocked.isConfirmed ? '__is-confirmed-bloc' : ''}`}>
                                                        {this.renderEventclockedDateBloc(intl.formatMessage({ defaultMessage: 'Administrator modification' }), event.clocked.adminStartDate, event.clocked.adminEndDate)}
                                                    </Col>
                                                    : null
                                            }
                                        </>
                                        : null
                                }
                                {
                                    event.clocked && event.clocked.isManual && event.clocked.manualDescription &&
                                    <Col xs={{ span: 24 }}>
                                        <Alert message={<>{event.clocked.manualDescription}</>} type='warning' />
                                    </Col>
                                }
                            </Row>
                        </Col>
                    </Row>
                );
            default:
                return <p>Error</p>;
        }
    };

    render() {
        const { event, intl } = this.props;
        const { creator, modifier } = this.state;

        let tabList = [
            {
                key: 'summary',
                tab: intl.formatMessage({ defaultMessage: 'Overview' }),
            },
            {
                key: 'extra',
                tab: intl.formatMessage({ defaultMessage: 'Extra' }),
            },
            {
                key: 'break',
                tab: intl.formatMessage({ defaultMessage: 'Breaks' }),
            },
            {
                key: 'overtime',
                tab: intl.formatMessage({ defaultMessage: 'Overtimes' }),
            },
            {
                key: 'eventclocked',
                tab: intl.formatMessage({ defaultMessage: 'Timeclock' }),
            },
            {
                key: 'journey',
                tab: intl.formatMessage({ defaultMessage: 'Travel' }),
            },
        ];
        tabList = this.props.company?.isTimeClockEnabled ? tabList.filter(tab => tab.key !== 'overtime') : tabList.filter(tab => tab.key !== 'eventclocked');
        return (
            <Modal
                open={true}
                width="750px"
                className="planning-modal"
                title={<FormattedMessage defaultMessage={'Event: {name}'} values={{ name: event ? event.title : '' }} />}
                onCancel={() => {
                    this.setState({ key: "summary" });
                    this.props.onCancel();
                }}
                destroyOnClose={true}
                footer={[<div key={`modal-action-showEvent`} style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', gap: 8 }}>
                    <Button
                        key="details-modal-cancel"
                        type="dashed"
                        onClick={this.props.onCancel}>
                        <FormattedMessage defaultMessage={'Close'} />
                    </Button>
                    {
                        (checkRBACRule(Rules.Planning.Management, this.props.currentUser?.role) || checkPrivilege(Privileges.Planning.Edition, this.props.currentUser)) &&
                        <Popconfirm
                            title={<FormattedMessage defaultMessage={'This action will create a model from this event. Would you like to continue?'} />}
                            key="details-modal-create-template"
                            onConfirm={this.createTemplate}
                            icon={<QuestionCircleOutlined style={{ color: '#ff2626' }} />}
                            okText={<FormattedMessage defaultMessage={'Yes'} />}
                            okButtonProps={{ type: "primary" }}
                            cancelText={<FormattedMessage defaultMessage={'No'} />}
                            cancelButtonProps={{ type: 'link' }}>
                            <Button loading={this.state.createTemplateLoading}>
                                <FormattedMessage defaultMessage={'Create as model'} />
                            </Button>
                        </Popconfirm>
                    }
                </div>,
                this.props.canEdit ?
                    <>
                        {
                            !event?.eventLocked ?
                                <div key="planning-details-modal-parent">
                                    <Popconfirm
                                        title={<FormattedMessage defaultMessage={'Are you sure you want to delete this event?'} />}
                                        key="details-modal-delete"
                                        onConfirm={() => {
                                            this.props.onCancel();
                                            if (event && event.id && event.userId) {
                                                this.props.onDelete(event.id, event.userId, `${event.startDate.format("YYYYMMDD")}`);
                                            }
                                        }}
                                        icon={<QuestionCircleOutlined style={{ color: '#ff2626' }} />}
                                        okText={<FormattedMessage defaultMessage={'Yes'} />}
                                        okButtonProps={{ danger: true, type: "link" }}
                                        cancelText={<FormattedMessage defaultMessage={'No'} />}
                                        cancelButtonProps={{ type: 'link' }}>
                                        <Button danger>
                                            <FormattedMessage defaultMessage={'Delete'} />
                                        </Button>
                                    </Popconfirm>
                                    <Button
                                        key="details-modal-edit"
                                        type="primary"
                                        onClick={() => { this.props.onCancel(); event && event.id && this.props.onEdit(event.id); }}>
                                        <FormattedMessage defaultMessage={'Edit'} />
                                    </Button>
                                </div>
                                :
                                undefined
                        }
                    </>
                    :
                    <Can
                        rule={Rules.Planning.Management}
                        key={"planning-details-modal-parent-can"}>
                        {
                            !event?.eventLocked ?
                                <div key="planning-details-modal-parent">
                                    <Popconfirm
                                        title={<FormattedMessage defaultMessage={'Are you sure you want to delete this event?'} />}
                                        key="details-modal-delete"
                                        onConfirm={() => {
                                            this.props.onCancel();
                                            if (event && event.id && event.userId) {
                                                this.props.onDelete(event.id, event.userId, `${event.startDate.format("YYYYMMDD")}`);
                                            }
                                        }}
                                        icon={<QuestionCircleOutlined style={{ color: '#ff2626' }} />}
                                        okText={<FormattedMessage defaultMessage={'Yes'} />}
                                        okButtonProps={{ danger: true, type: "link" }}
                                        cancelText={<FormattedMessage defaultMessage={'No'} />}
                                        cancelButtonProps={{ type: 'link' }}>
                                        <Button danger>
                                            <FormattedMessage defaultMessage={'Delete'} />
                                        </Button>
                                    </Popconfirm>
                                    <Button
                                        key="details-modal-edit"
                                        type="primary"
                                        onClick={() => {
                                            this.props.onCancel();
                                            event && event.id && this.props.onEdit(event.id);
                                        }}
                                    >
                                        <FormattedMessage defaultMessage={'Edit'} />
                                    </Button>
                                </div>
                                :
                                undefined
                        }
                    </Can>
                ]}>
                {
                    event ?
                        <>
                            <Card
                                tabList={tabList}
                                activeTabKey={this.state.key}
                                bodyStyle={this.state.key === 'extra' ? { padding: 0 } : {}}
                                className={this.state.key === "break" || this.state.key === "overtime" || (this.state.key === 'journey' && !(event.basePoi || event.poi || event.vehicle || event.distance)) /*|| this.state.key === "eventclocked"*/ ? "__event-tab-without-padding" : ""}
                                onTabChange={key => this.setState({ key: key as typeof this.state.key })}
                            >
                                {
                                    <this.TabContent key={`tab-content-${this.state.key}`} activeKey={this.state.key} creator={creator} modifier={modifier} />
                                }
                            </Card>


                            <div className="planning-details-modal-parent">
                                {
                                    event.timerStart &&
                                    <span>
                                        <p className="planning-details-modal-title"><FormattedMessage defaultMessage={'Stopwatch'} /></p>
                                        <p className="planning-details-modal-element"><FormattedMessage defaultMessage={'Started on {start}'} values={{ start: event.timerStart.format(getFormat('DATE_LONG_AND_ON_TIME_SHORT')) }} /></p>
                                        {event.timerStop && <p className="planning-details-modal-element"><FormattedMessage defaultMessage={'Ends on {end}'} values={{ end: event.timerStop.format(getFormat('DATE_LONG_AND_ON_TIME_SHORT')) }} /></p>}
                                        {event.timerStop && <p className="planning-details-modal-element"><FormattedMessage defaultMessage={'Duration {time}'} values={{ time: this.getTimerDuration() }} /></p>}
                                    </span>
                                }
                                {
                                    <span>
                                        <p className="planning-details-modal-title"><FormattedMessage defaultMessage={'Rules'} /></p>
                                        <span className="planning-details-modal-rrule">
                                            <p className="planning-details-modal-rrule-title"><FormattedMessage defaultMessage={'This event'} />{':'}</p>
                                            <ul>
                                                {event.isDraggable !== undefined && event.isDraggable ? <li className="planning-details-modal-rrule-element"><FormattedMessage defaultMessage={'is movable'} /></li> : null}
                                            </ul>
                                        </span>
                                    </span>
                                }
                            </div>
                        </>
                        :
                        <Row>
                            <Col span={24}>
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px', flexDirection: 'column' }}>
                                    <Spin size='large' style={{ marginBottom: '20px' }} indicator={<LoadingOutlined style={{ fontSize: 100 }} spin />} />
                                </div>
                            </Col>
                        </Row>
                }
            </Modal>
        );
    }
}

const mapDispatchToProps = (dispatch: StoreDispatch) => ({
    changeUsers: (u: User[]) => dispatch(changeUsers(u)),
});

const mapStateToProps = (state: ApplicationState) => ({
    company: state.user.company,
    users: state.teamManagement.users,
    groups: state.teamManagement.groups,
    currentUser: state.user.currentUser,
    isSmartphone: state.window.isSmartphone,
});
const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(withRouter(injectIntl(ShowEventModal)));